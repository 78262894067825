<template>
  <section class="bg-secondary-light">
    <template v-if="showRecommendations">
      <div
        v-for="(recommendation, index) in recommendations"
        :key="index"
        class="w-full d-flex flex-column  bg-neutral rounded-lg px-5 py-5 reveal-animation"
        :class="index === 0 ? 'mt-7' : 'mt-5'"
        style="--d: 0.09s"
      >
        <!-- campus -->
        <div class="mb-4 d-flex flex-row reveal-animation" style="--d: 0.1s">
          <!-- campus information -->
          <div class="d-flex flex-column mx-3 w-full">
            <TextAtom
              :value="recommendation?.campus_name"
              tag="h3"
              font="inter"
              color="primary-dark"
              weight="500"
              size="normal"
              class="text-left"
            />
            <!-- campus description -->
            <TextAtom
              key="sector_label"
              :value="getLocaleProperty(recommendation?.sector, 'sector_name')"
              tag="span"
              font="inter"
              color="neutral-800"
              weight="400"
              size="default"
              class="text-left"
            />
          </div>
        </div>
        <!-- campus categories -->
        <CampusCategories
          class="reveal-animation"
          style="--d: 0.17s"
          :campus="recommendation"
          :location="location"
        />
        <!-- Thumbnail -->
        <ImageAtom
          v-if="imageLink(recommendation)"
          :src="imageLink(recommendation)"
          :alt="recommendation?.campus_name"
          class="my-4 rounded-md"
          style="max-height: 200px; object-position: center;"
          object-fit="cover"
        />
        <button
          v-t="'feedback.show_campus'"
          type="button"
          class="mt-2 show-profile"
          @click="exploreCampusDetail(recommendation.campus_code, index)"
        />
      </div>
    </template>
    <button
      v-t="'feedback.go_to_explorer'"
      type="button"
      class="go-to-explorer"
      :class="showRecommendations ? 'mt-10' : ''"
      @click="goToExplorer()"
    />
    <v-dialog
      v-model="campusDetailModal"
      transition="dialog-bottom-transition"
      max-width="800px"
      content-class="favorites__dialog"
    >
      <CampusDetail
        v-if="campusDetailModal"
        style="z-index: 1000"
        in-dashboard
        @close="() => closeCampusDetail()"
      />
    </v-dialog>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import CampusDetail from '@/components/explorer/general/campus_detail/CampusDetail.vue';
import CampusCategories from '@/components/organisms/campus/information/CampusCategories.vue';
import trackMixPanel from '@/utils/mixpanel';
import { getLocaleProperty } from '@/utils/locale';
import ImageAtom from '@/components/atoms/images/ImageAtom.vue';

export default {
  name: 'FeedbackRecommendations',
  components: {
    TextAtom, CampusDetail, CampusCategories, ImageAtom,
  },
  props: {
    recommendations: {
      type: Array,
      required: true,
    },
    showRecommendations: {
      type: Boolean,
      default: false,
    },
    studentUuid: {
      type: String,
      required: true,
    },
    location: {
      type: Object,
      validate: (location) => {
        if (!location || (location.lat && location.lng)) return true;
        return false;
      },
      default: () => undefined,
    },
  },
  data() {
    return {
      campusDetailModal: false,
    };
  },
  computed: {
    ...mapGetters({
      studentList: 'authentication/students',
    }),
  },
  methods: {
    ...mapActions({
      updateCurrentSchool: 'institutions/updateCurrentSchool',
      setCurrentStudent: 'authentication/setCurrentStudent',
      setIsShowDigitalProfile: 'feedback/setIsShowDigitalProfile',
      deactivateModal: 'utils/deactivateModal',
    }),
    imageLink(recommendation) {
      const landingGIF = recommendation?.animated_landing_url;
      return landingGIF || recommendation?.image_thumb?.[0]?.image_link;
    },
    goToExplorer() {
      const student = this.studentList.find((st) => st.uuid === this.studentUuid);
      this.setCurrentStudent({ student });
      trackMixPanel('fbin_postind_explorador');
      this.$router.push({ name: 'Explorer' });
      this.deactivateModal();
    },
    closeCampusDetail() {
      this.campusDetailModal = false;
    },
    exploreCampusDetail(campusCode, index) {
      this.setIsShowDigitalProfile(false);
      this.campusDetailModal = true;
      trackMixPanel('fbin_postind_explorar_rec', { campusCode, rec_order: index + 1, studentUuid: this.studentUuid });
      this.updateCurrentSchool({ campusCode, fromUrl: true });
    },
    getLocaleProperty,
  },
};
</script>
<style lang="scss" scoped>
.go-to-explorer {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-dark);
  color: var(--neutral);
  border-radius: 30px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.28px;
}

.reveal-animation {
  opacity: 0;
  animation: reveal 0.5s forwards var(--d)
}

.show-profile {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-medium-light);
  color: var(--neutral);
  border-radius: 30px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.28px;
}

@keyframes reveal {
  from {
    transform: translateY(20px);
  }
  to {
    opacity: 1; transform: none;
  }
}
</style>
