import { TRAVEL_METHOD } from './general';

const TETHER_CATEGORY = Object.freeze({
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  MEDIUM_LOW: 'MEDIUM_LOW',
  LOW: 'LOW',
  SPECIAL: 'SPECIAL', // For special cases (infant school, Pack Tether)
  OTHER: 'OTHER', // For other labels (Admission)
  NO_DATA: 'NO_DATA', // The data is not available
});

const TETHER_CATEGORY_STYLE = Object.freeze({
  [TETHER_CATEGORY.HIGH]: {
    color: 'secondary-light',
    backgroundColor: 'secondary-dark',
  },
  [TETHER_CATEGORY.MEDIUM]: {
    color: 'secondary-light',
    backgroundColor: 'info',
  },
  [TETHER_CATEGORY.MEDIUM_LOW]: {
    color: 'secondary-light',
    backgroundColor: 'primary',
  },
  [TETHER_CATEGORY.LOW]: {
    color: 'secondary-light',
    backgroundColor: 'primary-medium',
  },
  [TETHER_CATEGORY.SPECIAL]: {
    color: 'secondary-light',
    backgroundColor: 'primary-dark',
  },
  [TETHER_CATEGORY.OTHER]: {
    color: 'primary-dark',
    backgroundColor: 'primary-light',
  },
  [TETHER_CATEGORY.NO_DATA]: {
    color: 'text-neutral-600',
    backgroundColor: 'neutral-50',
  },
});

const CAMPUS_PROPERTIES = Object.freeze({
  HAS_VACANCIES: 'HAS_VACANCIES',
  AGREEMENTS: 'AGREEMENTS',
  MULTIMEDIA: 'MULTIMEDIA',
  INFRASTRUCTURE: 'INFRASTRUCTURE',
  SPORTS: 'SPORTS',
  EXTRACURRICULARS: 'EXTRACURRICULARS',
  LANGUAGES: 'LANGUAGE',
  SPECIALTIES: 'SPECIALTIES',
  DEPENDENCIES: 'DEPENDENCIES',
  PARTNERSHIPS: 'PARTNERSHIPS',
  DISTANCE: 'DISTANCE',
  PERFORMANCE: 'PERFORMANCE',
  PAYMENT: 'PAYMENT',
  ADMISSION_RISK: 'ADMISSION_RISK',
  NETWORK_ID: 'NETWORK_ID',
  PROCESS_ID: 'PROCESS_ID',
  WALK_TIME: `${TRAVEL_METHOD.WALK}_TIME`,
  CAR_TIME: `${TRAVEL_METHOD.CAR}_TIME`,
  BUS_TIME: `${TRAVEL_METHOD.BUS}_TIME`,
  INSTITUTION: 'INSTITUTION',
  MODALITY: 'MODALITY',
});

const CAMPUS_SUBSCRIPTION_STATUS = Object.freeze({
  FREEMIUM: 'FREEMIUM',
  PREMIUM: 'PREMIUM',
  NOT_CLAIMED: 'NOT_CLAIMED',
});

const DEFAULT_CARD_PROPERTIES = Object.freeze([
  CAMPUS_PROPERTIES.DISTANCE,
  CAMPUS_PROPERTIES.PERFORMANCE,
  CAMPUS_PROPERTIES.PAYMENT,
  CAMPUS_PROPERTIES.ADMISSION_RISK,
]);

const TETHER_PAYMENT_CATEGORY = Object.freeze({
  1: TETHER_CATEGORY.HIGH,
  2: TETHER_CATEGORY.MEDIUM,
  3: TETHER_CATEGORY.MEDIUM_LOW,
  4: TETHER_CATEGORY.LOW,
});

const PERFORMANCE_ICON_STYLE = Object.freeze({
  ARROWS: 'arrows',
  ALPHA: 'alpha',
});

const CAMPUS_PAYMENT_TYPES = Object.freeze({
  ENROLLMENT_FEE: 1,
  MONTHLY_TUITION: 2,
});

export {
  CAMPUS_PROPERTIES,
  DEFAULT_CARD_PROPERTIES,
  TETHER_CATEGORY,
  TETHER_PAYMENT_CATEGORY,
  TETHER_CATEGORY_STYLE,
  PERFORMANCE_ICON_STYLE,
  CAMPUS_PAYMENT_TYPES,
  CAMPUS_SUBSCRIPTION_STATUS,
};
