import { backendAPI } from '@/api';

const appUrl = '/';
export default {
  // 1
  retrieveUserGenders() {
    return backendAPI.get(`${appUrl}registration/genders/`);
  },
  retrieveNatonialitys() {
    return backendAPI.get(`${appUrl}registration/nationality/`);
  },
  retrieveNationalidentification() {
    return backendAPI.get(`${appUrl}registration/nationalidentification/`);
  },
  retrieveAgreements() {
    return backendAPI.get(`${appUrl}institutions/agreementslabels/`);
  },
  retrieveEducationalLevels({
    campus, grade, year, modality, specialty,
  } = {}) {
    return backendAPI.get(`${appUrl}institutions/gradetracks/educational_levels`, {
      params: {
        campus,
        grade,
        year,
        modality,
        specialty,
      },
    });
  },
  retrieveRegions() {
    return backendAPI.get(`${appUrl}places/departments/`);
  },
  retrieveMunicipalities() {
    return backendAPI.get(`${appUrl}places/municipalities/`);
  },
  retrieveRoadType() {
    return backendAPI.get(`${appUrl}registration/vias/`);
  },
  // 2
  retrievePridePoints() {
    return backendAPI.get(`${appUrl}institutions/pridepointlabels/`);
  },
  retrievePerformanceCategories() {
    return backendAPI.get(`${appUrl}institutions/qualitymeasurecateglevellabel/`);
  },
  retrieveMonthlyPayments() {
    return backendAPI.get(`${appUrl}institutions/paymentcategorylabel/`);
  },
  retrievePaymentBandLabels() {
    return backendAPI.get(`${appUrl}institutions/v2/paymentbandslabelbytype/`);
  },
  retrievePaymentTypeLabels() {
    return backendAPI.get(`${appUrl}institutions/v2/paymenttypelabel/`);
  },
  retriveDocumentTypes() {
    return backendAPI.get(`${appUrl}registration/nationalidentification/`);
  },
  // 3
  retrieveRelationshipTypes() {
    return backendAPI.get(`${appUrl}registration/relationship_types/`);
  },
  retrieveDisabilities() {
    return backendAPI.get(`${appUrl}registration/disabilities/`);
  },
  retrieveArmedConflictTypes() {
    return backendAPI.get(`${appUrl}registration/armedConflict_types/`);
  },
  retrieveTestScoreAreaLabels() {
    return backendAPI.get(`${appUrl}institutions/testscorearealabels/`);
  },
  retrieveTestScoreVariableLabels() {
    return backendAPI.get(`${appUrl}institutions/testscorevariablelabels/`);
  },
  retrieveSectorLabels() {
    return backendAPI.get(`${appUrl}institutions/sectorlabel/`);
  },
  // 4
  retrieveLanguage() {
    return backendAPI.get(`${appUrl}institutions/languajelabels/`);
  },
  retrieveSports() {
    return backendAPI.get(`${appUrl}institutions/sportlabels/`);
  },
  retrieveInfrastructure() {
    return backendAPI.get(`${appUrl}institutions/infraestructurelabels/`);
  },
  retrieveSafety() {
    return backendAPI.get(`${appUrl}institutions/safety/`);
  },
  retrieveExtracurriculares() {
    return backendAPI.get(`${appUrl}institutions/extraactivitylabels/`);
  },
  retrieveSupport() {
    return backendAPI.get(`${appUrl}institutions/supportlabels/`);
  },
  // 5
  retrieveLocationType() {
    return backendAPI.get(`${appUrl}registration/address_labels/`);
  },
  retrieveSisbenGroup() {
    return backendAPI.get(`${appUrl}registration/sisben_category/`);
  },
  retrieveSpecialities() {
    return backendAPI.get(`${appUrl}institutions/specialitieslabel/`);
  },
  retrieveSpecialtiesByGrade({ gradeId, stageId, modalityId }) {
    const params = { grade_label_id: gradeId, stage_label_id: stageId, modality_label_id: modalityId };
    return backendAPI.get(`${appUrl}institutions/specialitieslabel/`, { params });
  },
  retrieveAudiovisual() {
    return backendAPI.get(`${appUrl}institutions/audiovisuallabels/`);
  },
  retrievePartnership() {
    return backendAPI.get(`${appUrl}institutions/partnershiplabels/`);
  },
  // 6
  retrieveMembers() {
    return backendAPI.get(`${appUrl}institutions/memberlabels/`);
  },
  retrieveShifts() {
    return backendAPI.get(`${appUrl}institutions/shiftslabel/`);
  },
  retrieveModalities() {
    return backendAPI.get(`${appUrl}institutions/modalitieslabel/`);
  },
  retrieveModalitiesByGrade({ gradeId, stageId }) {
    const params = { grade_label_id: gradeId, stage_label_id: stageId };
    return backendAPI.get(`${appUrl}institutions/modalitieslabel/`, { params });
  },
  retrieveProgramGenders() {
    return backendAPI.get(`${appUrl}institutions/genders/`);
  },
  // 7
  retrieveLanguageLevels() {
    return backendAPI.get(`${appUrl}institutions/languajelevellabels/`);
  },
  retrieveNativeLanguages() {
    return backendAPI.get(`${appUrl}registration/nativelanguagelist/`);
  },
  retrieveContacts() {
    return backendAPI.get(`${appUrl}institutions/contactlabels/`);
  },
};
