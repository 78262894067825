import services from '@/services';
import { TRAVEL_METHOD } from '@/constants/general';

const getDefaultState = () => ({
});

const state = getDefaultState();

const actions = {
  // eslint-disable-next-line no-empty-pattern
  searchCommuneFromText({}, { searchTerm }) {
    return services.geoToolsService
      .getCommuneFromText({ searchTerm })
      .then((response) => {
        const places = response.data[0];
        return { places, status: 200 };
      }).catch((error) => {
        if (error.message !== 'canceled') {
          throw error;
        }
        return { campuses: [], status: 418 };
      });
  },
  // eslint-disable-next-line no-empty-pattern
  searchAddressFromText({}, { searchTerm, codeNational }) {
    return services.geoToolsService
      .getAddressFromText({ searchTerm, codeNational })
      .then((response) => {
        const address = response.data;
        return { address, status: 200 };
      }).catch((error) => {
        if (error.message !== 'canceled') {
          throw error;
        }
        return { campuses: [], status: 418 };
      });
  },

  // eslint-disable-next-line no-empty-pattern
  searchAddressInGoogle({}, { searchTerm, codeNational }) {
    return services.geoToolsService
      .getAddressInGoogle({ searchTerm, codeNational })
      .then((response) => {
        const address = response.data;
        return { address, status: 200 };
      }).catch((error) => {
        if (error.message !== 'canceled') {
          throw error;
        }
        return { campuses: [], status: 418 };
      });
  },

  // eslint-disable-next-line no-empty-pattern
  sendPlaceHistory({}, { place }) {
    return services.geoToolsService.setGeoHistory({ params: place, typeHistory: 'place' });
  },

  // eslint-disable-next-line no-empty-pattern
  sendGeocodingHistory({}, { geoInfo }) {
    return services.geoToolsService.setGeoHistory({ params: geoInfo, typeHistory: 'geocoding' });
  },

  // eslint-disable-next-line no-empty-pattern
  async getTravelTimes({}, travelTime) {
    return services.geoToolsService.retrieveTravelTime({ travelTime }).then((response) => {
      const {
        walk_duration: walkTimeLabel,
        walk_duration_max: maxWalkTime,
        drive_duration: carTimeLabel,
        drive_duration_max: maxCarTime,
      } = response.data;
      const carData = {
        rangeLabel: carTimeLabel,
        maxTime: maxCarTime,
        mode: TRAVEL_METHOD.CAR,
      };
      const walkData = {
        rangeLabel: walkTimeLabel,
        maxTime: maxWalkTime,
        mode: TRAVEL_METHOD.WALK,
      };
      return { walkData, carData };
    }).catch(() => ({ walkData: null, carData: null }));
  },
  // eslint-disable-next-line no-empty-pattern
  async getBusRouteTimes({ commit }, travelTime) {
    return services.geoToolsService.retrieveBusRoutes({ travelTime })
      .then((response) => {
        commit('institutions/setSchoolTravelTime', response.data, { root: true });
        const { bus_duration_display: rangeLabel, bus_duration: maxTime } = response.data;
        const busData = { mode: TRAVEL_METHOD.BUS, rangeLabel, maxTime };
        return { busData };
      })
      .catch(() => ({ busData: null }));
  },

};

export default {
  namespaced: true,
  state,
  actions,
};
