<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        :inline-logo="true"
        :small-logo="true"
        @back="$emit('backStep')"
      />
      <RowStepper :step="3" />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', 'selectionButton']"
        :small-title-text="'user_register.additional_info.title'"
        :small-title-left="true"
        :subtitle-text="'user_register.campus_selection.subtitle'"
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :selection-button-info="selectionButtonInfo"
        @setSelectionButtonValue="setButtonsValue"
      />
      <section v-if="currentSchoolToSet" style="margin-top: 30px">
        <ModalContent
          v-if="!onlySchool"
          :content-sections="['subtitle', 'autoComplete']"
          :subtitle-text="'user_register.campus_selection.which_one'"
          :auto-complete-placeholder="'user_register.register_location.department_placeholder'"
          :auto-complete-items="regionLabels"
          :autocomplete-no-info-error="noInfoError && !department"
          @setAutoComplete="setDepartment"
        />
        <div
          v-for="index in getNumberOfButtons"
          :key="index"
        >
          <ModalContent
            :content-sections="['autoComplete']"
            :auto-complete-placeholder="getLocationInfoPlaceholder(index)"
            :auto-complete-items="getLocationInfoItems()"
            :disabled-autocomplete-value="getLocationInfoDisabled"
            :autocomplete-no-info-error="noInfoError && !commune"
            :autocomplete-index="index"
            @setAutoComplete="setAutcomplete"
          />
        </div>
      </section>
    </div>
    <CallToActions
      style="margin-top: 40px"
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      :small="true"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import CONFIG from '@/config';
import { mapActions, mapGetters } from 'vuex';
import { ELASTIC_SEARCH_FIELDS } from '@/constants/search';

export default {
  name: 'CampusSelection',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  data() {
    return {
      isActive: false,
      currentSchoolToSet: false,
      selectionButtonInfo: [
        { label: 'user_register.priority_question.yes', isActive: false },
        { label: 'user_register.priority_question.no', isActive: false },
      ],
      noInfoError: false,
      commune: null,
      department: null,
      recommendedSchools: null,
      schoolNamesList: null,
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      municipalityLabels: 'options/municipalityLabels',
      regions: 'options/regions',
      regionLabels: 'options/regionLabels',
      stages: 'options/stages',
    }),
    isRegionSet() {
      if (!this.department) {
        return true;
      }
      return false;
    },
    onlySchool() {
      if (CONFIG.tenant === 'newhaven') {
        return true;
      }
      return false;
    },
    getNumberOfButtons() {
      if (this.onlySchool) {
        return 1;
      }
      return 2;
    },
    getLocationInfoDisabled(index) {
      if (this.onlySchool) {
        return false;
      }
      if (index === 1) {
        return this.isRegionSet;
      }
      return !this.schoolNamesList;
    },
  },
  mounted() {
    this.commune = 'New Haven';
    this.setSchoolListArray();
  },
  methods: {
    ...mapActions({
      setStudents: 'newStudentRegister/setStudents',
      getSchoolsFromCommune: 'institutions/getSchoolsFromCommune',
    }),
    getLocationInfoPlaceholder(index) {
      if (this.onlySchool) {
        return 'user_register.campus_selection.placeholder';
      }
      if (index === 1) {
        return 'user_register.register_location.commune_placeholder';
      }
      return 'user_register.campus_selection.placeholder';
    },
    getLocationInfoItems(index) {
      if (this.onlySchool) {
        return this.schoolNamesList;
      }
      if (index === 1) {
        return this.setMunicipalitesArray();
      }
      return this.schoolNamesList;
    },
    setMunicipalitesArray() {
      let municipalitiesLabels = [];
      if (this.department) {
        const regionInfo = this.regions.find((region) => region.label === this.department);
        this.selectedMuncipalities = regionInfo.commune_options;
        municipalitiesLabels = this.selectedMuncipalities.map((muni) => muni.name);
      }
      return municipalitiesLabels;
    },
    setSchoolListArray() {
      if (this.commune) {
        const filter = [
          {
            fieldname: ELASTIC_SEARCH_FIELDS.COMMUNE,
            fieldvalue: this.commune,
          },
        ];
        const schoolList = this.getSchoolsFromCommune({ filter }).then((response) => {
          this.recommendedSchools = response.results;
          return response.results;
        });
        schoolList.then((response) => {
          this.schoolNamesList = response.map((school) => school.campus_name);
          return this.schoolNamesList;
        });
        return this.schoolNamesList;
      }
      return null;
    },
    setDepartment(value) {
      if (value) {
        this.department = value;
      }
      return this.department;
    },
    setAutcomplete(value, index) {
      if (value && index === 1) {
        this.commune = value;
        this.setSchoolListArray();
      }
      return this.commune;
    },
    nextStep() {
      if (!this.isActive) {
        return null;
      }
      const studentStage = [];
      if (this.currentStudent.grades.length > 0) {
        this.currentStudent.grades.forEach((grade) => {
          const stageInfo = this.stages.find((stage) => {
            const studentStageInfo = stage.grades.find((stageGrade) => stageGrade.id === grade);
            if (studentStageInfo) {
              return stage;
            }
            return null;
          });
          if (stageInfo && !studentStage.includes(stageInfo.id)) {
            studentStage.push(stageInfo.id);
          }
          return stageInfo;
        });
      }
      const studentsForm = {
        uuid: this.currentStudent.uuid,
        firstName: this.currentStudent.first_name,
        secondName: this.currentStudent.other_name,
        firstLastName: this.currentStudent.first_lastname,
        secondLastName: this.currentStudent.other_lastname,
        gradeInterest: this.currentStudent.grades,
        stagesInterest: studentStage,
        birthDay: this.currentStudent.birth_day,
        birthMonth: this.currentStudent.birth_month,
        birthYear: this.currentStudent.birth_year,
      };
      this.setStudents({ studentsForm, current: true });
      this.$emit('nextStep');
      return null;
    },
    setButtonsValue(i) {
      if (i === 0) {
        this.currentSchoolToSet = true;
        this.selectionButtonInfo[0].isActive = true;
        this.selectionButtonInfo[1].isActive = false;
      } else {
        this.currentSchoolToSet = false;
        this.selectionButtonInfo[0].isActive = false;
        this.selectionButtonInfo[1].isActive = true;
      }
      this.isActive = true;
      return i;
    },
  },
};
</script>
