var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"w-full d-flex flex-column bg-primary-dark py-12"},[_c('TextAtom',{staticClass:"text-left",attrs:{"value":_vm.$t(`feedback.probability_alert.title.${_vm.riskLabel.TETHER_CATEGORY.toLowerCase()}`),"tag":"h2","font":"poppins","color":"secondary-light","weight":"400","size":"subtitle"}}),_vm._l((_vm.$t('feedback.probability_alert.descriptions')),function(description,index){return _c('TextAtom',{key:index,staticClass:"mt-3 text-left",attrs:{"value":description,"tag":"h2","font":"inter","color":"secondary-light","weight":"400","size":"normal"}})}),_c('div',{staticClass:"my-4 w-full d-flex flex-row justify-center"},[_c('img',{attrs:{"src":require(`@/assets/feedback/probability-alert-${_vm.riskLabel.TETHER_CATEGORY.toLowerCase()}.svg`),"alt":"probability alert"}}),_c('div',{staticClass:"rounded-lg py-3 px-4 feedback-message-warning ml-5",style:({
        'background-color':
          _vm.riskLabel.TETHER_CATEGORY === _vm.TETHER_CATEGORY.HIGH
            ? '#F3276B'
            : (
              _vm.riskLabel.TETHER_CATEGORY === _vm.TETHER_CATEGORY.MEDIUM
                ? '#5627FF'
                : '#AD8FFF'
            ),
      })},[_c('TextAtom',{staticClass:"text-left",staticStyle:{"height":"fit-content"},attrs:{"value":_vm.$t('feedback.probability_alert.chance'),"tag":"span","font":"inter","color":"secondary-light","weight":"500","size":"default"}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }