<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        v-if="!inSimulation"
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper
        v-if="!inSimulation"
        :step="3"
      />
      <ModalContent
        :content-sections="inSimulation
          ? ['subtitle', 'selectionButton'] : ['smallTitle', 'subtitle', 'selectionButton']"
        :small-title-text="'user_register.additional_info.title'"
        :subtitle-text="isGuest && $route.name === 'Simulate'
          ? 'user_register.language_priorities.subtitle_guest'
          : 'user_register.language_priorities.subtitle'"
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        small-title-left
        :selection-button-column="!inSimulation"
        :selection-button-info="getLanguageArray"
        selection-button-wide
        @setSelectionButtonValue="setButtonsValue"
      />
      <section v-if="!!selectionBtnInfo.find((info) => info.id === 99)">
        <ModalContent
          :content-sections="['subtitle', 'registerInput']"
          :subtitle-text="'user_register.language_priorities.others_question'"
          subtitle-center
          subtitle-light
          :input-section="inputContent"
          @setValue="setValue"
        />
      </section>
    </div>
    <section
      class="d-flex align-center justify-end fill-width"
    >
      <CallToActions
        style="margin-top: 40px; margin-right: 8px"
        :types="['main']"
        :active-value="!inSimulation ? selectionBtnInfo.length > 0 : false"
        :main-button-text="inSimulation
          ? 'user_register.brother_in_campus.cancel' : 'user_register.continue'"
        :spaced="false"
        :main-active-light="inSimulation"
        small
        :loader="langLoader"
        :main-button-tiny="mobile"
        @continue="inSimulation ? $emit('backStep') : nextStep()"
      />
      <CallToActions
        v-if="inSimulation"
        style="margin-top: 40px; margin-left: 8px"
        :types="['main']"
        :active-value="selectionBtnInfo.length > 0"
        :main-button-text="'user_register.brother_in_campus.confirm'"
        small
        width-stretch
        :main-button-tiny="mobile"
        :spaced="false"
        :loader="langLoader"
        @continue="selectionBtnInfo.length > 0 ? nextStep() : null"
      />
    </section>
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LanguagePriorities',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  props: {
    inSimulation: {
      type: Boolean,
      default: false,
    },
    preFill: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectionBtnInfo: [],
      langLoader: false,
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
      nativeLanguageLabels: 'options/nativeLanguageLabels',
      isGuest: 'authentication/isGuest',
      guestSimulationData: 'simulation/guestSimulationData',
    }),
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    inputContent() {
      const inputContent = [
        {
          value: this.preFill ? this.getOtherName : null,
          noInfoError: false,
          placeholder: 'user_register.language_priorities.language',
          show: true,
          clickTag: this.registerType === 1
            ? 'click_reg_quick-register-language-other-input' : 'click_reg_detailed-register-language-other-input',
        },
      ];
      return inputContent;
    },
    getLanguageArray() {
      const selectionArray = [];
      this.nativeLanguageLabels.forEach((language) => {
        selectionArray.push(
          {
            id: language.id,
            label: this.$i18n.locale === 'en' ? language.nativelanguage_name_en : language.nativelanguage_name,
            isActive: !!this.selectionBtnInfo.find((info) => info.id === language.id),
          },
        );
      });
      return selectionArray;
    },
    getOtherName() {
      if (!this.isGuest) {
        const otherLang = this.currentStudent.native_languages.find((lang) => lang.id === 99);
        if (otherLang) {
          return otherLang.other_name;
        }
      }
      return null;
    },
    isActive() {
      return this.selectionBtnInfo.length > 0;
    },
  },
  watch: {
    currentStudent() {
      if (this.selectionBtnInfo.length > 0) {
        this.langLoader = false;
        this.$emit('nextStep');
      }
    },
  },
  mounted() {
    if (this.preFill) {
      if (this.isGuest) {
        const { lang: guestSimulationLanguages = [] } = this.guestSimulationData;
        guestSimulationLanguages.forEach((lang, index) => {
          this.selectionBtnInfo.push(
            {
              id: lang,
              index,
            },
          );
        });
      }
      if (this.currentStudent && Object.keys(this.currentStudent).length > 0) {
        this.currentStudent.native_languages.forEach((lang, index) => {
          this.selectionBtnInfo.push(
            {
              id: lang.nativelanguage.id,
              index,
            },
          );
        });
      }
    }
  },
  methods: {
    ...mapActions({
      setNativeLanguage: 'newStudentRegister/setNativeLanguage',
      setGuestSimulationData: 'simulation/setGuestSimulationData',
    }),
    nextStep() {
      if (this.isActive) {
        this.langLoader = true;
        const langPatchingArray = [];
        if (this.isGuest) {
          this.selectionBtnInfo.forEach((lang) => {
            langPatchingArray.push(lang.id);
          });
          this.setGuestSimulationData({ key: 'lang', value: langPatchingArray });
          this.$emit('nextStep');
        } else {
          this.selectionBtnInfo.forEach((lang, index) => {
            langPatchingArray.push(
              {
                uuid: this.currentStudent.uuid,
                status: 0,
                nativelanguage_id: lang.id,
              },
            );
            if (lang.id === 99) {
              Object.assign(langPatchingArray[index], { other_name: lang.otherName });
            }
          });
          this.setNativeLanguage({ languageArray: langPatchingArray, studentUuid: this.currentStudent.uuid });
        }
      }
      return null;
    },
    setValue(value) {
      this.selectionBtnInfo.map((info) => {
        if (info.id === 99) {
          Object.assign(info, { otherName: value });
        }
        return info;
      });
    },
    setButtonsValue(index, langId) {
      if (this.selectionBtnInfo.length === 0) {
        this.selectionBtnInfo.push(
          {
            id: langId,
            index,
          },
        );
        return this.selectionBtnInfo;
      }
      if (this.selectionBtnInfo.find((info) => info.id === langId) === undefined) {
        this.selectionBtnInfo.push(
          {
            id: langId,
            index,
          },
        );
        return this.selectionBtnInfo;
      }
      const newList = this.selectionBtnInfo.filter((option) => option.id !== langId);
      this.selectionBtnInfo = newList;
      return this.selectionBtnInfo;
    },
  },
};
</script>
