<template>
  <div class="register-main" style="margin-top: 16px">
    <MainTitle v-if="contentSections.includes('title')" :args="titleArg" :text="titleText" />
    <SmallTitle
      v-if="contentSections.includes('smallTitle')"
      :center-left="smallTitleLeft"
      :text="smallTitleText"
      :args="smallTitleArg"
      :primary="smallTitlePrimaryColor"
      :no-margin="smallTitleNoMargin"
    />
    <section class="fill-width" :class="{ 'd-flex align-center mb-5': rowInstructions }">
      <Subtitle
        v-if="contentSections.includes('subtitle')"
        :center="subtitleCenter"
        :subtitle="subtitleText"
        :light="subtitleLight"
        :neutral="subtitleNeutral"
        :args="subtitleArg"
        :row-instructions="rowInstructions"
        :no-margin="contentSections.includes('instructions') || subtitleNoMargin"
      />
      <TitleInstructions
        v-if="contentSections.includes('instructions')"
        :row-instructions="rowInstructions"
        :text="instructionText"
        :style="rowInstructions ? 'margin-left: 16px' : 'margin-bottom: 32px'"
      />
    </section>
    <InfoBox
      v-if="contentSections.includes('infoBox')"
      :border="infoBoxBorder"
      :text="infoBoxText"
      :sub-text="infoBoxSubText"
      :mixpanel-tag="infoBoxTag"
      :icon="infoBoxIcon"
      :align-left="infoBoxAlignLeft"
      :fill-width="infoBoxFillWidth"
      :info-padding="infoPadding"
      :info-array="infoBoxContentArray"
    />
    <ImageAtom
      v-if="contentSections.includes('imageAtom')"
      :source="imageSource"
      :alt-text="imageAltText"
      :rounded="imageRounded"
      :rounded-wide="imageRoundedWide"
      :medium="imageMedium"
    />
    <LoaderCircular
      v-if="contentSections.includes('loader')"
      :size="loaderSize"
      :color="loaderColor"
      :width="loaderWidth"
      show
    />
    <MdiIcon
      v-if="contentSections.includes('icon')"
      :color="iconColor"
      :icon="iconName"
      :icon-type="iconType"
    />
    <SvgLogo v-if="contentSections.includes('logo')" :logo="logoName" />
    <PrimaryText
      v-if="contentSections.includes('primaryText')"
      :text="primaryTextLabel"
      :mixpanel-tag="primaryTextTag"
      :secondary-color="primaryTextSecondaryColor"
      :bold="boldPrimaryText"
      :center="centerPrimaryText"
      :disabled="disabledPrimaryText"
      :font-family="primaryTextFontFamily"
    />
    <section v-if="contentSections.includes('MultiStyleText')">
      <div
        v-for="(section, index) in multiTextArray"
        :key="index"
      >
        <MultiStyleText
          :bold="section.bold"
          :bolder="section.bolder"
          :link="section.link"
          :underline="section.underline"
          :disabled="section.disabledValue"
          :secondary="section.secondary"
          :white="section.white"
          :spaced="section.spaced"
          :text="section.text"
          :args="section.args"
          :args2="section.args2"
          :args3="section.args3"
          :args4="section.args4"
          :text2="section.text2"
          :text3="section.text3"
          :text4="section.text4"
          :text5="section.text5"
          :center="section.center"
          :small="section.small"
          @linkClick="$emit('linkClick')"
          @underlineClick="$emit('underlineClick', index)"
        />
      </div>
    </section>
    <section v-if="contentSections.includes('google')" class="fill-width">
      <GoogleInput :mixpanel-tag="googleInputTag" @signInGoogle="$emit('signInGoogle')" />
      <Or />
    </section>
    <div v-if="contentSections.includes('infoButton')" class="fill-width">
      <section v-for="(section, i) in infoButtonsSection" :key="i">
        <InfoButton
          center
          :without-title="section.withoutTitle"
          :without-img="section.withoutImg"
          :section-info="[section]"
          :is-active="section.isActive"
          :mixpanel-tag="section.tag"
          @infoNextStep="$emit('setInfoButtonValue', i)"
        />
      </section>
    </div>
    <div
      v-if="contentSections.includes('selectionButton')"
      class="fill-width"
      :class="{
        'flex-column': selectionButtonColumn,
        'd-flex': !selectionButtonWide,
        'selection-button-gird': selectionButtonWide && !selectionButtonColumn,
      }"
    >
      <section
        v-for="(section, i) in selectionButtonInfo"
        :key="i"
        :class="{
          'fill-width': !selectionButtonNarrow,
        }"
        :style="i !== 1 ? 'margin-right: 12px;' : ''"
      >
        <SelectionButton
          :style="i !== 0 && selectionButtonColumn ? 'margin-top: 12px' : ''"
          :text="section.label"
          :is-active="section.isActive"
          :multi-selection-icons="selectionButtonMultiIcon"
          :click-disabled="selectionButtonClickDisabled"
          @setValue="$emit('setSelectionButtonValue', i, section.id)"
        />
      </section>
    </div>
    <section v-if="contentSections.includes('textSelect')" class="fill-width">
      <TextSelect
        :style="'margin-top: -24px; margin-bottom: -10px'"
        :placeholder="textSelectPlaceholders"
        :items="textSelectItems"
        :index="textSelectIndex"
        :select-value="textSelectInitialValue"
        :disabled="textSelectDisabled"
        :item-text="textSelectItemText"
        :item-value="textSelectItemValue"
        :return-object="textSelectReturnObject"
        :click-mixpanel-tag="clickTextSelectTag"
        :mixpanel-tag="textSelectTag"
        @setValue="setTextSelectValue"
      />
      <NoInfoErrorText v-if="textSelectNoInfoError" :text="'shared.location.field_required'" />
    </section>
    <div v-if="contentSections.includes('numberSelect')" class="fill-width">
      <section class="d-flex fill-width justify-space-between">
        <div
          v-for="(section, index) in numberSlotContent"
          :key="index"
          :style="numberSlotContent.length !== index ? 'margin-right: 12px' : ''"
        >
          <NumberSelect
            v-if="section.show"
            :placeholder="section.placeholder"
            :total-numbers="section.totalNumber"
            :start-number="section.startNumber"
            :initial-value="section.initialValue"
            :index="index"
            :mixpanel-tag="section.tag"
            :click-mixpanel-tag="section.selectorTag"
            @setValue="setNumberValue"
          />
        </div>
      </section>
      <NoInfoErrorText v-if="numberInfoError" center :text="'views.login.error.required_fields'" />
    </div>
    <RegisterAutocomplete
      v-if="contentSections.includes('autoComplete')"
      style="margin-bottom: -20px"
      :placeholder="autoCompletePlaceholder"
      :items="autoCompleteItems"
      :disabled-value="disabledAutocompleteValue"
      :index="autocompleteIndex"
      :reset-value="autocompleteResetValue"
      :value="autocompleteInitialValue"
      :click-mixpanel-tag="clickAutocompleteTag"
      :mixpanel-tag="autocompleteTag"
      :key-mixpanel-tag="keyAutocompleteTag"
      @setValue="setAutoComplete"
    />
    <TextExpand
      v-if="contentSections.includes('textExpand')"
      :placeholder="textPlaceholder"
      :index-text-expand="indexTextExpand"
      :disabled="textExpandDisabled"
      @setPlaceSelected="setPlaceSelected"
      @setAddressSelected="setAddressSelected"
      @openNotFoundAddressModal="openNotFoundAddressModal"
      @setCurrentLocation="setCurrentLocation"
    />
    <NoInfoErrorText
      v-if="autocompleteNoInfoError"
      :text="'shared.location.field_required'"
    />
    <div
      v-if="contentSections.includes('registerInput')"
      class="fill-width"
      :class="{ 'd-flex justify-space-between': inputsInline }"
    >
      <section
        v-for="(section, i) in inputSection"
        :key="i"
        :style="inputsInline && inputSection.length - 1 !== i ? 'margin-right: 8px;' : null"
        class="fill-width"
      >
        <div
          class="d-flex"
          :style="
            contentSections.includes('iconTextSelect') && i === 0 ? 'margin-bottom: -16px' : ''
          "
        >
          <TextSelect
            v-if="inputTextSelect && i === textSelectIndex"
            style="width: 110px; margin-right: 8px"
            :placeholder="textSelectPlaceholders"
            :items="textSelectItems"
            :index="textSelectIndex"
            :disabled="textSelectDisabled"
            :is-in-input-section="inputTextSelect"
            :select-value="section.selectValue"
            :item-text="textSelectItemText"
            :item-value="textSelectItemValue"
            :return-object="textSelectReturnObject"
            @setValue="setTextSelectValue"
          />
          <RegisterInput
            v-if="section.show"
            :placeholder="section.placeholder"
            :value="section.value"
            :index="i"
            :password-input="section.password"
            :input-type="section.inputType"
            :mixpanel-tag="section.tag"
            :click-mixpanel-tag="section.clickTag"
            :with-icon-text-select="contentSections.includes('iconTextSelect')"
            @setValue="setValue"
          />
          <IconTextSelect
            v-if="contentSections.includes('iconTextSelect') && i === 0"
            :items="iconSelectItems"
            :icon-color="iconSelectColor"
            @setValue="setTextSelectIconValue"
          />
        </div>
        <InputError
          v-if="errorTypes.length > 0"
          :class="errorTypes.length > 0 ? 'mb-2' : ''"
          :value-errors="setValueErrors(i)"
        />
        <NoInfoErrorText v-if="section.noInfoError" :text="'shared.location.field_required'" />
      </section>
      <PasswordValidation
        v-if="contentSections.includes('passValidation')"
        :password="inputSection[1].value"
      />
    </div>
    <div v-if="contentSections.includes('fileInput')" class="fill-width">
      <section
        v-for="(section, index) in fileInputSection"
        :key="index + 'file'"
        class="fill-width"
        :style="index !== 0 ? 'margin-top: 12px' : ''"
      >
        <RegisterFileInput
          :placeholder="section.placeholder"
          :value="section.value"
          :index="index"
          :file-types="section.fileTypes"
          :file="section.file"
          @setValue="setFileValue"
        />
        <NoInfoErrorText
          v-if="section.noInfoError"
          style="margin-top: 12px"
          :text="'shared.location.field_required'"
        />
      </section>
    </div>
    <div v-if="contentSections.includes('checkbox')" class="fill-width">
      <section
        v-for="(section, index) in checkboxSection"
        :key="index"
        class="register-main--check-div d-flex fill-width"
        :class="{
          'justify-center': checkboxInvertedTitle,
          'register-main--check-div--white-background': checkboxWhiteBackground,
        }"
      >
        <CheckboxInput
          v-if="!checkboxInvertedTitle"
          style="margin-right: 16px"
          :value="section.value"
          :disabled-value="section.disabledValue"
          :index="index"
          :mixpanel-tag="section.tag"
          @setValue="setCheckboxValue"
        />
        <div class="d-flex align-center">
          <MultiStyleText
            :bold="section.bold"
            :bolder="section.bolder"
            :link="section.link"
            :underline="section.underline"
            :disabled="section.disabledValue"
            :secondary="section.secondary"
            :white="section.white"
            :spaced="section.spaced"
            :text="section.text"
            :args="section.args"
            :text2="section.text2"
            :text3="section.text3"
            :text4="section.text4"
            :text5="section.text5"
            :args5="section.args5"
            @linkClick="$emit('linkClick')"
            @underlineClick="$emit('underlineClick', index)"
          />
          <CheckboxInput
            v-if="checkboxInvertedTitle"
            style="margin-left: 30px"
            :value="section.value"
            :disabled-value="section.disabledValue"
            :index="index"
            :mixpanel-tag="section.tag"
            @setValue="setCheckboxValue"
          />
        </div>
      </section>
    </div>
    <VerificationCodeInput
      v-if="contentSections.includes('verification')"
      :no-info-error="verificationNoInfoError"
      @setCode="setCode"
    />
    <div
      v-if="contentSections.includes('question') || contentSections.includes('link')"
      class="d-flex"
    >
      <QuestionLabel
        v-if="contentSections.includes('question')"
        gray-question
        :question-text="questionText"
      />
      <TextLink
        v-if="contentSections.includes('link')"
        :link-text="linkText"
        :mixpanel-tag="linkTextTag"
        @toLink="$emit('toLink')"
      />
    </div>
    <RecoverLink
      v-if="contentSections.includes('textLink')"
      :text="recoverLinkText"
      :mixpanel-tag="recoverLinkTag"
      @toRecovery="$emit('toRecovery')"
    />
  </div>
</template>

<script>
import { email, requiredIf } from 'vuelidate/lib/validators';
import RegisterAutocomplete from '@/components/atoms/autocompletes/RegisterAutocomplete.vue';
import InfoButton from '@/components/atoms/buttons/InfoButton.vue';
import RecoverLink from '@/components/atoms/buttons/RecoverLink.vue';
import SelectionButton from '@/components/atoms/buttons/SelectionButton.vue';
import TextLink from '@/components/atoms/buttons/TextLink.vue';
import MdiIcon from '@/components/atoms/icons/MdiIcon.vue';
import ImageAtom from '@/components/atoms/images/Image.vue';
import CheckboxInput from '@/components/atoms/inputs/CheckboxInput.vue';
import GoogleInput from '@/components/atoms/inputs/GoogleInput.vue';
import RegisterFileInput from '@/components/atoms/inputs/RegisterFileInput.vue';
import RegisterInput from '@/components/atoms/inputs/RegisterInput.vue';
import VerificationCodeInput from '@/components/atoms/inputs/VerificationCodeInput.vue';
import InfoBox from '@/components/atoms/labels/InfoBox.vue';
import InputError from '@/components/atoms/labels/InputError.vue';
import MultiStyleText from '@/components/atoms/labels/MultiStyleText.vue';
import NoInfoErrorText from '@/components/atoms/labels/NoInfoErrorText.vue';
import Or from '@/components/atoms/labels/Or.vue';
import PasswordValidation from '@/components/atoms/labels/PasswordValidation.vue';
import PrimaryText from '@/components/atoms/labels/PrimaryText.vue';
import QuestionLabel from '@/components/atoms/labels/QuestionLabel.vue';
import LoaderCircular from '@/components/atoms/loaders/LoaderCircular.vue';
import SvgLogo from '@/components/atoms/logos/SvgLogo.vue';
import IconTextSelect from '@/components/atoms/selects/IconTextSelect.vue';
import NumberSelect from '@/components/atoms/selects/NumberSelect.vue';
import TextSelect from '@/components/atoms/selects/TextSelect.vue';
import Subtitle from '@/components/atoms/subtitles/Subtitle.vue';
import TitleInstructions from '@/components/atoms/subtitles/TitleInstructions.vue';
import MainTitle from '@/components/atoms/titles/MainTitle.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import TextExpand from '@/components/molecules/textExpand/TextExpand.vue';

export default {
  name: 'ModalContent',
  components: {
    Subtitle,
    RegisterInput,
    InputError,
    NoInfoErrorText,
    GoogleInput,
    Or,
    RecoverLink,
    PasswordValidation,
    VerificationCodeInput,
    QuestionLabel,
    TextLink,
    SmallTitle,
    TitleInstructions,
    NumberSelect,
    TextSelect,
    TextExpand,
    RegisterFileInput,
    CheckboxInput,
    RegisterAutocomplete,
    MainTitle,
    InfoButton,
    SelectionButton,
    PrimaryText,
    MdiIcon,
    LoaderCircular,
    InfoBox,
    IconTextSelect,
    SvgLogo,
    ImageAtom,
    MultiStyleText,
  },
  props: {
    contentSections: {
      type: Array,
      default() {
        return [];
      },
    },
    errorTypes: {
      type: Array,
      default() {
        return [];
      },
    },
    subtitleText: {
      type: String,
      default: '',
    },
    subtitleNoMargin: {
      type: Boolean,
      default: false,
    },
    inputSection: {
      type: Array,
      default() {
        return [];
      },
    },
    recoverLinkText: {
      type: String,
      default: '',
    },
    recoverLinkTag: {
      type: String,
      default: '',
    },
    questionText: {
      type: String,
      default: '',
    },
    linkText: {
      type: String,
      default: '',
    },
    linkTextTag: {
      type: String,
      default: '',
    },
    verificationNoInfoError: {
      type: Boolean,
      default: false,
    },
    titleText: {
      type: String,
      default: '',
    },
    subtitleCenter: {
      type: Boolean,
      default: false,
    },
    subtitleLight: {
      type: Boolean,
      default: false,
    },
    subtitleNeutral: {
      type: Boolean,
      default: false,
    },
    smallTitleLeft: {
      type: Boolean,
      default: false,
    },
    smallTitleText: {
      type: String,
      default: '',
    },
    smallTitleArg: {
      type: String,
      default: '',
    },
    smallTitleNoMargin: {
      type: Boolean,
      default: false,
    },
    instructionText: {
      type: String,
      default: '',
    },
    numberSlotContent: {
      type: Array,
      default() {
        return [];
      },
    },
    googleInputTag: {
      type: String,
      default: '',
    },
    numberInfoError: {
      type: Boolean,
      default: false,
    },
    textSelectItems: {
      type: Array,
      default() {
        return [];
      },
    },
    textSelectIndex: {
      type: Number,
      default: 0,
    },
    textSelectDisabled: {
      type: Boolean,
      default: false,
    },
    textSelectPlaceholders: {
      type: String,
      default: '',
    },
    textSelectReturnObject: {
      // Determines whether the v-select component returns an object or the value specified in the item-value prop
      type: Boolean,
      default: false,
    },
    textSelectItemText: {
      // Defines the property to use as display text for the item
      // Vuetify uses by default 'text', and we're wrapping this setting
      // Example: itemText="label" -> item.label will be used as display text
      type: String,
      default: 'text',
    },
    textSelectItemValue: {
      // Defines the property to return as value for the item
      // Vuetify uses by default 'value', and we're wrapping this setting
      // Example: itemValue="number" -> item.number will be returned
      // If returnObject is true, this prop is ignored
      type: String,
      default: 'value',
    },
    textSelectInitialValue: {
      // Defines the initial value for the v-select component
      // If returnObject is true, this needs to be an object
      type: [Object, String],
      default: null,
    },
    autocompleteTag: {
      type: String,
      default: '',
    },
    clickAutocompleteTag: {
      type: String,
      default: '',
    },
    keyAutocompleteTag: {
      type: String,
      default: '',
    },
    fileInputSection: {
      type: Array,
      default() {
        return [];
      },
    },
    textSelectNoInfoError: {
      type: Boolean,
      default: false,
    },
    multiTextArray: {
      type: Array,
      default() {
        return [];
      },
    },
    checkboxSection: {
      type: Array,
      default() {
        return [];
      },
    },
    autoCompletePlaceholder: {
      type: String,
      default: '',
    },
    autoCompleteItems: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    textSelectTag: {
      type: String,
      default: '',
    },
    clickTextSelectTag: {
      type: String,
      default: '',
    },
    autocompleteNoInfoError: {
      type: Boolean,
      default: false,
    },
    autocompleteIndex: {
      type: Number,
      default: 0,
    },
    autocompleteResetValue: {
      type: Boolean,
      default: false,
    },
    autocompleteInitialValue: {
      // Defines the initial value for the v-autocomplete component
      type: String,
      default: '',
    },
    titleArg: {
      type: String,
      default: '',
    },
    subtitleArg: {
      type: String,
      default: '',
    },
    smallTitlePrimaryColor: {
      type: Boolean,
      default: false,
    },
    infoButtonsSection: {
      type: Array,
      default() {
        return [];
      },
    },
    selectionButtonInfo: {
      type: Array,
      default() {
        return [];
      },
    },
    selectionButtonColumn: {
      type: Boolean,
      default: false,
    },
    selectionButtonWide: {
      type: Boolean,
      default: false,
    },
    selectionButtonNarrow: {
      type: Boolean,
      default: false,
    },
    selectionButtonMultiIcon: {
      type: Boolean,
      default: false,
    },
    selectionButtonClickDisabled: {
      type: Boolean,
      default: false,
    },
    primaryTextLabel: {
      type: String,
      default: '',
    },
    primaryTextTag: {
      type: String,
      default: '',
    },
    primaryTextSecondaryColor: {
      type: Boolean,
      default: false,
    },
    primaryTextFontFamily: {
      type: String,
      default: null,
    },
    disabledPrimaryText: {
      type: Boolean,
      default: false,
    },
    boldPrimaryText: {
      type: Boolean,
      default: false,
    },
    centerPrimaryText: {
      type: Boolean,
      default: false,
    },
    inputTextSelect: {
      type: Boolean,
      default: false,
    },
    inputsInline: {
      type: Boolean,
      default: false,
    },
    checkboxInvertedTitle: {
      type: Boolean,
      default: false,
    },
    checkboxWhiteBackground: {
      type: Boolean,
      default: false,
    },
    disabledAutocompleteValue: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    logoName: {
      type: String,
      default: '',
    },
    iconType: {
      type: String,
      default: '',
    },
    loaderSize: {
      type: Number,
      default: 80,
    },
    loaderColor: {
      type: String,
      default: '#5627ff',
    },
    loaderWidth: {
      type: Number,
      default: 4,
    },
    infoBoxText: {
      type: String,
      default: '',
    },
    infoBoxSubText: {
      type: String,
      default: '',
    },
    infoBoxTag: {
      type: String,
      default: '',
    },
    infoBoxIcon: {
      type: String,
      default: '',
    },
    infoBoxBorder: {
      type: Boolean,
      default: false,
    },
    infoBoxAlignLeft: {
      type: Boolean,
      default: false,
    },
    infoBoxFillWidth: {
      type: Boolean,
      default: false,
    },
    infoBoxContentArray: {
      type: Array,
      default() {
        return [];
      },
    },
    iconSelectItems: {
      type: Array,
      default() {
        return [];
      },
    },
    iconSelectColor: {
      type: String,
      default: '',
    },
    imageSource: {
      type: String,
      default: '',
    },
    imageAltText: {
      type: String,
      default: '',
    },
    imageRounded: {
      type: Boolean,
      default: false,
    },
    imageRoundedWide: {
      type: Boolean,
      default: false,
    },
    imageMedium: {
      type: Boolean,
      default: false,
    },
    textPlaceholder: {
      type: String,
      default: '',
    },
    indexTextExpand: {
      type: Number,
      default: 0,
    },
    textExpandDisabled: {
      type: Boolean,
      default: true,
    },
    infoPadding: {
      type: Boolean,
      default: false,
    },
    rowInstructions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: null,
    };
  },
  validations: {
    email: {
      required: requiredIf(function useEmail() {
        return this.authMethod === 'email' && this.loginAssistAction === '';
      }),
      email(emailToTest) {
        return email(emailToTest);
      },
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      this.$emit('isInvalidEmail', false);
      if (!this.$v.email.$dirty) {
        return errors;
      }
      if (!this.$v.email.required) {
        this.$emit('isInvalidEmail', true);
        errors.push(this.$t('views.login.error.email.required'));
      }
      if (!this.$v.email.email) {
        this.$emit('isInvalidEmail', true);
        errors.push(this.$t('views.login.error.email.invalid'));
      }
      return errors;
    },
  },
  watch: {
    autocompleteResetValue: {
      handler() {
        this.itemArray = this.items;
      },
    },
  },
  methods: {
    setValue(value, passwordInput, index) {
      if (this.errorTypes.includes('email') && index === 0) {
        this.email = value;
        this.$v.email.$touch();
      }
      this.$emit('setValue', value, passwordInput, index);
    },
    setValueErrors(i) {
      if (this.errorTypes.includes('email') && i === 0 && this.errorTypes.includes('email-space')) {
        const emailAndSpaceErrors = this.emailErrors;
        if (emailAndSpaceErrors.includes(this.$t('views.login.error.email.whitespace'))) {
          return emailAndSpaceErrors;
        }
        emailAndSpaceErrors.push(this.$t('views.login.error.email.whitespace'));
        return emailAndSpaceErrors;
      }
      if (
        !this.errorTypes.includes('email')
        && i === 0
        && this.errorTypes.includes('email-space')
      ) {
        const emailAndSpaceErrors = [];
        emailAndSpaceErrors.push(this.$t('views.login.error.email.whitespace'));
        return emailAndSpaceErrors;
      }
      const emailError = this.emailErrors;
      if (this.errorTypes.includes('email') && i === 0 && emailError.length) {
        return this.emailErrors;
      }
      return [];
    },
    setCode(code) {
      this.$emit('setCode', code);
    },
    setNumberValue(index, value) {
      this.$emit('setValue', index, value);
    },
    setTextSelectValue(index, value) {
      this.$emit('setTextSelectValue', index, value);
    },
    setFileValue(value, index) {
      this.$emit('setFileValue', index, value);
    },
    setCheckboxValue(index) {
      this.$emit('setCheckboxValue', index);
    },
    setAutoComplete(value, index) {
      this.$emit('setAutoComplete', value, index);
    },
    setPlaceSelected(value) {
      this.$emit('setPlaceSelected', value);
    },
    setAddressSelected(value) {
      this.$emit('setAddressSelected', value);
    },
    setTextSelectIconValue(index, value) {
      this.$emit('setTextSelectIconValue', index, value);
    },
    openNotFoundAddressModal() {
      this.$emit('openNotFoundAddressModal');
    },
    setCurrentLocation(index) {
      this.$emit('setCurrentLocation', index);
    },
  },
};
</script>
