<template>
  <v-dialog
    v-if="isActive"
    v-model="isActive"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
    :fullscreen="isMobile"
    :content-class="isMobile ? 'feedback-dialog-mobile' : 'feedback-dialog'"
  >
    <div class="relative d-flex align-end flex-column full-height">
      <v-btn icon class="sticky right-0" @click="deactivateModal()">
        <v-icon color="#1E0C61">
          mdi-close
        </v-icon>
      </v-btn>
      <Feedback />
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Feedback from '../Feedback.vue';

export default {
  name: 'FeedbackModal',
  components: {
    Feedback,
  },
  computed: {
    ...mapGetters({
      activeModal: 'utils/activeModal',
    }),
    isActive() {
      return this.activeModal === 'Feedback';
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    ...mapActions({
      deactivateModal: 'utils/deactivateModal',
    }),
  },
};

</script>

