<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper :step="3" />
      <ModalContent
        :content-sections="['smallTitle']"
        :small-title-text="'user_register.additional_info.title'"
        small-title-left
      />
      <ModalContent
        style="margin-top: 40px"
        :content-sections="['subtitle', 'selectionButton', 'instructions']"
        :subtitle-text="'user_register.priority_question.subtitle'"
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :selection-button-info="selectionButtonInfo"
        :instruction-text="'user_register.priority_question.instructions'"
        @setSelectionButtonValue="setButtonsValue"
      />
    </div>
    <CallToActions
      :types="['main']"
      :active-value="isActive"
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'PriorityQuestion',
  components: {
    ModalHeader,
    CallToActions,
    ModalContent,
    RowStepper,
  },
  data() {
    return {
      isActive: false,
      selectionButtonInfo: [
        { label: 'user_register.priority_question.yes', isActive: false },
        { label: 'user_register.priority_question.no', isActive: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentStudent: 'authentication/currentStudent',
    }),
  },
  methods: {
    ...mapActions({ setStudents: 'newStudentRegister/setStudents' }),
    nextStep() {
      if (!this.isActive) {
        return null;
      }
      if (this.selectionButtonInfo[1].isActive) {
        return this.$emit('toFinale');
      }
      return this.$emit('nextStep');
    },
    setButtonsValue(i) {
      if (i === 0) {
        this.selectionButtonInfo[0].isActive = true;
        this.selectionButtonInfo[1].isActive = false;
      } else {
        this.selectionButtonInfo[0].isActive = false;
        this.selectionButtonInfo[1].isActive = true;
      }
      this.isActive = true;
      return i;
    },
  },
};
</script>
