import jwtDecode from 'jwt-decode';
import moment from 'moment';
import CONFIG from '@/config';
import intercom from '@/plugins/intercom';
import router from '@/router';
import services from '@/services';
import { deleteCookie, setCookie } from '@/utils/cookies';
import { studentCanApply, studentHasCompletedRegistration } from '@/utils/students';
import { TENANT_CONFIGURATION } from '@/constants/tenant';

const getDefaultState = () => ({
  exitsInfoGuest: false,
  user: {
    //  FIXME: Cambiar username por firstName y dejar usernameCheck como username
    // (username se  usa en diferentes partes de la app)
    usernameCheck: '',
    username: '',
    email: '',
    phone: null,
    dialCode: '',
    firstLogin: null,
    isGuest: true,
    token: '',
    guestToken: '',
    registerComplete: false,
    legalguardian: {
      firstName: '',
      otherName: '',
      firstLastname: '',
      otherLastname: '',
      birthDate: '',
      referenceLocation: {},
      uuid: '',
      guestStudent: '',
      guestStudentUuid: '',
      address: null,
      id: '',
      interestGrades: [],
      identification_link_1: '',
      identification_link_2: '',
    },
    guestLocation: {},
    guestLocationSet: false,
    guestLocationLastUpdated: 0,
    guestGrades: [],
    treatment: -1,
    guestStage: null,
    preCreated: false,
    uuid: '',
  },
  isGuestClickFirstTime: {
    location: false,
    grade: false,
  },
  students: [],
  allMainFilters: [
    {
      name: null,
      lastName: null,
    },
  ],
  currentStudent: {},
  registerStatus: null,
  resetPasswordStatus: null,
  tokenStatus: null,
  feedbackShow: false,
  wantCreateAccount: false,
  showMapModal: true,
  rerenderCount: 0,
  language: null,
  preCheckStatus: null,
  loading: false,
  loginLoading: false,
  linkLoading: false,
  resetStore: false,
  validCode: false,
  gauth: null,
  externalLoginInfo: {
    origin: null,
    redirectUrl: null,
  },
  authDone: false,
  loginModal: null,
  identificationResult: {
    found: false,
    verify: false,
    success: false,
    done: false,
  },
  googleIdentificationResult: {
    done: false,
    success: false,
    linked: false,
  },
  legalGuardianNeedsData: false,
  informationPages: false,
  forceScholarshipEligibility: false,
});

const state = {
  ...getDefaultState(),
  isNewVisitor: true,
};

const getters = {
  isNewVisitor: ({ isNewVisitor }) => isNewVisitor,
  gauth: ({ gauth }) => gauth,
  externalLoginInfo: ({ externalLoginInfo }) => externalLoginInfo,
  loading: ({ loading }) => loading,
  loginLoading: ({ loginLoading }) => loginLoading,
  linkLoading: ({ linkLoading }) => linkLoading,
  currentStudent: ({ currentStudent }) => currentStudent,
  userEmail: ({ user }) => user.email,
  usernameCheck: ({ user }) => user.usernameCheck,
  userPhone: ({ user }) => {
    const { number, dialCode } = user.phone ?? {};
    return number ? `${dialCode ?? ''}${number}` : null;
  },
  informationPages: ({ informationPages }) => informationPages,
  userDialCode: ({ user }) => user.phone?.dialCode ?? null,
  language: ({ language }) => language,
  userUsername: ({ user }) => user.username,
  legalGuardian: ({ user }) => user.legalguardian,
  legalGuardianUUID: ({ user }) => user.legalguardian.uuid,
  legalGuardianID: ({ user }) => user.legalguardian.id,
  legalGuardianAddress: ({ user }) => user.legalguardian.address,
  loggedToken: ({ user }) => user.token,
  guestToken: ({ user }) => user.guestToken,
  sessionToken: ({ user }) => (user.isGuest ? user.guestToken : user.token),
  sessionUUID: ({ user }) => {
    const token = user.isGuest ? user.guestToken : user.token;
    if (!token) return null;
    return jwtDecode(token)?.user_id;
  },
  legalGuardianInterestGrades: ({ user }) => user.legalguardian.interestGrades,
  students: ({ students }) => students,
  guestStudentUUID: ({ user }) => user.legalguardian.guestStudentUuid,
  registerComplete: ({ user }) => user.registerComplete,
  resetPasswordStatus: ({ resetPasswordStatus }) => resetPasswordStatus,
  userFirstLogin: ({ user }) => user.firstLogin,
  tokenStatus: ({ tokenStatus }) => tokenStatus,
  referenceLocation: ({ user }) => user.legalguardian.referenceLocation,
  homeLocation: ({ currentStudent, user }) => {
    const { coordinates: defaultCoordinates } = TENANT_CONFIGURATION.DEFAULTS.LOCATION;
    const defaultResponse = {
      ...defaultCoordinates,
      source: 'default',
    };
    const { isGuest } = user;
    if (isGuest && user.guestLocationSet && user.guestLocation !== undefined) {
      return {
        ...user.guestLocation.coordinates,
        source: 'guest',
        details: user.guestLocation,
      };
    }
    if (currentStudent && Object.keys(currentStudent).length !== 0) {
      if (currentStudent.address !== null) {
        if (
          currentStudent.address[0].address_lat !== null
          && currentStudent.address[0].address_lon !== null
        ) {
          return {
            lat: currentStudent.address[0].address_lat,
            lng: currentStudent.address[0].address_lon,
            source: 'student',
            details: currentStudent.address[0],
            sourceId: currentStudent.uuid,
          };
        }
      }
    }
    if (!currentStudent || Object.keys(currentStudent).length === 0 || currentStudent.address === null) {
      if (user.legalguardian.address !== null) {
        if (user.legalguardian.address.length > 0) {
          return {
            lat: user.legalguardian.address[0].address_lat,
            lng: user.legalguardian.address[0].address_lon,
            source: 'legalguardian',
            details: user.legalguardian.address[0],
            sourceId: user.legalguardian.uuid,
          };
        }
      }
    }
    return defaultResponse;
  },
  userLegalGuardianLocation: ({ user }) => (user.legalguardian?.address !== null
    ? user.legalguardian?.address[0] : null),
  isGuest: ({ user }) => CONFIG.guestOption && user.isGuest,
  wantCreateAccount: ({ wantCreateAccount }) => wantCreateAccount && CONFIG.guestOption,
  // eslint-disable-next-line
  guestGrades: ({ user }) => user.guestGrades,
  guestLocationSet: ({ user }) => user.guestLocationSet,
  guestLocationLastUpdated: ({ user }) => user.guestLocationLastUpdated,
  treatment: ({ user }) => user.treatment,
  guestLocation: ({ user }) => user.guestLocation,
  showMapModal: ({ showMapModal }) => showMapModal,
  feedbackShow: ({ feedbackShow }) => feedbackShow,
  rerenderCount: ({ rerenderCount }) => rerenderCount,
  preCheckStatus: ({ preCheckStatus }) => preCheckStatus,
  email: ({ user }) => user.email,
  isGuestClickFirstTime: ({ isGuestClickFirstTime }) => isGuestClickFirstTime,
  resetStore: ({ resetStore }) => resetStore,
  exitsInfoGuest: ({ exitsInfoGuest }) => exitsInfoGuest,
  validCode: ({ validCode }) => validCode,
  allMainFilters: ({ allMainFilters }) => allMainFilters,
  authDone: ({ authDone }) => authDone,
  loginModal: ({ loginModal }) => loginModal,
  identificationResult: ({ identificationResult }) => identificationResult,
  googleIdentificationResult: ({ googleIdentificationResult }) => googleIdentificationResult,
  legalGuardianNeedsData: ({ legalGuardianNeedsData }) => legalGuardianNeedsData,
  isPreCreated: ({ user }) => user.preCreated,
  activeGrades: ({ user, currentStudent }) => {
    let grades = TENANT_CONFIGURATION.DEFAULTS.GRADES;
    const isGuest = CONFIG.guestOption && user.isGuest;
    let source = 'default';
    if (isGuest && user.guestGrades?.length > 0) {
      grades = user.guestGrades;
      source = 'guest';
    } else if (currentStudent?.grades?.length > 0) {
      grades = currentStudent.grades;
      source = 'student';
    } else if (currentStudent?.currentEnrollment?.grade_label) {
      grades = [currentStudent.currentEnrollment.grade_label];
      source = 'enrollment';
    } else if (user.legalguardian.interestGrades?.length > 0) {
      grades = user.legalguardian.interestGrades;
      source = 'legalguardian';
    }

    return {
      grades,
      source,
    };
  },
  activeMunicipality: ({ user, currentStudent }) => {
    const defaultMunicipality = TENANT_CONFIGURATION.DEFAULTS.LOCATION.municipalityName;
    const isGuest = CONFIG.guestOption && user.isGuest;
    const guestMunicipality = user.guestLocation?.municipalityName || defaultMunicipality;
    if (isGuest) return guestMunicipality || defaultMunicipality;
    const studentMunicipality = currentStudent?.address?.[0]?.location?.name;
    const legalGuardianMunicipality = user.legalguardian.address?.[0]?.location;
    return studentMunicipality || legalGuardianMunicipality || defaultMunicipality;
  },
  forceScholarshipEligibility: ({ forceScholarshipEligibility }) => forceScholarshipEligibility,
};

const mutations = {
  setIsNewVisitor(state, value) {
    state.isNewVisitor = value;
  },
  setLoginLoading(state, { bool }) {
    state.loginLoading = bool;
  },
  setLinkLoading(state, { bool }) {
    state.linkLoading = bool;
  },
  setValidCode(state, { validCode }) {
    state.validCode = validCode;
  },
  // TODO: Check
  disableLegalGuardianGuestData(state, { newUUID = null } = {}) {
    state.user.isGuest = false;
    state.user.legalguardian.guestStudent = null;
    state.user.guestToken = null;
    state.user.legalguardian.uuid = newUUID;
    state.user.legalguardian.id = null;
  },
  setInformationPages(state, { informationPages }) {
    state.informationPages = informationPages;
  },
  setExternalLoginInfo(state, { info }) {
    state.externalLoginInfo = info;
  },
  setUser(state, {
    username, token, treatment, registerComplete, email, firstLogin,
  }) {
    // Unused
    state.user.username = username;
    state.user.token = token;
    state.user.treatment = treatment;
    state.user.registerComplete = registerComplete;
    state.user.email = email;
    state.user.firstLogin = firstLogin;

    intercom.updateUser({
      token,
    });
  },
  setUsername(state, { username }) {
    state.user.username = username;
  },
  setUsernameCheck(state, { usernameCheck }) {
    state.user.usernameCheck = usernameCheck;
  },
  setPreCreatedUser(state, { preCreatedUser }) {
    state.user.preCreated = preCreatedUser;
  },
  setUserToken(state, { token }) {
    state.user.token = token;
  },
  setGoogleAuthData(state, data) {
    state.gauth = data;
  },
  updateMainFilters(state, { info }) {
    state.allMainFilters[0].name = info.firstName;
    state.allMainFilters[0].lastName = info.firstLastName;
  },
  setCurrentStudent(state, { student }) {
    state.currentStudent = { ...student };
  },
  setAddress(state, { address }) {
    if (state.user.legalguardian.address === null) {
      state.user.legalguardian.address = [];
      state.user.legalguardian.address.push({ address_details: address });
    } else {
      state.user.legalguardian.address[0].address_details = address;
    }
  },
  setLegalGuardianLocation(state, { location }) {
    if (location) {
      if (!state.user.legalguardian.address) {
        state.user.legalguardian.address = [{}];
      }
      const lgAddress = [{
        address_lat: location.address_lat,
        address_lon: location.address_lon,
        address_details: location.address_details,
        location: location.location,
        address_name: location.address_name,
        city: location.city,
        code_national_id: location.code_national_id,
        p_location_id: location.p_location_id,
      }];
      state.user.legalguardian.address = lgAddress;
    } else {
      state.user.legalguardian.address = null;
    }
  },
  setLgIdentification(state, identification) {
    state.user.legalguardian.firstName = identification.first_name;
    state.user.legalguardian.firstLastname = identification.first_lastname;
    if (identification.other_name) {
      state.user.legalguardian.otherName = identification.other_name;
    }
    if (identification.other_lastname) {
      state.user.legalguardian.otherLastname = identification.other_lastname;
    }
    if (identification.birth_date) {
      state.user.legalguardian.birthDate = identification.birth_date;
    }
    const fullName = (`${identification.first_name ?? ''} ${identification.first_lastname ?? ''}`).trim();
    intercom.updateUserName({
      name: fullName,
    });
  },
  setLanguage(state, language) {
    state.language = language;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setPreCheckStatus(state, preCheckStatus) {
    state.preCheckStatus = preCheckStatus;
  },
  // deprecated!!
  setLegalGuardian(state, { legalGuardian }) {
    state.user.legalguardian = legalGuardian;
  },
  setLegalGuardianUUID(state, { legalGuardianUUID }) {
    state.user.legalguardian.uuid = legalGuardianUUID;
  },
  setLegalGuardianID(state, { legalGuardianID }) {
    state.user.legalguardian.id = legalGuardianID;
  },
  setReferenceLocation(state, { referenceLocation }) {
    state.user.legalguardian.referenceLocation = referenceLocation;
  },
  setStudents(state, { students }) {
    state.students = [...students];
  },
  setSelectFilter(state, { filters }) {
    state.allMainFilters = [...filters];
  },
  setRegisterStatus(state, { registerStatus }) {
    state.registerStatus = registerStatus;
  },
  setResetPasswordStatus(state, { status }) {
    state.resetPasswordStatus = status;
  },
  setTokenStatus(state, { status }) {
    state.tokenStatus = status;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  setFeedbackShow(state, { feedbackShow }) {
    state.feedbackShow = feedbackShow;
  },
  setWantCreateAccount(state, { wantCreateAccount }) {
    state.wantCreateAccount = wantCreateAccount;
  },
  setGuestLocation(state, { location }) {
    state.user.guestLocation = location;
  },
  setGuestLocationSet(state, newVal) {
    state.user.guestLocationSet = newVal;
  },
  setGuestLocationLastUpdated(state, newVal) {
    state.user.guestLocationLastUpdated = newVal;
  },
  setGuestGrades(state, { grades }) {
    state.user.guestGrades = grades;
  },
  setMapModal(state, { show }) {
    state.showMapModal = show;
  },
  setUserIsGuest(state, { isGuest }) {
    state.user.isGuest = isGuest;
  },
  setGuestStudent(state, { guestStudent }) {
    state.user.legalguardian.guestStudent = guestStudent;
  },
  setGuestStudentUuid(state, { guestStudent }) {
    state.user.legalguardian.guestStudentUuid = guestStudent;
  },
  setGuestToken(state, { token }) {
    state.user.guestToken = token;
  },
  setRerenderCount(state, { rerenderCount }) {
    state.rerenderCount = rerenderCount;
  },
  setEmail(state, { email }) {
    state.user.email = email;
  },
  setPhoneData(state, { dialCode, number }) {
    state.user.phone = {
      dialCode,
      number,
    };
  },
  setIsGuestClickFirstTime(state, { property }) {
    state.isGuestClickFirstTime[property] = true;
  },
  resetStore(state) {
    Object.assign(state, getDefaultState());
  },
  setResetStore(state, { data }) {
    state.resetStore = data;
  },
  setFirstLogin(state, { bool }) {
    state.user.firstLogin = bool;
  },
  setExitsInfoGuest(state, { exitsInfoGuest }) {
    state.exitsInfoGuest = exitsInfoGuest;
  },
  setLegalGuardianInterestGrades(state, { interestGrades }) {
    state.user.legalguardian.interestGrades = interestGrades;
  },
  setLgPersonalInfo(state, { personalInfo }) {
    state.user.legalguardian.firstName = personalInfo.firstName;
    state.user.legalguardian.otherName = personalInfo.otherName;
    state.user.legalguardian.firstLastname = personalInfo.firstLastname;
    state.user.legalguardian.otherLastname = personalInfo.otherLastname;

    state.user.legalguardian.identificationType = personalInfo.identificationType;
    state.user.legalguardian.nationality = personalInfo.nationality;
    state.user.legalguardian.identificationNumber = personalInfo.identificationNumber;
    state.user.legalguardian.identification_link_1 = personalInfo.identification_link_1;
    state.user.legalguardian.identification_link_2 = personalInfo.identification_link_2;
    const fullName = (`${personalInfo.firstName ?? ''} ${personalInfo.firstLastname ?? ''}`).trim();
    intercom.updateUserName({
      name: fullName,
    });
  },
  setLgPersonalIdInfo(state, { data }) {
    state.user.legalguardian.identificationNumber = data.identification_number;
    state.user.legalguardian.nationality = data.nationality;
    state.user.legalguardian.identificationType = data.identification_type;
    state.user.legalguardian.identification_link_1 = data.identification_link_1;
    state.user.legalguardian.identification_link_2 = data.identification_link_2;
  },
  setAuthDone(state, { bool }) {
    state.authDone = bool;
  },
  setLoginModal(state, { modal }) {
    state.loginModal = modal;
  },
  setIdentificationResult(state, { identificationResult }) {
    state.identificationResult = identificationResult;
  },
  setGoogleIdentificationResult(state, { googleIdentificationResult }) {
    state.googleIdentificationResult = googleIdentificationResult;
  },
  setLegalGuardianNeedsData(state, { bool }) {
    state.legalGuardianNeedsData = bool;
  },
  setForceScholarshipEligibility(state, { force }) {
    state.forceScholarshipEligibility = force;
  },
};

const actions = {
  setIsNewVisitor({ commit }, value) {
    commit('setIsNewVisitor', value);
  },
  resetStores({ commit, dispatch }) {
    commit('resetStore');
    dispatch('filters/resetStore', {}, { root: true });
    dispatch('newStudentRegister/resetStore', {}, { root: true });
    dispatch('favorites/resetFavorites', {}, { root: true });
    dispatch('digitalEnrollment/resetStore', {}, { root: true });
    dispatch('utils/resetStore', {}, { root: true });
    dispatch('feedback/resetStore', {}, { root: true });
    // dispatch('options/resetStores', {}, { root: true });
    // dispatch('institutions/reset', {}, { root: true });
    deleteCookie('tetherToken');
  },
  setLoginLoading({ commit }, { bool }) {
    commit('setLoginLoading', { bool });
  },
  setPhoneData({ commit }, { number, dialCode }) {
    commit('setPhoneData', { dialCode, number });
  },
  setLoginModal({ commit }, { modal }) {
    commit('setLoginModal', { modal });
  },
  setInformationPages({ commit }, { informationPages }) {
    commit('setInformationPages', { informationPages });
  },
  setLegalGuardianInterestGrades({ commit }, { interestGrades }) {
    commit('setLegalGuardianInterestGrades', { interestGrades });
  },
  setLinkLoading({ commit }, { bool }) {
    commit('setLinkLoading', { bool });
  },
  setUsername({ commit }, { username }) {
    commit('setUsername', { username });
  },
  setUsernameCheck({ commit }, { usernameCheck }) {
    commit('setUsernameCheck', { usernameCheck });
  },
  setPreCreatedUser({ commit }, { preCreatedUser }) {
    commit('setPreCreatedUser', { preCreatedUser });
  },
  setAddress({ commit }, { address }) {
    commit('setAddress', { address });
  },
  async setUserToken({ commit }, { token }) {
    commit('setUserToken', { token });
    if (token) {
      const expirationDate = new Date(jwtDecode(token).exp * 1000);
      setCookie('tetherToken', token, expirationDate.toUTCString());
    } else {
      deleteCookie('tetherToken');
    }
  },
  setIsGuestClickFirstTime({ commit }, { property }) {
    commit('setIsGuestClickFirstTime', { property });
  },
  setLgIdInfo({ commit }, { data }) {
    commit('setLgPersonalIdInfo', { data });
  },
  setFakeToken({ commit }) {
    const token = services.authenticationService.fakeToken().then((response) => {
      commit('setGuestToken', { token: response.data.key });
      return response.data.key;
    });
    return token;
  },
  async checkGuestToken({ dispatch, rootGetters }) {
    const loggedToken = rootGetters['authentication/loggedToken'];
    let guestToken = rootGetters['authentication/guestToken'];
    if (!guestToken || guestToken === loggedToken) {
      const newToken = await dispatch('setFakeToken');
      guestToken = newToken;
    }
    return guestToken;
  },
  /**
   * Executes the precheck for the user
   * @param {*} userIdentification
   * @param {string} userIdentification.username - username to check
   * @param {string} userIdentification.password - password to check
   * @param {boolean} quiet - if true, a failed precheck will not throw an error on the snackbar
   * @returns {Promise} - Promise with the precheck data
   */
  async doUserPreCheck({ commit, dispatch }, { username, password, quiet = false }) {
    const preCheckPayload = {
      username,
      password,
    };

    const userPreCheck = await services.prechecksService
      .preCheckUser({ preCheck: preCheckPayload })
      .then((response) => {
        const preCheckData = response.data;
        return preCheckData;
      })
      .catch((error) => {
        const preCheckData = {
          origin: 'Error on PreCheck',
          found: false,
          first_login: false,
          same_password: false,
          has_address: false,
          new_user: false,
          is_verified: false,
          error,
        };
        return preCheckData;
      });
    commit('setPreCheckStatus', userPreCheck);
    commit('setInformationPages', { informationPages: userPreCheck.first_login });
    if (!quiet && !(userPreCheck.found && userPreCheck.same_password)) {
      dispatch('utils/error', 'errors.login.precheck', { root: true });
    }
    return userPreCheck;
  },

  /**
   * Retrieves the user data from the API and stores it
   * @param {*} param1
   * @param {String} param1.legalGuardianUUID - UUID of the legal guardian
   * @returns {Promise} - Promise that resolves with the user data
   */
  async getUserDetails(
    { commit, dispatch, state },
    { legalGuardianUUID },
  ) {
    const { token } = state.user;
    return services.userRegistrationService
      .retrieveRegistrationData(legalGuardianUUID, token)
      .then((response) => {
        const {
          uuid, id, address, ...userData
        } = response.data;
        let personalizedName = userData.personalized_name;
        // FIXME: https://app.clickup.com/t/860r1tuw7
        const rawInterestGrades = userData.interest_grades ?? [];
        const interestGrades = rawInterestGrades.map((grade) => grade.grade_label);
        if (!personalizedName) {
          personalizedName = userData.first_name;
        }
        const { email } = state.user;
        const personalInfo = {
          firstName: userData.first_name,
          otherName: userData.other_name,
          firstLastname: userData.first_lastname,
          otherLastname: userData.other_lastname,
          identificationNumber: userData.identification_number,
          identificationType: userData.identification_type ?? null,
          nationality: userData.nationality ?? null,
          birthDate: userData.birth_date,
          identification_link_1: userData.identification_link_1,
          identification_link_2: userData.identification_link_2,
        };
        const contactPreferences = {
          phone: userData.phone,
          email: userData.email,
          contactPreferenceWhatsApp: userData.contact_preference_whatsapp,
          contactPreferenceSms: userData.contact_preference_sms,
          contactPreferenceEmail: userData.contact_preference_email,
        };
        commit('setUser', {
          username: personalizedName,
          token,
          treatment: null,
          registerComplete: null,
          email,
        });
        commit('setLegalGuardianInterestGrades', { interestGrades });
        commit('setUsername', { username: personalizedName });
        commit('setLegalGuardianUUID', { legalGuardianUUID: uuid });
        commit('setLegalGuardianID', { legalGuardianID: id });
        commit('setWantCreateAccount', { wantCreateAccount: false });
        commit('setLgPersonalInfo', { personalInfo });
        dispatch('cleanGuestLocationWithClosedModal');
        dispatch('explorer/setShowCard', { show: false }, { root: true });
        // Only save contactPreferences in the store, not in the API
        dispatch(
          'userRegister/setContactPreferences',
          { contactPreferences, persist: false },
          { root: true },
        );
        if (address !== null) {
          const newAddress = {
            address_lat: address[0].address_lat,
            address_lon: address[0].address_lon,
            address_details: address[0].address_details,
            location: address[0].location.name,
            address_name: address[0].address_name,
            code_national: address[0].location.code_national,
            zipCode: address[0].zip_code ? address[0].zip_code : null,
            city: address[0].location.name,
            code_national_id: address[0].location.code_national,
            p_location_id: address[0].location.id,
          };
          commit('setLegalGuardianLocation', { location: newAddress });
        } else {
          commit('setLegalGuardianLocation', { location: null });
        }
        return response.data;
      })
      .finally((response) => response);
  },

  /**
   * Get or create legal guardian for the current user
   * @param {*} userData
   * @param {object} userData.userPreCheck - user precheck data
   * @param {boolean} userData.withFavorites - whether to fetch the favorites list of the legal guardian
   * @param {boolean} userData.withRoles - whether to fetch the roles of the user
   *
   * @returns {object} Legal guardian data containing the following properties:
   * - mergedPreChecks: object containing the precheck data for the user and the legal guardian
   * - legalGuardian: object containing the legal guardian data that was found or created
   * - found: boolean indicating if a legal guardian was found or created
   * - missingData: boolean indicating if the legal guardian is missing data and needs to be completed
   */
  async getOrCreateLegalGuardian({ commit, dispatch }, {
    userPreCheck = {},
    withFavorites = true,
    withRoles = true,
    studentUUID = null,
  } = {}) {
    const { data: legalGuardianPreCheck } = await services.prechecksService.preCheckLegalGuardian();
    const {
      has_lg: found,
      has_lg_address: hasAddress,
      has_applicants: hasApplicants,
      lg_uuid: legalGuardianUUID,
    } = legalGuardianPreCheck;
    const mergedPreChecks = {
      ...userPreCheck,
      found,
      has_address: hasAddress,
      has_applicants: hasApplicants,
    };

    commit('setLegalGuardianNeedsData', { bool: !found || !(hasAddress || hasApplicants) });

    let legalGuardianData = null;
    if (found) { // Maybe || instead of &&? -> check response
      // The legal guardian exists, we need to get the user details for it.
      legalGuardianData = await dispatch('getUserDetails', { legalGuardianUUID })
        .then((data) => data)
        .catch(() => {
          dispatch('utils/error', 'errors.login.failed_user_retrieve', { root: true });
          return null;
        });
    } else {
      // Create the legal guardian
      legalGuardianData = await services.authenticationService.createLegalGuardian()
        .then(({ data }) => data)
        .catch(() => {
          dispatch('utils/error', 'errors.login.failed_user_create', { root: true });
          return null;
        });
    }
    if (legalGuardianData?.uuid) {
      commit('disableLegalGuardianGuestData', { newUUID: legalGuardianData.uuid });
    }

    if (hasApplicants) {
      await dispatch('getStudents', { updateStudent: studentUUID });
    } else {
      commit('setGuestGrades', { grades: TENANT_CONFIGURATION.DEFAULTS.GRADES });
    }

    if (legalGuardianData && withRoles) {
      await dispatch('getPrecheckRoles');
    }

    if (legalGuardianData && withFavorites) {
      dispatch(
        'favorites/retrieveListFavorites',
        { isInLogin: true, getDetails: false },
        { root: true },
      );
    }
    return {
      legalGuardianData, mergedPreChecks, found, missingData: found && !hasAddress && !hasApplicants,
    };
  },

  /**
   * Gets the students of the user and updates the store
   * If updateStudent is true, it will update the current student in the store as well
   * @param {*} parameters
   * @param {boolean} parameters.updateStudent - if true, it will update the current student in the store
   * @param {boolean} parameters.updateMarkers - if true, it will update the markers in the store
   * @returns {Promise} - resolves with the students of the user
   */
  async getStudents({ commit, dispatch, rootGetters }, { updateStudent = false, updateMarkers = false } = {}) {
    return services.authenticationService.retrieveStudents().then(({ data }) => {
      const students = data.map((student) => ({
        ...student,
        register_complete: studentHasCompletedRegistration(student),
        can_apply: studentCanApply(student),
      }));
      const legalGuardian = rootGetters['authentication/legalGuardian'];
      // TODO: Figure out why this is needed:
      legalGuardian.lg = true;
      if (updateStudent) {
        const updatedStudent = students.find((student) => student.uuid === updateStudent);
        dispatch('setCurrentStudent', { student: updatedStudent });
      } else {
        // Unset the current student if it's not in the list
        const currentStudent = rootGetters['authentication/currentStudent'];
        if (currentStudent && !students.find((student) => student.uuid === currentStudent.uuid)) {
          commit('setCurrentStudent', { student: {} });
        }
        const filterList = [...students, legalGuardian];
        filterList.sort((a, b) => a.id - b.id);
        commit('setSelectFilter', { filters: filterList });
      }
      commit('setStudents', { students });
      if (updateMarkers && students.length > 0) {
        const [student] = students;
        commit('setCurrentStudent', { student });
      }
      commit('setLoading', false);
      return Promise.resolve(students);
    });
  },
  /**
   * Log in with a UUID (fast login) and sets the token in the store
   * @param {*} data
   * @param {string} data.uuid - the UUID to log in with
   * @returns {Promise} - resolves with the UUID
   */
  doLoginWithUUID({ commit, dispatch }, { uuid }) {
    dispatch('setExitsInfoGuest', { exitsInfoGuest: false });
    return services.authenticationService.loginWithUUID({ uuid })
      .then((response) => {
        const token = response.data.key;
        commit('setUserToken', { token });
        commit('setUserIsGuest', { isGuest: false });
        commit('setLoading', false);
        return uuid;
      });
  },
  async doLoginFeedback({ commit, dispatch }, { uuid, alliance = false, studentUUID = null }) {
    // TODO: TEST and document THIS
    return dispatch('doLoginWithUUID', { uuid })
      .then(async () => {
        const preCheckData = {
          origin: 'Faked in doLoginFeedback',
          found: true,
          first_login: true,
          same_password: true,
          is_verified: true,
          has_address: true,
          has_applicants: true,
        };
        commit('setPreCheckStatus', preCheckData);
        commit('setInformationPages', { informationPages: false });
        dispatch('userRegister/setAllianceUserFlag', { isAlliance: alliance }, { root: true });
        await dispatch('getOrCreateLegalGuardian', { userPreCheck: preCheckData, studentUUID }).then(() => {
          commit('setLoginLoading', { bool: false });
          commit('setAuthDone', { bool: true });
        });
      }).catch(() => {
        dispatch('userRegister/setAllianceUserFlag', { isAlliance: false }, { root: true });
      });
  },
  verifyCode({ commit, rootGetters, dispatch }, {
    code, email, phone, authType,
  }) {
    services.authenticationService
      .verifyCode({
        email,
        phone,
        code,
        authMethod: authType,
      })
      .then(({ data }) => {
        commit('setValidCode', { validCode: data.is_valid });
        const userPreCheck = {
          origin: 'Code Verification',
          found: true,
          first_login: true,
          same_password: true,
          has_address: false,
          is_verified: data.is_valid,
        };
        commit('setPreCheckStatus', userPreCheck);
        commit('setInformationPages', { informationPages: userPreCheck.first_login });
        if (userPreCheck.is_verified) {
          const token = rootGetters['authentication/loggedToken'];
          services.authenticationService.refreshToken({ token }).then((response) => {
            const { token } = response.data;
            dispatch('setUserToken', { token });
            dispatch('getOrCreateLegalGuardian', { userPreCheck })
              .catch(() => commit('setLoading', false));
          });
        }
      })
      .catch(() => {
        commit('setValidCode', false);
      });
  },
  /**
   * Excecute a login through the Tether users service for an existing user (preChecked)
   * @param {*} loginData
   * @param {string} loginData.phone - the phone number of the user
   * @param {string} loginData.email - the email of the user
   * @param {string} loginData.password - the password of the user
   * @param {string} loginData.captcha
   * @param {boolean} loginData.goToMap - if true, it will redirect to the map after login
   * @param {boolean} loginData.linkAccounts - if true, it will link the account with a GAuth after login
   * @param {boolean} loginData.getLegalGuardian - if true, it will get the legal guardian after login
   * @returns {Promise} - resolves with the user token
   */
  async doLogin({ commit, dispatch, rootGetters }, {
    username, email, password, captcha, goToMap = false, linkAccounts = false, getLegalGuardian = true,
  }) {
    dispatch('setExitsInfoGuest', { exitsInfoGuest: false });
    const loginModal = rootGetters['authentication/loginModal'];
    const userPreCheck = await dispatch('doUserPreCheck', { username: username || email, password });
    const loginPayload = {
      password,
      captcha: captcha ?? undefined,
      username: username ?? email,
      email: email ?? undefined,
    };
    return services.authenticationService.login(loginPayload)
      .then(async ({ data: { key: token } }) => {
        dispatch('setUserToken', { token });
        commit('setUserIsGuest', { isGuest: false });

        // We dispatch the linkAccounts action here (and wait for it to finish)
        // because we need to have the updated token
        if (linkAccounts) await dispatch('linkAccounts');
        if (getLegalGuardian) await dispatch('getOrCreateLegalGuardian', { userPreCheck });

        commit('setAuthDone', { bool: true });
        commit('setLinkLoading', { bool: false });
        commit('setLoginLoading', { bool: false });
        if (goToMap && loginModal !== 'applicationFlow') {
          router.push({ name: 'Explorer' });
        }
      }).catch(() => {
        dispatch('utils/error', 'errors.login.failed', { root: true });
      });
  },
  setCurrentStudentWithUUID({ getters, dispatch }, { uuid, callback }) {
    const student = getters.students.find((student) => student.uuid === uuid);
    if (student) {
      dispatch('setCurrentStudent', { student });
    }
    if (callback) {
      callback();
    }
  },
  async setCurrentStudent({ commit, dispatch, getters }, { student, updateMap = true }) {
    commit('setCurrentStudent', { student });
    const { coordinates: defaultCoordinates } = TENANT_CONFIGURATION.DEFAULTS.LOCATION;
    if (updateMap) {
      let { lat, lng } = { ...defaultCoordinates };
      if (getters.isGuest && getters.guestLocation.coordinate !== undefined) {
        lat = getters.guestLocation.coordinate.lat;
        lng = getters.guestLocation.coordinate.lng;
      } else if (student && Object.keys(student).length > 0 && student.address !== null) {
        if (student.address[0].location !== null) {
          if (student.address[0].location.default_coordinate.lat !== 0) {
            lat = student.address[0].location.default_coordinate.lat;
          }
          if (student.address[0].location.default_coordinate.lon !== 0) {
            lng = student.address[0].location.default_coordinate.lon;
          }
        }
        if (
          student.address[0].address_lat !== null
          && student.address[0].address_lon !== null
        ) {
          lat = student.address[0].address_lat;
          lng = student.address[0].address_lon;
        }
      } else if (getters.legalGuardianAddress) {
        lat = parseFloat(getters.legalGuardianAddress[0].address_lat);
        lng = parseFloat(getters.legalGuardianAddress[0].address_lon);
      }
      dispatch('explorer/navigateTo', { lat, lng }, { root: true });
      dispatch('institutions/retrieveCampuses', { }, { root: true });
    }
  },
  setLegalGuardian({ commit }, { legalGuardian }) {
    commit('setLegalGuardian', { legalGuardian });
  },
  setReferenceLocation({ commit }, { referenceLocation }) {
    commit('setReferenceLocation', { referenceLocation });
  },
  setResetStore({ commit }, { data }) {
    commit('setResetStore', { data });
  },
  setLanguage({ commit }, language) {
    commit('setLanguage', language);
  },
  setLoading({ commit }, loading) {
    commit('setLoading', loading);
  },
  setLgIdentification({ commit }, { identification }) {
    commit('setLgIdentification', identification);
  },
  /**
   * Does a login based on a user token
   * @param {*} data
   * @param {string} data.token - the user token to use for login
   */
  async doTokenLogin({ commit, dispatch }, { token }) {
    const userInfo = jwtDecode(token);
    commit('setEmail', { email: userInfo.email });
    await dispatch('setUserToken', { token })
      .then(async () => {
        await dispatch('doLoginWithUUID', { uuid: userInfo.user_id })
          .then(() => {
            commit('setAuthDone', { bool: true });
          });
      });
  },
  /**
   * Registers a new user and logs them in
   * @param {*} data
   * @param {object} data.registerData - the data to use for registration
   */
  async doRegister({ commit, dispatch }, { registerData }) {
    const guestToken = await dispatch('checkGuestToken');
    const contactPreferences = {
      phone: registerData.phone,
      email: registerData.email,
    };
    dispatch('userRegister/setContactPreferences', { contactPreferences, persist: false }, { root: true });
    services.authenticationService
      .register({
        email: registerData.email,
        phone: registerData.phone,
        password1: registerData.password1,
        password2: registerData.password2,
        token: guestToken,
      })
      .then((response) => {
        const token = response.data.key;
        dispatch('doTokenLogin', { token });
      })
      .catch((error) => {
        commit('setRegisterStatus', { registerStatus: error.message });
        commit('setLoading', false);
      });
  },
  async setLegalGuardianLocation({ commit, dispatch }, { address }) {
    await dispatch(
      'explorer/navigateTo',
      { lat: address.address_lat, lng: address.address_lon },
      { root: true },
    ).then(() => {
      commit('setLegalGuardianLocation', { location: address });
    });
  },
  /**
   * Handles the identification of a user, which can be done by email or phone
   * If in the register flow, it will check if the user exists. If it does it logs them in and if not it registers them
   *
   * @param {*} data
   * @param {object} data.identification - the identification data
   * @param {string} data.identification.email - the email to use for identification
   * @param {object} data.identification.phoneData - the phone data to use for identification
   * @param {string} data.identification.phoneData.dialCode - the phone dial code
   * @param {string} data.identification.phoneData.number - the phone number
   * @param {string} data.identification.password - the password to use for identification
   * @param {string} data.identification.captcha - the captcha to use for identification
   * @param {boolean} data.register - whether or not the identification is part of the register flow
   * @param {boolean} data.linkAccounts - whether or not the identification is part of the link accounts flow
   * @returns {Promise} - a promise that resolves when the identification is done
   */
  async doIdentification(
    { commit, dispatch, rootGetters },
    { identification = {}, register = false, linkAccounts = false } = {},
  ) {
    commit('setLoading', true);

    const inDigitalEnrollment = rootGetters['digitalEnrollment/inDigitalEnrollment'];

    const {
      email, phoneData, password, captcha,
    } = identification;

    let username = email;
    let phone = null;
    let authMethod = 'email';
    if (phoneData) {
      commit('setPhoneData', { ...phoneData });
      phone = phoneData.dialCode + phoneData.number;
      username = phone;
      authMethod = 'phone';
    } else if (email) {
      commit('setEmail', { email });
    }
    const userPreCheck = await dispatch('doUserPreCheck', { username, password, quiet: register });
    const { found, is_verified: isVerified, same_password: samePassword } = userPreCheck;

    // Possible cases:
    const successfulIdentification = found && samePassword;
    const notVerified = successfulIdentification && !isVerified;
    const newUser = !found && register;

    const identificationResult = {
      found,
      verify: notVerified || newUser,
      success: successfulIdentification || newUser,
      done: true,
    };

    if (successfulIdentification) {
      const goToMap = isVerified && !linkAccounts && !inDigitalEnrollment;
      dispatch('doLogin', {
        username,
        email,
        phone,
        password,
        captcha,
        goToMap,
        linkAccounts,
        getLegalGuardian: isVerified || linkAccounts,
      });
      if (notVerified) {
        dispatch('resendVerificationCode', {
          email,
          phone,
          authMethod,
        });
      }
    } else if (newUser) {
      // Not found & in register flow -> register
      const registerData = {
        email,
        phone,
        password1: password,
        password2: password,
        captcha,
        errorCallback: (error) => dispatch('utils/error', error, { root: true }),
      };
      dispatch('doRegister', { registerData });
    }
    commit('setAuthDone', { bool: successfulIdentification && isVerified });
    commit('setLoading', false);
    commit('setIdentificationResult', { identificationResult });
    return identificationResult;
  },

  /**
   * Gets the roles for the current user
   */
  getPrecheckRoles({ dispatch, rootGetters }) {
    // FIXME: This is NOT a Precheck, it's a getRoles for the current user
    // It should be moved to a different module (new roles module?). But the semantics
    // are wrong by calling it precheckRoles and making it live in the prechecks service.
    services.prechecksService
      .preCheckRoles()
      .then((response) => {
        let roles = response.data;

        // delete if roles is empty
        if (roles.length === 0) {
          roles = [
            {
              id: 2653,
              role: {
                id: 2,
                name: 'Legal Guardian',
                code: 'legalguardian',
                enabled: true,
                options: [
                  {
                    id: 3,
                    role: 2,
                    module_name: 'tools',
                    module_code: 'tools',
                    enabled: true,
                    order: 1,
                  },
                  {
                    id: 5,
                    role: 2,
                    module_name: 'dashboard-map',
                    module_code: 'dashboard-map',
                    enabled: true,
                    order: 3,
                  },
                  {
                    id: 6,
                    role: 2,
                    module_name: 'messages',
                    module_code: 'messages',
                    enabled: true,
                    order: 4,
                  },
                  {
                    id: 7,
                    role: 2,
                    module_name: 'school-list',
                    module_code: 'school-list',
                    enabled: true,
                    order: 5,
                  },
                ],
                role_options: null,
              },
              // FIXME: What is this user?
              user: 'e3437af1-ffc1-46dd-92d5-67af37433c8c',
              is_dummy: false,
            },
          ];
        }
        if (roles.length === 1) {
          const roleInfo = {
            id: roles[0].role.id,
            roleCode: roles[0].role.code,
            roleName: roles[0].role.name,
            roleOptions: roles[0].role.options,
          };
          const currentRole = {
            roleInfo,
            rolePermissions: roles[0].role.permissions,
          };
          if (roles[0].role.id === 2) {
            dispatch('roles/setLegalGuardianRoleInfo', { info: roleInfo }, { root: true }).then(
              () => {
                dispatch(
                  'roles/setLegalGuardianRolePermissions',
                  { info: roles[0].role.permissions },
                  { root: true },
                ).then(() => {
                  dispatch('roles/setCurrentRole', { info: currentRole }, { root: true });
                });
              },
            );
            dispatch('roles/setHeadMasterRoleInfo', { info: null }, { root: true }).then(() => {
              dispatch('roles/setHeadMasterRolePermissions', { info: null }, { root: true });
            });
          } else {
            dispatch('roles/setHeadMasterRoleInfo', { info: roleInfo }, { root: true }).then(() => {
              dispatch(
                'roles/setHeadMasterRolePermissions',
                { info: roles[0].role.permissions },
                { root: true },
              ).then(() => {
                dispatch('roles/setCurrentRole', { info: currentRole }, { root: true });
              });
            });
            dispatch('roles/setLegalGuardianRoleInfo', { info: null }, { root: true }).then(() => {
              dispatch('roles/setLegalGuardianRolePermissions', { info: null }, { root: true });
            });
          }
        } else {
          roles.forEach((roleData) => {
            if (roleData.role.id === 3) {
              const roleInfo = {
                id: roleData.role.id,
                roleCode: roleData.role.code,
                roleName: roleData.role.name,
                roleOptions: roleData.role.options,
              };
              dispatch('roles/setHeadMasterRoleInfo', { info: roleInfo }, { root: true }).then(
                () => {
                  dispatch(
                    'roles/setHeadMasterRolePermissions',
                    { info: roleData.role.permissions },
                    { root: true },
                  ).then(() => {
                    const headMasterRole = rootGetters['roles/headMasterRole'];
                    if (headMasterRole !== null) {
                      const currentRole = {
                        roleInfo,
                        rolePermissions: roleData.role.permissions,
                      };
                      dispatch('roles/setCurrentRole', { info: currentRole }, { root: true });
                    }
                  });
                },
              );
            } else if (roleData.role.id === 2) {
              const roleInfo = {
                id: roleData.role.id,
                roleCode: roleData.role.code,
                roleName: roleData.role.name,
                roleOptions: roleData.role.options,
              };
              dispatch('roles/setLegalGuardianRoleInfo', { info: roleInfo }, { root: true }).then(
                () => {
                  dispatch(
                    'roles/setLegalGuardianRolePermissions',
                    { info: roleData.role.permissions },
                    { root: true },
                  ).then(() => {
                    const headMasterRole = rootGetters['roles/headMasterRole'];
                    if (headMasterRole === null) {
                      const currentRole = {
                        roleInfo,
                        rolePermissions: roleData.role.permissions,
                      };
                      dispatch('roles/setCurrentRole', { info: currentRole }, { root: true });
                    }
                  });
                },
              );
            }
          });
        }
      })
      .catch(() => {
        dispatch('utils/error', 'errors.login.roles', { root: true });
      })
      .finally((response) => response);
  },
  retrieveResetPasswordToken({ commit }, {
    email, phone, authMethod, callback,
  }) {
    services.authenticationService
      .resetPasswordToken({ email, phone, authMethod })
      .then(() => {
        commit('setTokenStatus', { status: 'OK' });
        callback('ok');
      })
      .catch(() => {
        commit('setTokenStatus', { status: 'ERROR' });
        callback('error');
      });
  },
  changePassword({ commit, dispatch }, { password1, password2, callback }) {
    commit('setLoading', true);
    services.authenticationService
      .changePassword({ password1, password2 })
      .then(() => {
        commit('setFirstLogin', { bool: false });
        commit('setResetPasswordStatus', { status: 'OK' });
        dispatch('roles/setHeadMasterFirstLogin', { bool: false }, { root: true });
        commit('setLoading', false);
      })
      .catch(() => {
        commit('setResetPasswordStatus', { status: 'ERROR' });
      })
      .finally(() => callback());
  },
  tokenResetPassword({ commit, dispatch }, {
    password1, password2, callback, legalGuardian = false,
  }) {
    commit('setLoading', true);
    services.authenticationService
      .tokenResetPassword({ password1, password2 })
      .then(() => {
        commit('disableLegalGuardianGuestData');
        commit('setFirstLogin', { bool: false });
        commit('setResetPasswordStatus', { status: 'OK' });
        if (legalGuardian) {
          dispatch('roles/setHeadMasterFirstLogin', { bool: false }, { root: true });
        }
        commit('setLoading', false);
      })
      .catch(() => {
        commit('setResetPasswordStatus', { status: 'ERROR' });
      })
      .finally(() => callback());
  },
  resetPassword({ commit }, { token, newPassword, callback }) {
    services.authenticationService
      .resetPassword({
        token,
        newPassword,
      })
      .then(() => commit('setResetPasswordStatus', { status: 'OK' }))
      .catch(() => commit('setResetPasswordStatus', { status: 'ERROR' }))
      .finally(() => callback());
  },
  resetHeadMasterPassword({ commit }) {
    commit('setResetPasswordStatus', { status: 'LOGGED-RESET' });
  },
  resendVerificationCode({ dispatch }, {
    email, phone, authMethod, callback,
  }) {
    services.authenticationService
      .resendVerificationCode({ email, phone, authMethod })
      .then((response) => {
        if (response.status === 200) {
          if (callback) callback();
        } else {
          dispatch('utils/error', response.data.detail, { root: true });
        }
      })
      .catch((response) => {
        dispatch('utils/error', response.response.data.detail, { root: true });
      });
  },
  logoutSuccess({ commit, dispatch }) {
    dispatch('resetStores').then(() => {
      const { LOCATION, GRADES } = TENANT_CONFIGURATION.DEFAULTS;
      dispatch('setGuestData', {
        location: LOCATION,
        grades: GRADES,
      }).then(() => {
        commit('setExitsInfoGuest', { exitsInfoGuest: true });
        commit('setAuthDone', { bool: false });
        dispatch('checkGuestToken');
      });
      services.authenticationService.logout().then(() => null);
    });
  },
  updateMainFilters({ commit }, { info }) {
    commit('updateMainFilters', { info });
  },
  async linkAccounts({ dispatch, rootGetters, commit }) {
    const gauth = rootGetters['authentication/gauth'];

    if (!gauth) {
      return dispatch('utils/error', 'errors.login.link_failed', { root: true });
    }

    return services.authenticationService.linkAccounts({ gauth })
      .then(async () => {
        const oldToken = rootGetters['authentication/loggedToken'];
        await services.authenticationService.refreshToken({ token: oldToken })
          .then(async (response) => {
            const { token: newToken } = response.data;
            await dispatch('setUserToken', { token: newToken });
            const preCheckData = {
              origin: 'Google',
              found: true,
              first_login: true,
              same_password: true,
              is_verified: true,
            };
            commit('setPreCheckStatus', preCheckData);
            commit('setInformationPages', { informationPages: preCheckData.first_login });
          });
        commit('setGoogleAuthData', null);
        return { linked: true };
      })
      .catch(() => ({ linked: false }));
  },
  setWantCreateAccount({ commit }, { wantCreateAccount }) {
    commit('setWantCreateAccount', { wantCreateAccount });
  },
  setRerenderCount({ commit }, { rerenderCount }) {
    // TODO: Delete=ion candidate
    commit('setRerenderCount', { rerenderCount });
  },
  async setGuestData(
    { commit, dispatch },
    {
      location, grades,
    },
  ) {
    if (location && Object.keys(location).length > 0) {
      await dispatch('setGuestLocation', { location });
    }
    if (grades !== null) {
      commit('setGuestGrades', { grades });
    }
    intercom.setGuestUser();
  },
  setFirstLogin({ commit }, bool) {
    commit('setFirstLogin', { bool });
  },
  cleanGuestData({ commit }) {
    commit('setGuestLocation', { location: {} });
    commit('setGuestLocationSet', false);
    commit('setGuestGrades', { grades: [] });
    commit('setGuestLocationLastUpdated', 0);
  },
  deleteStudent({ dispatch, state }, { uuid }) {
    try {
      services.authenticationService.deleteStudent({ uuid }).then(() => {
        dispatch('getStudents').then(() => {
          const { students, currentStudent } = state;
          if (students.length > 0) {
            return currentStudent;
          }
          dispatch('setCurrentStudent', { student: {} });
          return null;
        });
      });
      return null;
    } catch (error) {
      dispatch('utils/error', error, { root: true });
      return null;
    }
  },
  setMapModal({ commit }, { show }) {
    commit('setMapModal', { show });
  },
  setGuestLocation({ commit, dispatch }, { location }) {
    commit('setGuestLocation', { location });
    commit('setGuestLocationSet', true);
    dispatch(
      'explorer/navigateTo',
      { lat: location.coordinates.lat, lng: location.coordinates.lng },
      { root: true },
    );
    commit('setMapModal', { show: false });
    commit('setGuestLocationLastUpdated', moment());
  },
  cleanGuestLocationWithClosedModal({ commit }) {
    commit('setGuestLocation', {});
    commit('setGuestLocationSet', false);
    commit('setMapModal', { show: false });
  },
  setExitsInfoGuest({ commit }, { exitsInfoGuest }) {
    commit('setExitsInfoGuest', { exitsInfoGuest });
  },
  async setExternalLoginFlow({ commit, dispatch }, { loginQueryParams, reset }) {
    const {
      origin, redirect_url: redirectURL, ...specificMetadata
    } = loginQueryParams ?? {};
    const redirectInfo = {
      origin: reset ? null : (origin ?? null),
      redirectURL: reset ? null : (redirectURL ?? null),
    };
    commit('setExternalLoginInfo', { info: redirectInfo });
    const validMetadata = await dispatch('setExternalLoginMetadata', { origin, metadata: specificMetadata });
    return { validExternalLogin: validMetadata };
  },
  async setInternalDgeLoginFlow({ commit, dispatch }, { loginQueryParams }) {
    const {
      origin, redirectURL, tenantId, userType,
    } = loginQueryParams ?? {};
    const redirectInfo = {
      origin,
      redirectURL,
    };
    const specificMetadata = {
      tenantId,
      userType,
    };
    commit('setExternalLoginInfo', { info: redirectInfo });
    const validMetadata = await dispatch('setExternalLoginMetadata', { origin, metadata: specificMetadata });
    return { validExternalLogin: validMetadata };
  },
  setExternalLoginMetadata({ dispatch }, { origin, metadata }) {
    switch (origin) {
      case 'digitalenrollment':
        // metadata = { tenantId, user_type }
        return dispatch('digitalEnrollment/setup', { metadata }, { root: true });
      default:
        // Reset all specific stores if origin is not recognized
        dispatch('digitalEnrollment/resetStore', {}, { root: true });
        return false;
    }
  },
  async doGoogleIdentification({ commit, dispatch }, { googleAuth }) {
    commit('setLoading', true);
    const guestToken = await dispatch('checkGuestToken');

    const authResponse = googleAuth.credential;
    const googleIdentificationResult = {
      done: true,
      success: false,
      canLink: false,
      newUser: true,
    };
    await services.authenticationService
      .googleAuth({ authResponse, guestToken })
      .then(async (response) => {
        const token = response.data.key;
        await dispatch('doTokenLogin', { token })
          .then(async () => {
            const userPreCheck = {
              origin: 'doGoogleIdentification',
              found: true,
              first_login: false,
              same_password: true,
              is_verified: true,
            };
            commit('setPreCheckStatus', userPreCheck);
            commit('setInformationPages', { informationPages: userPreCheck.first_login });
            await dispatch('getOrCreateLegalGuardian', { userPreCheck }).then(({ found, missingData }) => {
              googleIdentificationResult.newUser = !found || missingData;
              googleIdentificationResult.success = true;
              commit('setLoading', false);
              commit('setGoogleIdentificationResult', { googleIdentificationResult });
            });
          });
      })
      .catch(async () => {
        // TODO: Handle error type. Right now it's 400 for all cases.
        // For when the user already has an account, the code should be 409
        commit('setGoogleAuthData', authResponse);
        googleIdentificationResult.canLink = true;
        commit('setLoading', false);
        return false;
      });
    return googleIdentificationResult;
  },
  getUserMainInfo({ commit }, { uuid }) {
    services.authenticationService.getUserMainInfo({ uuid })
      .then((response) => {
        commit('setUserToken', { token: response.data.key });
        let phone = null;
        let number = null;
        let dialCode = null;
        if (response.data.phone && response.data.username) {
          phone = response.data.phone;
          if (
            CONFIG.tenant === 'chile'
            || CONFIG.tenant === 'colombia'
            || CONFIG.tenant === 'palmira'
          ) {
            dialCode = phone.slice(0, 3);
            number = phone.slice(3, phone.length);
          }
          if (CONFIG.tenant === 'newhaven' || CONFIG.tenant === 'dom') {
            dialCode = phone.slice(0, 2);
            number = phone.slice(2, phone.length);
          }
        }
        commit('setPhoneData', { number, dialCode });
        commit('setEmail', { email: response.data.email });
        commit('setUsernameCheck', { usernameCheck: response.data.username });
        commit('setUsername', { username: response.data.first_name });
        commit('setPreCreatedUser', { preCreatedUser: true });
        return response.data.key;
      })
      .catch(() => {
        router.push({ name: 'Explorer' });
      });
  },
  setForceScholarshipEligibility({ commit }, { force }) {
    commit('setForceScholarshipEligibility', { force });
  },

};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
