import services from '@/services';

const getDefaultState = () => ({
  goToFormContactFrom: null,
});

const state = {
  ...getDefaultState(),
};

const getters = {
  goToFormContactFrom(storeState) {
    return storeState.goToFormContactFrom;
  },
};

const mutations = {
  setGoToFormContactFrom(state, from) {
    state.goToFormContactFrom = from;
  },
};

const actions = {
  // eslint-disable-next-line
  getSchoolMessages({}, { schoolUuid }) {
    return services.messagingService.getMessages({ schoolUuid }).then((response) => response);
  },
  getMessagesCount() {
    return services.messagingService.getMessagesCount().then((response) => response);
  },
  // eslint-disable-next-line
  getGroupedMessages({}, { index, uuid, groupBy }) {
    return services.messagingService
      .getGroupedMessages({ index, uuid, groupBy })
      .then((response) => response)
      .catch(() => ({ status: 404 }));
  },
  setGoToFormContactFrom({ commit }, { from }) {
    commit('setGoToFormContactFrom', { from });
  },
  getMessages({ rootGetters }) {
    const legalGuardianUUID = rootGetters['authentication/legalGuardianUUID'];
    return services.messagingService.getMessages(legalGuardianUUID).then((data) => data);
  },
  async sendMail({ rootGetters }, { form, callback, callbackError }) {
    const legalGuardianUUID = rootGetters['authentication/legalGuardianUUID'];
    const legalGuardianID = rootGetters['authentication/legalGuardianID'];
    const snackbarError = rootGetters['utils/error'];
    const data = {
      ...form,
      parent_id: String(legalGuardianID) || 99,
      parent_uuid: String(legalGuardianUUID) || 99,
    };
    await services.messagingService
      .sendMail(data)
      .then(() => {
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        snackbarError(error);
        if (callbackError) {
          callbackError();
        }
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
