<template>
  <div>
    <PaymentConfirmationModal />
    <PaymentStatusModal />
    <PaymentRedirectionModal />
  </div>
</template>

<script>
import PaymentConfirmationModal from '@/components/organisms/payments/modals/PaymentConfirmation.vue';
import PaymentRedirectionModal from '@/components/organisms/payments/modals/PaymentRedirectionModal.vue';
import PaymentStatusModal from '@/components/organisms/payments/modals/PaymentStatus.vue';

export default {
  name: 'PaymentModals',
  components: {
    PaymentConfirmationModal,
    PaymentStatusModal,
    PaymentRedirectionModal,
  },
};
</script>
