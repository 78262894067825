<template>
  <section class="campus-detail__about">
    <!-- Title -->
    <h1 v-t="'campus-details.about-campus'" class="campus-detail__about__title" />
    <!-- About -->
    <p class="campus-detail__about__text">
      {{ textAbout }}
    </p>
    <div v-if="continuity" class="campus-detail__about__continuity">
      <p v-t="'campus-details.about-continuity'" class="campus-detail__about__sede" />
      <p class="campus-detail__about__sede-name">
        {{ continuity_name }}
      </p>
    </div>
    <!-- Network -->
    <PlacardNetwork
      v-if="network"
      :info="network.network_info"
      :logo="network.network_logo"
    />

    <!-- Generate by ia -->
    <PlacardGeneratedByAi v-if="showPlacardGenerateByAi" class="mt-1 mb-4" />

    <!-- Title 2 -->
    <h1 v-t="'campus-details.additional-info'" class="campus-detail__about__title" />

    <!-- Social Media -->
    <CampusDetailSocialMedia v-if="showFullInfo" :contacts="contacts" class="mt-6" />

    <div class="campus-detail__about__divider">
      <hr />
      <button
        type="button"
        class="ml-3"
        :class="{ 'campus-detail__about__rotate': showFullInfo }"
        @click="showFullInfo = !showFullInfo"
      >
        <img src="@/assets/icons/arrow-down-circle.svg" width="25px" alt="" />
      </button>
    </div>

    <!-- Agreements -->
    <div v-if="getAgreements.length > 0" class="d-flex flex-row w-full justify-between mt-3">
      <span v-t="'campus-details.covenants'" class="campus-detail__about__convenants" />

      <div class="d-flex w-full align-end" :class="isMobile ? 'flex-row gap-1 justify-end' : 'flex-column'">
        <span
          v-for="({ name, abbreviaton, background }, index) in getAgreements"
          :key="index"
          class="campus-detail__about__convenants--item"
          :class="background"
        >
          {{ isMobile ? abbreviaton : name }}
        </span>
      </div>
    </div>

    <hr v-if="showDivisor" class="campus-detail__about--divisor mb-2" />
    <!-- Training -->
    <div v-if="listTrainingInSchool.length > 0" class="d-flex flex-row w-full justify-between mt-3">
      <span v-t="'campus-details.about-title-formation'" class="campus-detail__about__convenants" />

      <div class="d-flex w-full align-end flex-column">
        <span
          v-for="({ name, background }, index) in listTrainingInSchool"
          :key="index"
          class="campus-detail__about__convenants--item"
          :class="`campus-detail__about--${background}`"
        >
          {{ name }}
        </span>
      </div>
    </div>
    <MainActivities
      v-if="partnerships.length > 0"
      class="mt-4"
      :partnerships="partnerships"
    />
  </section>
</template>

<script>
import CampusDetailSocialMedia from '@/components/explorer/general/campus_detail/CampusDetailSocialMedia.vue';
import PlacardGeneratedByAi from '@/components/molecules/placard/PlacardGeneratedByAi.vue';
import PlacardNetwork from '@/components/molecules/placard/PlacardNetwork.vue';
import MainActivities from '@/components/explorer/general/campus_detail/panels/MainActivities.vue';
import CONFIG from '@/config';
import { mapGetters } from 'vuex';

export default {
  name: 'CampusDetailAbout',
  components: {
    CampusDetailSocialMedia, PlacardGeneratedByAi, PlacardNetwork, MainActivities,
  },
  props: {
    about: {
      type: String,
      default: '',
    },
    agreements: {
      type: Array,
      default: () => [],
    },
    trainings: {
      type: Array,
      default: () => [],
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    showPlacardGenerateByAi: {
      type: Boolean,
      default: false,
    },
    partnerships: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showFullInfo: true,
      listTrainingInSchool: [],
      continuity: false,
      continuity_name: '',
      network: null,
    };
  },
  computed: {
    ...mapGetters({
      agreementsLabels: 'options/agreementsLabels',
      campusDetail: 'institutions/campusDetails',
    }),
    showDivisor() {
      return (
        this.listTrainingInSchool.length > 0 && this.getAgreements.length > 0
      );
    },
    textAbout() {
      let text = this.about;
      if (!this.showFullInfo) {
        return `${text.substring(0, 300)}...`;
      }
      if (text?.length === 0) {
        text = this.$t('campus-details.no-about-info');
      }
      return text;
    },
    getAgreements() {
      let formatAgreements = [];
      // eslint-disable-next-line camelcase
      this.agreements.forEach(({ agreement_label, descrip }) => {
        // eslint-disable-next-line camelcase
        const NEW_AGREEMENT = {
          ...this.setAgreement(agreement_label, descrip),
        };
        formatAgreements.push(NEW_AGREEMENT);
      });

      formatAgreements = formatAgreements.filter(
        (agremement) => agremement.abbreviaton !== '',
      );
      return formatAgreements;
    },
  },
  metaInfo() {
    return {
      meta: [
        // fix me: this should return contact info
      ],
    };
  },
  mounted() {
    if (this.trainings.length > 0) {
      this.setListTraining();
    }
    if (
      this.campusDetail.institutiontext_campus[0]?.campus_continuity?.length > 0
    ) {
      this.continuity = true;
      this.continuity_name = this.campusDetail.institutiontext_campus[0]?.campus_continuity;
    }
    if (
      this.campusDetail.campus_network
    ) {
      if (this.campusDetail.campus_network.network_info && this.campusDetail.campus_network.network_logo) {
        this.network = this.campusDetail.campus_network;
      }
    }
  },

  methods: {
    setAgreement(agreement, description) {
      const newAgreement = {
        background: '',
        abbreviaton: '',
        name:
          agreement.id === 99
            ? description
            : agreement.agreement_name,
      };

      switch (agreement.id) {
        case 1:
          newAgreement.background = 'campus-detail__about--tag';
          newAgreement.abbreviaton = CONFIG.tenant !== 'chile' ? 'PAE' : 'SEP';
          break;
        case 2:
          newAgreement.background = 'campus-detail__about--tag';
          newAgreement.abbreviaton = CONFIG.tenant !== 'chile' ? 'PER' : 'PIE';
          break;
        case 3:
          newAgreement.background = 'campus-detail__about--maa';
          newAgreement.abbreviaton = 'MAA';
          break;
        case 4:
          newAgreement.background = 'campus-detail__about--bus';
          newAgreement.abbreviaton = 'BUS';
          break;
        case 99:
          newAgreement.background = 'campus-detail__about--otro';
          newAgreement.abbreviaton = description;
          break;
        default:
          break;
      }

      return newAgreement;
    },
    setListTraining() {
      const formattedListTraining = [];
      let removeDuplicateTraining;
      const listTraining = this.trainings.map(
        (training) => training.gradetrack?.specialty_label?.id,
      );
      // formatted data list training
      listTraining.forEach((trainingId) => {
        if (trainingId >= 41 && trainingId <= 43) {
          formattedListTraining.push(41);
        } else if (trainingId >= 47 && trainingId <= 72) {
          formattedListTraining.push(47);
        } else if (trainingId !== 1) {
          formattedListTraining.push(trainingId);
        }
      });

      // remove duplicate list training
      removeDuplicateTraining = new Set(formattedListTraining);
      removeDuplicateTraining = [...removeDuplicateTraining];
      removeDuplicateTraining.forEach((trainingId) => {
        this.listTrainingInSchool.push(this.setTraining(trainingId));
      });
    },
    setTraining(id) {
      const DATA_TRAINING = this.$t('campus-details.about-list-training');

      return DATA_TRAINING[id] ?? {};
    },
  },
};
</script>
