var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.source)?_c('div',{staticClass:"url-image",style:(_vm.small ? 'width: auto' : '')},[_c('img',{class:{
      'url-image__image--unset-width': _vm.width,
      'url-image__image--rounded': _vm.rounded,
      'url-image__image--rounded-wide': _vm.roundedWide,
      'url-image__image--medium': _vm.medium,
      'url-image__image--small': _vm.small,
      'url-image__image--with-background': _vm.withBackground,
      'url-image__image--large': _vm.large,
    },attrs:{"width":_vm.small ? 'auto' : _vm.width,"src":_vm.source,"alt":_vm.altText},on:{"click":function($event){return _vm.$emit('click')},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('click')}}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }