<template>
  <section class="w-full d-flex flex-column justify-start pb-10">
    <SvgIcon
      icon="ring_blue.svg"
      size="30"
      alt="ring icon"
      class="mb-4 mx-auto"
    />

    <TextAtom
      v-for="(description, index) in $t('feedback.end.descriptions')"
      :key="`${index}-description`"
      :value="description"
      tag="h2"
      font="inter"
      color="neutral-700"
      weight="400"
      size="normal"
      class="mt-3 text-left"
    />

    <div
      class="bg-white rounded-lg py-3 px-4 my-3"
    >
      <TextAtom
        v-for="(recommendation, index) in $t('feedback.end.recommendation')"
        :key="`${index}-recommendation`"
        :value="recommendation"
        tag="h2"
        font="inter"
        color="neutral-700"
        weight="400"
        size="normal"
        class="mt-3 text-left"
      />

      <MainButton
        text="feedback.end.modify"
        class="mt-5"
        :active-value="true"
        @continue="openSchoolMint"
      />
    </div>

    <TextAtom
      v-for="(contact, index) in $t('feedback.end.contact')"
      :key="`${index}-contact`"
      :value="contact"
      tag="h2"
      font="inter"
      color="neutral-700"
      weight="400"
      size="normal"
      class="mt-3 text-left"
    />
  </section>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import MainButton from '@/components/atoms/buttons/MainButton.vue';

export default {
  name: 'FeedbackEnd',
  components: {
    TextAtom, SvgIcon, MainButton,
  },
  methods: {
    openSchoolMint() {
      window.open('https://nhps.schoolmint.com/', '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
