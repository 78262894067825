import { backendAPI } from '@/api';

const recommendations = '/reports/recommendations_v2/';
const application = '/reports/risk/simulate';
const results = '/reports/results/postulations';
const intervention = 'reports/intervention';
const simulateFamily = '/reports/risk/simulate_family';

export default {
  recommendations() {
    return backendAPI.get(`${recommendations}`, {
      params: {
        // applicant_uuid: uuid,
        year: 2023,
        limit: 10,
      },
    });
  },

  application(uuid) {
    return backendAPI.get(`${application}/`, {
      params: {
        applicant_uuid: uuid,
        year: 2023,
      },
    });
  },

  simulateFamily() {
    return backendAPI.get(`${simulateFamily}/`, {
      params: {
        year: '2023',
      },
    });
  },

  results() {
    return backendAPI.get(`${results}/`, {
      params: {
        year: '2022',
        admission_system_label_id: 1,
      },
    });
  },

  getIntervention() {
    return backendAPI.get(`${intervention}/`);
  },
};
