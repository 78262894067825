import CONFIG from '@/config';
import services from '@/services';

const getDefaultState = () => ({
  widgetsVacanciesByStageGraphURL: null,
  widgetsVacanciesGraphURL: null,
  widgetsVacanciesTableURL: null,
  widgetsVacanciesGraphEndpoint: 'seats_vacancies_graph',
  widgetsVacanciesTableEndpoint: 'seats_vacancies_table',
  widgetsVacanciesByStageGraphEndpoint: 'vacancies_by_stage_graph',
  stages: [],
  reportsWidgetsData: {},
});

const state = {
  ...getDefaultState(),
};

const getters = {
  widgetsVacanciesByStageGraphURL: ({ widgetsVacanciesByStageGraphURL }) => widgetsVacanciesByStageGraphURL,
  widgetsVacanciesGraphURL: ({ widgetsVacanciesGraphURL }) => widgetsVacanciesGraphURL,
  widgetsVacanciesTableURL: ({ widgetsVacanciesTableURL }) => widgetsVacanciesTableURL,
  stages: ({ stages }) => stages,
  reportsWidgetsData: ({ reportsWidgetsData }) => reportsWidgetsData,
};

const mutations = {
  setWidgetsVacanciesGraphURL(state, { url }) {
    state.widgetsVacanciesGraphURL = url;
  },
  setWidgetsVacanciesByStageGraphURL(state, { url }) {
    state.widgetsVacanciesByStageGraphURL = url;
  },
  setWidgetsVacanciesTableURL(state, { url }) {
    state.widgetsVacanciesTableURL = url;
  },
  setStages(state, { stages }) {
    state.stages = stages;
  },
  setReportsWidgetsData(state, { data, reportCode }) {
    state.reportsWidgetsData = { ...state.reportsWidgetsData, [reportCode]: data };
  },
};

const actions = {
  async getStages({ commit }, { campusCode }) {
    await services.widgetsService.getStages({ campusCode })
      .then((response) => {
        const { stages } = response.data;
        commit('setStages', { stages });
      })
      .catch(() => commit('setStages', { stages: [] }));
  },

  async getWidgetsVacanciesGraph({ commit }, { campusCode }) {
    const { tenant } = CONFIG;
    const url = `${CONFIG.widgetsApiURL}${state.widgetsVacanciesGraphEndpoint}?campus_code=${campusCode}&tenant=${tenant}`;
    await services.widgetsService
      .getVacanciesGraph({ campusCode, tenant })
      .then(() => commit('setWidgetsVacanciesGraphURL', { url }))
      .catch(() => commit('setWidgetsVacanciesGraphURL', { url: null }));
  },
  async getWidgetsVacanciesByStageGraph({ commit }, { campusCode, size, title }) {
    const { tenant } = CONFIG;
    const url = `${CONFIG.widgetsApiURL}${state.widgetsVacanciesByStageGraphEndpoint}?campus_code=${campusCode}&tenant=${tenant}&size=${size}&title=${title}`;
    await services.widgetsService
      .getVacanciesByStageGraph({
        campusCode, tenant, size, title,
      })
      .then(() => commit('setWidgetsVacanciesByStageGraphURL', { url }))
      .catch(() => commit('setWidgetsVacanciesByStageGraphURL', { url: null }));
  },
  async getWidgetsVacanciesTable({ commit }, { campusCode }) {
    const { tenant } = CONFIG;
    const url = `${CONFIG.widgetsApiURL}${state.widgetsVacanciesTableEndpoint}?campus_code=${campusCode}&tenant=${tenant}`;
    await services.widgetsService
      .getVacanciesTable({ campusCode, tenant })
      .then(() => commit('setWidgetsVacanciesTableURL', { url }))
      .catch(() => commit('setWidgetsVacanciesTableURL', { url: null }));
  },
  async getReports({ commit }, { campusCode, reportCode }) {
    await services.widgetsService.getReports({ campusCode, reportCode })
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          commit('setReportsWidgetsData', { data, reportCode });
        } else {
          commit('setReportsWidgetsData', { data: null, reportCode });
        }
      })
      .catch(() => commit('setReportsWidgetsData', { data: null, reportCode }));
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
