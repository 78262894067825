import analyticsService from './analytics.service';
import authenticationService from './authentication.service';
import digitalEnrollmentService from './digitalEnrollment.service';
import elasticSearchService from './elasticSearch.services';
import favorites from './favorites.service';
import feedbackService from './feedback.service';
import geoToolsService from './geoTools.service';
import institutionsService from './institutions.services';
import messagingService from './messaging.service';
import optionsService from './options.services';
import paymentsService from './payments.service';
import prechecksService from './prechecks.service';
import publicRecordsService from './publicRecords.service';
import simulationService from './simulation.services';
import studentRegistrationService from './studentRegistration.services';
import userRegistrationService from './userRegistration.services';
import widgetsService from './widgets.service';
import explorerLabelService from './explorerLabel.service';
import facebookAccountsService from './facebookAccounts.service';
import reportsService from './reports.service';
import * as pluginsService from './plugins.service';

const services = {
  analyticsService,
  authenticationService,
  digitalEnrollmentService,
  elasticSearchService,
  favorites,
  feedbackService,
  geoToolsService,
  institutionsService,
  messagingService,
  optionsService,
  explorerLabelService,
  paymentsService,
  prechecksService,
  publicRecordsService,
  simulationService,
  studentRegistrationService,
  userRegistrationService,
  widgetsService,
  facebookAccountsService,
  pluginsService,
  reportsService,
};

export default services;
