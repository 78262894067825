<template>
  <section class="campus-detail__header px-7 pt-8">
    <!-- Name of the school -->
    <TitleVerifiedAccount
      itemprop="name"
      :title="nameSchool"
      :verified="isCampusVerified"
      :prime="isCampusTetherPack"
      :freemium="isCampusFreemium"
    />
    <!-- Information School -->
    <div class="campus-detail__header__information">
      <span v-if="commune"> {{ commune }} | </span>
      <span>
        {{ gradesSchool }}
      </span>
    </div>

    <!-- Information admission -->
    <div class="w-full d-flex flex-row justify-space-between mb-5 mt-2">
      <div
        class="campus-detail__header__admission clickable"
        :class="{ 'flex-column': isMobile }"
        @click="scrollTo()"
        @keydown="scrollTo()"
      >
        <VacancyTile
          v-if="false"
          :text="vacanciesInformation.text"
          :background-color="vacanciesInformation.backgroundColor"
          :color="vacanciesInformation.color"
          :loading="vacanciesLoader"
        />
        <!-- // ! TODO in future -->
        <button v-show="false" type="button" :class="isMobile ? 'ml-0 mt-1' : 'ml-3'">
          <img class="mr-2" width="15" src="@/assets/icons/redo.svg" alt="redo" />
          <span v-t="'campus-details.admission-date'" />
        </button>
      </div>

      <!-- Total views -->
      <div
        class="d-flex flex-row justify-center align-center"
        itemprop="interactionStatistic"
      >
        <img
          width="25px"
          src="@/assets/icons/visibility.svg"
          :alt="`${nameSchool} tiene ${views} visitas`"
        />
        <span class="ml-2 campus-detail__header__views">
          {{ views }}
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import TitleVerifiedAccount from '@/components/molecules/verified_account/TitleVerifiedAccount.vue';
import VacancyTile from '@/components/molecules/tiles/VacancyTile.vue';

export default {
  name: 'CampusDetailHeader',
  components: {
    TitleVerifiedAccount,
    VacancyTile,
  },
  props: {
    commune: {
      type: String,
      default: '',
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    nameSchool: {
      type: String,
      default: '',
    },
    gradesSchool: {
      type: String,
      default: '',
    },
    views: {
      type: Number,
      default: 0,
    },
    isCampusVerified: {
      type: Boolean,
      default: false,
    },
    isCampusTetherPack: {
      type: Boolean,
      default: false,
    },
    isCampusFreemium: {
      type: Boolean,
      default: false,
    },
    campus: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      schoolsVacancies: 'institutions/schoolsVacancies',
      navbarVacanciesFilter: 'filters/navbarVacanciesFilter',
      vacanciesLoader: 'institutions/vacanciesLoader',
    }),
    vacanciesInformation() {
      if (!this.navbarVacanciesFilter?.size) return {};
      const year = Array.from(this.navbarVacanciesFilter);
      const yearVacancies = this.schoolsVacancies[year];
      let vacancy;
      if (yearVacancies && yearVacancies[this.campus.campus_code] !== 0) {
        vacancy = yearVacancies[this.campus.campus_code] > 0 ? 1 : 2;
      } else {
        vacancy = yearVacancies?.[this.campus.campus_code];
      }

      const label = {
        0: {
          text: this.$t('map.card.vacancies.closed'),
          backgroundColor: 'secondary-focus',
          color: 'primary-dark',
        },
        1: {
          text: this.$t('map.card.vacancies.open'),
          backgroundColor: 'primary-medium',
          color: 'white',
        },
        2: {
          text: this.$t('map.card.vacancies.no-info'),
          backgroundColor: 'gray-line',
          color: 'primary-dark',
        },
      };

      if (vacancy === -1 || vacancy === undefined) {
        vacancy = 2;
      }
      return {
        text: label[vacancy].text + year[0],
        backgroundColor: label[vacancy].backgroundColor,
        color: label[vacancy].color,
      };
    },
  },
  methods: {
    scrollTo() {
      document.getElementById('vacancy').scrollIntoView({ behavior: 'smooth' });
    },
  },

};

</script>
