<template>
  <section class="w-full d-flex flex-column bg-neutral space-y-8">
    <TextAtom
      :value="$t('feedback.message.title', { name: student })"
      tag="h2"
      font="poppins"
      color="primary-700"
      weight="400"
      size="subtitle"
      class="text-left"
    />

    <TextAtom
      :value="$t('feedback.message.description', { name: student })"
      tag="p"
      font="poppins"
      color="neutral-800"
      weight="400"
      size="normal"
      class="text-left"
    />
    <TextAtom
      :value="$t('feedback.message.chance', { name: student })"
      tag="p"
      font="poppins"
      color="neutral-800"
      weight="400"
      size="normal"
      class="text-left"
    />
  </section>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'FeedbackMessage',
  components: {
    TextAtom,
  },
  props: {
    student: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
</style>
