import { parseQueryParam } from '@/utils/routes';
import { TETHER_FILTER_KEYS as FILTER_KEYS, TETHER_FILTER_CONTEXTS } from '@/constants/filters';
import { TENANT_CONFIGURATION } from '@/constants/tenant';

/**
 * Set default guest data in store
 * @param {*} param0 - guest data to set
 * @param {*} param0.store - store -> REQUIRED
 * @param {*} param0.next - next route -> OPTIONAL - will be called after setting data if provided
 * @param {*} param0.coordinates - coordinates to set -> OPTIONAL
 * @param {*} param0.municipalityName - municipalityName to set -> OPTIONAL
 * @param {*} param0.grades - grades to set -> OPTIONAL
 * @returns {void}
 *
 * If there is no municipalityName, coordinates or grades, the default values will be used
 */
export function setDefaultGuestData({
  store,
  next = undefined,
  coordinates = TENANT_CONFIGURATION.DEFAULTS.LOCATION.coordinates,
  municipalityName = TENANT_CONFIGURATION.DEFAULTS.LOCATION.municipalityName,
  grades = TENANT_CONFIGURATION.DEFAULTS.GRADES,
}) {
  store.dispatch('authentication/setGuestData', {
    location: {
      coordinates,
      municipalityName,
    },
    grades,
  }).then(() => {
    store.dispatch('authentication/setExitsInfoGuest', { exitsInfoGuest: true })
      .then(() => {
        if (next && typeof next === 'function') next();
      });
  });
}

/**
 * TODO: Refactor this function, because it's unnecessarily complex. With an endpoint that returns the coordinates and '
 * municipality name based on the location name, this function could be simplified.
 * @param {*} store
 * @param {*} locationName
 * @returns
 */
async function getLocationFromText(store, locationName) {
  const {
    coordinates: defaultCoordinates,
    municipalityName: defaultMunicipalityName,
  } = TENANT_CONFIGURATION.DEFAULTS.LOCATION;
  store.dispatch('institutions/setLocationFromUrl', true);
  const formattedLocationName = locationName.toLowerCase().replaceAll('-', ' ').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return store.dispatch('options/getMunicipalities')
    .then(() => {
      const municipalities = store.getters['options/municipalities'];
      const municipality = municipalities.find(
        (municipalityInfo) => municipalityInfo.name.toLowerCase().replaceAll('-', ' ').normalize('NFD').replace(/[\u0300-\u036f]/g, '')
              === formattedLocationName,
      );

      const coordinates = {
        lat: municipality?.default_coordinate?.lat || defaultCoordinates.lat,
        lng: municipality?.default_coordinate?.lon || defaultCoordinates.lng,
      };
      const municipalityName = municipality?.name || defaultMunicipalityName;
      return { coordinates, municipalityName };
    });
}

/**
 * Takes the route query object and parses system flags, filters and explorer properties to be set.
 * If store is provided, it will set the flags, filters and explorer properties in the store.
 * If next is provided, it will call next after setting the properties in the store (if provided), otherwise after
 * parsing the properties and before returning them.
 * @param {*} query - route query object
 * @param {*} store - store -> OPTIONAL
 * @param {*} next - next route -> OPTIONAL
 * @param {TETHER_FILTER_CONTEXTS} context - context for filters -> OPTIONAL
 * @returns {Object} - properties to set
 * @returns {Object.coordinates} - coordinates to set (lat, lng, z)
 * @returns {Object.flags} - flags to set (hideVacancies, hideCategories, forceScholarships)
 * @returns {Object.filters} - filters to set (performance, payment, grade, distanceCategory, distance...)
 */
export async function handleRouteQuery(
  query,
  options = {
    context: TETHER_FILTER_CONTEXTS.EXPLORER,
    clearFilters: false,
  },
) {
  const {
    LOCATION: { coordinates: defaultCoordinates },
    FILTERS: FILTER_PRESETS,
  } = TENANT_CONFIGURATION.DEFAULTS;
  const {
    lat = defaultCoordinates.lat,
    lng = defaultCoordinates.lng,
    z,
    useFilterPresets: useFiltersPresetsRaw,
    hideVacancies,
    hideCategories,
    forceScholarships,
    shrinkUnfiltered,
    hideChat,
    circleRadius, // Forces the circle radius (in km)
  } = query;

  const parseFlag = (value) => (value !== undefined ? `${value}`.toLowerCase() === 'true' : undefined);

  const parseFloat = (value) => Number.parseFloat(value);

  const useFilterPresets = parseFlag(useFiltersPresetsRaw);

  const buildDefaultFilters = (preset) => (useFilterPresets ? preset : undefined);

  const {
    performance = buildDefaultFilters(FILTER_PRESETS.PERFORMANCE),
    payment = buildDefaultFilters(FILTER_PRESETS.PAYMENTS),
    grade = buildDefaultFilters(FILTER_PRESETS.GRADES),
    distanceCategory = buildDefaultFilters(FILTER_PRESETS.DISTANCE),
    distance = buildDefaultFilters(FILTER_PRESETS.DISTANCE_RAW),
    institution, // Institution codes
    hasVacancies, // Years with vacancies
    network, // Network ID
    payment_agreement: paymentAgreement, // Payment agreement
    process_id: processId,
  } = query;

  const flags = {
    hideVacancies: parseFlag(hideVacancies),
    hideCategories: parseFlag(hideCategories),
    forceScholarships: parseFlag(forceScholarships),
    shrinkUnfiltered: parseFlag(shrinkUnfiltered),
  };

  const grades = grade ? Array.from(parseQueryParam(grade, 'number', true)) : undefined;

  const search = {
    grades: grades || [],
  };

  const rawFilters = [
    [FILTER_KEYS.PAYMENT, payment, 'number', true], // Payment categories
    [FILTER_KEYS.PERFORMANCE, performance, 'number', true], // Performance categories
    [FILTER_KEYS.DISTANCE, distanceCategory, 'number', true], // Distance categories
    [FILTER_KEYS.INSTITUTION, institution, 'string', true], // Institution codes
    [FILTER_KEYS.HAS_VACANCIES, hasVacancies, 'number', true], // Years with vacancies
    [FILTER_KEYS.DISTANCE_RAW, distance, 'float', false], // Distance in km
    [FILTER_KEYS.NETWORK_ID, network, 'number', true], // Network ID
    [FILTER_KEYS.PAYMENT_AGREEMENT, paymentAgreement, 'number', true], // Payment agreement
  ];

  const filters = rawFilters.reduce((acc, [key, value, valueType, multi]) => {
    if (!value) return acc;
    acc[key] = parseQueryParam(value, valueType, multi);
    return acc;
  }, {});

  const {
    store, next, context = TETHER_FILTER_CONTEXTS.EXPLORER, clearFilters = false,
  } = options;

  const explorer = {
    coordinates: {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    },
    z: parseFloat(z),
    circleRadius: parseFloat(circleRadius),
    municipalityName: undefined,
  };

  if (store) {
    if (query.locationName) {
      const { locationName } = query;
      const { coordinates, municipalityName } = await getLocationFromText(store, locationName);
      // Lat and Lng take precedence over the location name
      explorer.coordinates = coordinates;
      explorer.municipalityName = municipalityName;
    }

    store.dispatch('explorer/setFlags', flags);
    store.dispatch('explorer/setMapZoom', { zoom: explorer.z });

    store.dispatch('filters/setContext', { context }).then(() => {
      if (Object.keys(filters).length > 0) {
        store.dispatch('filters/commitFilters', { filters, updateSchools: true });
      } else if (clearFilters) {
        store.dispatch('filters/resetContext');
      }
    });

    if (!Number.isNaN(explorer.circleRadius) && explorer.circleRadius > 0) {
      store.dispatch('explorer/configureDistanceCircle', { radius: explorer.circleRadius * 1000 });
    }

    if (processId) {
      store.dispatch('explorer/setProcessId', { processId });
    }

    store.dispatch('authentication/setForceScholarshipEligibility', { force: flags.forceScholarships });

    setDefaultGuestData({
      store, grades, next, ...explorer,
    });

    if (hideChat) {
      if (window.Intercom) window.Intercom('update', { hide_default_launcher: true });
    }
  } else if (next) {
    next();
  }

  return {
    explorer,
    flags,
    filters,
    search,
  };
}
