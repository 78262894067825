<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper v-if="!isInMap" :step="2" />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', , 'instructions', 'infoBox', 'link']"
        :small-title-text="'user_register.location_student.title'"
        small-title-left
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :subtitle-text="'user_register.location_student.subtitle'"
        :info-box-icon="'location-pin.svg'"
        :info-box-text="setLegalGuardianAddress()"
        :link-text="'user_register.location_student.text_link'"
        :instruction-text="'user_register.location_student.instructions'"
        @toLink="$emit('toLocationFlow')"
      />
    </div>
    <CallToActions
      :types="['main']"
      active-value
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LocationStudent',
  components: {
    ModalHeader,
    RowStepper,
    ModalContent,
    CallToActions,
  },
  props: {
    isInMap: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      municipalityLabels: 'options/municipalityLabels',
      students: 'authentication/students',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      currentStudent: 'authentication/currentStudent',
      roadTypes: 'options/roadTypes',
      fullAddressDetails: 'userRegister/fullAddressDetails',
    }),
  },
  methods: {
    ...mapActions({
      setStudentLocation: 'newStudentRegister/setStudentLocation',
    }),
    nextStep() {
      const location = {
        uuid: this.currentStudent.uuid,
        address_details: this.legalGuardianAddress[0].address_details,
        address_name: this.legalGuardianAddress[0].address_details.address_name.id ?? 1,
        address_street: this.legalGuardianAddress[0].address_details,
        address_number: this.legalGuardianAddress[0].address_details,
        address_complement: this.legalGuardianAddress[0].address_details,
        address_lat: this.legalGuardianAddress[0].address_lat,
        address_lon: this.legalGuardianAddress[0].address_lon,
        location: this.legalGuardianAddress[0].location,
      };
      this.setStudentLocation({ location, updateStudent: true });
      this.$emit('nextStep', this.legalGuardianAddress);
      return null;
    },
    setLegalGuardianAddress() {
      if (this.legalGuardianAddress && this.legalGuardianAddress.length > 0) {
        if (this.legalGuardianAddress[0].address_details === 'Selecciona tu ubicación') {
          return this.legalGuardianAddress[0].location;
        }
        return this.legalGuardianAddress[0].address_details;
      }
      return '';
    },
  },
};
</script>
