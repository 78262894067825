const ELASTIC_SEARCH_SIZE = 500;

const SEARCH_ENGINE_FUNCTIONS = Object.freeze({
  GEO_DISTANCE: {
    path: 'geo',
    method: 'post',
  },
  RETRIEVE: {
    path: '',
    method: 'get',
  },
  BOUNDING_BOX: {
    path: 'poly_filter',
    method: 'post',
  },
  TERM: {
    path: 'term',
    method: 'post',
  },
  MATCH_ALL: {
    path: 'all',
    method: 'post',
  },
  COUNT: {
    path: 'counter',
    method: 'post',
  },
});

const ELASTIC_SEARCH_FIELDS = Object.freeze({
  ADMISSION_SYSTEM: 'campus_admission_system.admission_system.id',
  CAMPUS_CODE: 'campus_code',
  COMMUNE: 'commune',
  GENDER: 'programs.gender.id',
  GRADE: 'programs.grade.id',
  INSTITUTION_CODE: 'institution_code',
  PAYMENT_CATEGORY: 'general_costs.monthly.payment_category.id',
  PERFORMANCE: 'performance_set.qualitycategory_level_label.id',
  SECTOR: 'sector.id',
  AGREEMENT: 'agreement_set.id',
  NETWORK_ID: 'network_id',
  PROCESS_ID: 'programs.process_ids',
  YEAR: 'programs.year',
});

const GEO_SEARCH_MODES = Object.freeze({
  RADIAL: 'radial',
  BOUNDS: 'bounds',
});

export {
  ELASTIC_SEARCH_SIZE,
  ELASTIC_SEARCH_FIELDS,
  SEARCH_ENGINE_FUNCTIONS,
  GEO_SEARCH_MODES,
};
