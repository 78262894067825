<template>
  <section class="w-full d-flex flex-column bg-primary-dark py-12">
    <TextAtom
      :value="$t(`feedback.probability_alert.title.${riskLabel.TETHER_CATEGORY.toLowerCase()}`)"
      tag="h2"
      font="poppins"
      color="secondary-light"
      weight="400"
      size="subtitle"
      class="text-left"
    />

    <TextAtom
      v-for="(description, index) in $t('feedback.probability_alert.descriptions')"
      :key="index"
      :value="description"
      tag="h2"
      font="inter"
      color="secondary-light"
      weight="400"
      size="normal"
      class="mt-3 text-left"
    />

    <div
      class="my-4 w-full d-flex flex-row justify-center"
    >
      <img
        :src="require(`@/assets/feedback/probability-alert-${riskLabel.TETHER_CATEGORY.toLowerCase()}.svg`)"
        alt="probability alert"
      />

      <div
        class="rounded-lg py-3 px-4 feedback-message-warning ml-5"
        :style="{
          'background-color':
            riskLabel.TETHER_CATEGORY === TETHER_CATEGORY.HIGH
              ? '#F3276B'
              : (
                riskLabel.TETHER_CATEGORY === TETHER_CATEGORY.MEDIUM
                  ? '#5627FF'
                  : '#AD8FFF'
              ),
        }"
      >
        <TextAtom
          :value="$t('feedback.probability_alert.chance')"
          tag="span"
          font="inter"
          color="secondary-light"
          weight="500"
          size="default"
          class="text-left"
          style="height: fit-content"
        />
      </div>
    </div>

    <!-- <TextAtom
      :value="$t('feedback.probability_alert.message')"
      tag="h2"
      font="poppins"
      color="secondary-light"
      weight="400"
      size="subtitle"
      class="text-left mt-10"
    /> -->
  </section>
</template>

<script>
import { findAdmissionProbabilityLabel } from '@/utils/categories/admissionRisk';
import { TETHER_CATEGORY } from '@/constants/category';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'FeedbackProbabilityAlert',
  components: {
    TextAtom,
  },
  props: {
    unmatched: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      TETHER_CATEGORY,
    };
  },
  computed: {
    riskLabel() {
      return findAdmissionProbabilityLabel({ risk: this.unmatched });
    },
  },
};
</script>

<style scoped>
</style>
