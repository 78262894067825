import {
  widgetsApi, widgetsProfileApi, backendAPI,
} from '@/api';

export default {
  getVacanciesGraph({ campusCode, tenant }) {
    const params = { campus_code: campusCode, tenant };
    return widgetsApi.get('seats_vacancies_graph', { params });
  },
  getVacanciesByStageGraph({
    campusCode, tenant, size = 200, title = 'Ocupación',
  }) {
    const params = {
      campus_code: campusCode, tenant, size, title,
    };
    return widgetsApi.get('vacancies_by_stage_graph', { params });
  },
  getVacanciesTable({ campusCode, tenant }) {
    const params = { campus_code: campusCode, tenant };
    return widgetsApi.get('seats_vacancies_table', { params });
  },
  getStages({ campusCode }) {
    return widgetsProfileApi.post('SchoolProfile_ExAlumnTransitions_PreFetch', { campus_code: campusCode });
  },
  getReports({ campusCode, reportCode }) {
    return backendAPI.get(`institutions/widgets/by_code/${campusCode}/${reportCode}`);
  },
};
