<template>
  <section
    class="d-flex align-center flex-column contact"
    :style="inExplorer ? 'background: white;' : ''"
  >
    <picture v-if="inExplorer" class="d-flex flex-row mt-5 w-100">
      <img width="30" src="@/assets/icons/messageBlue.svg" alt="mensaje" />
      <p
        v-t="'contact_panel.contact.plataform'"
        class="contact_panel__container__header--white--title"
      />
    </picture>

    <!-- Form contact -->
    <v-form
      ref="form"
      v-model="validFormContact"
      lazy-validation
      class="contact__form"
      @submit.prevent="sendContact"
    >
      <template v-if="!successSendMail">
        <!-- Step 1 -->
        <div class="w-full d-flex flex-row align-center mt-6">
          <span class="contact__step contact__step__one"> 1 </span>
          <span v-t="'contact.step_one'" class="contact__title ml-2 contact__asterisk" />
        </div>

        <!-- name -->
        <label
          v-t="'contact.name.title'"
          class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk"
          for="name"
        />
        <v-text-field
          id="name"
          v-model="form.username"
          dense
          outlined
          :rules="errorForm.username"
          :placeholder="$t('contact.name.placeholder')"
          class="mt-2"
          color="#2B5BFF"
          :dark="form.username !== ''"
          :background-color="form.username ? '#2b5bff' : '#FFFFFF'"
          type="text"
        />

        <!-- email -->
        <label
          v-t="'contact.email.title'"
          class="contact__title font-weight-light text-left d-block mt-2 contact__asterisk"
          for="email"
        />
        <v-text-field
          id="email"
          v-model="form.mail_from"
          dense
          outlined
          :rules="errorForm.mail_from"
          :placeholder="$t('contact.email.placeholder')"
          class="mt-2"
          color="#2B5BFF"
          :dark="form.mail_from !== ''"
          :background-color="form.mail_from ? '#2b5bff' : '#FFFFFF'"
          type="email"
        />

        <!-- phone -->
        <label
          v-t="'contact.phone.title'"
          class="contact__title font-weight-light text-left d-block mt-2"
          for="phone"
        />
        <div class="w-full d-flex flex-row">
          <v-autocomplete
            v-model="codeArea"
            background-color="#2b5bff"
            dark
            dense
            outlined
            :items="listCodeArea"
            item-text="dial_code"
            item-value="dial_code"
            class="mt-2"
            :class="isMobile ? 'w-6/12' : 'w-4/12'"
          >
            <template slot="item" slot-scope="{ item }">
              {{ item.dial_code }} {{ item.name_es }}
            </template>
          </v-autocomplete>
          <!-- <v-text-field
            value="+57"
            dense
            outlined
            class="mt-2"
            :class="isMobile ? 'w-4/12' : 'w-1/12'"
            color="#2B5BFF"
            :dark="form.phone !== ''"
            :background-color="form.phone ? '#2b5bff' : '#FFFFFF'"
            readonly
          /> -->
          <v-text-field
            id="phone"
            v-model="form.phone"
            dense
            outlined
            :placeholder="$t('contact.phone.placeholder')"
            class="mt-2 w-11/12 ml-3"
            :class="isMobile ? 'w-6/12' : 'w-8/12'"
            color="#2B5BFF"
            :dark="form.phone !== ''"
            :background-color="form.phone ? '#2b5bff' : '#FFFFFF'"
            type="number"
          />
        </div>

        <!-- Step 2 -->
        <template v-if="showStepTwo">
          <div class="w-full d-flex flex-row align-center mt-3 animation-fadeIndDown">
            <span class="contact__step contact__step__two"> 2 </span>
            <span
              v-t="'contact.step_two'"
              class="contact__title contact__width text-left ml-2 contact__asterisk"
            />
          </div>

          <!-- position -->
          <template v-if="isContactPlataformForm">
            <p
              v-t="'contact.identify_with_school'"
              class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk animation-fadeIndDown"
            />
            <div class="w-full d-flex flex-row gap-2 flex-wrap justify-start animation-fadeIndDown">
              <div
                v-for="(type, index) in $t('contact.position-list')"
                :key="index"
                class="contact__option__radio--button"
              >
                <input :id="type" v-model="selectedPosition" type="radio" :value="type" />
                <label class="mt-2" :for="type">
                  {{ type }}
                </label>
              </div>
            </div>
          </template>

          <!-- school contact -->
          <template v-else>
            <p
              v-t="'contact.school_contact.title'"
              class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk animation-fadeIndDown"
            />
            <div class="w-full d-flex flex-row gap-2 flex-wrap justify-start animation-fadeIndDown">
              <div
                v-for="({ name, email }, index) in listContacts"
                :key="index"
                class="contact__option__radio--button"
              >
                <input :id="name" v-model="selectedContact" type="radio" :value="email" />
                <label class="mt-2" :for="name">
                  {{ name }}
                </label>
              </div>
            </div>

            <!-- school relate -->
            <template v-if="selectedContact !== 'empty'">
              <p
                v-t="'contact.school_relate'"
                class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk animation-fadeIndDown"
              />
              <div
                class="w-full d-flex flex-row gap-2 flex-wrap justify-start animation-fadeIndDown"
              >
                <div
                  v-for="(type, index) in $t('contact.school_relate_list')"
                  :key="index"
                  class="contact__option__radio--button"
                >
                  <input :id="type" v-model="form.school_relate" type="radio" :value="type" />
                  <label class="mt-2" :for="type">
                    {{ type }}
                  </label>
                </div>
              </div>
            </template>

            <!-- identify with school -->
            <template v-if="form.school_relate">
              <p
                v-t="'contact.identify_with_school'"
                class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk animation-fadeIndDown"
              />
              <div
                class="w-full d-flex flex-row gap-2 flex-wrap justify-start animation-fadeIndDown"
              >
                <div
                  v-for="(type, index) in $t(
                    form.school_relate === $t('contact.school_relate_list[0]')
                      ? 'contact.identify_with_school_list'
                      : 'contact.identify_with_school_list_extern',
                  )"
                  :key="index"
                  class="contact__option__radio--button"
                >
                  <input
                    :id="type"
                    v-model="form.identify_with_school"
                    type="radio"
                    :value="type"
                  />
                  <label class="mt-2" :for="type">
                    {{ type }}
                  </label>
                </div>
              </div>
            </template>
          </template>
        </template>

        <!-- Step 3 -->
        <template v-if="showStepThree">
          <div class="w-full d-flex flex-row align-center mt-8">
            <span class="contact__step contact__step__three"> 3 </span>
            <span
              v-t="'contact.step_three'"
              class="contact__title contact__width text-left ml-2 contact__asterisk"
            />
          </div>

          <!-- title message -->
          <p
            v-t="'contact.select_default_message'"
            class="mt-5 contact__title font-weight-light text-left d-block contact__asterisk"
          />
          <div class="w-full d-flex flex-row gap-2 flex-wrap justify-start">
            <div
              v-for="(type, index) in getTitleMessage"
              :key="index"
              class="contact__option__radio--button contact__option__radio--button--purple"
            >
              <input :id="type" v-model="form.message_title" type="radio" :value="type" />
              <label class="mt-2" :for="type">
                {{ type }}
              </label>
            </div>
          </div>

          <!-- Personalize message -->
          <label
            v-t="'contact.personalized_message.title'"
            class="mt-7 contact__title font-weight-light text-left d-block"
            for="large message"
          />
          <textarea
            id="large message"
            v-model="form.message"
            class="contact__text-area mt-3"
            :placeholder="$t('contact.personalized_message.placeholder')"
          />

          <!-- button send message -->
          <div class="d-flex w-full justify-between">
            <!-- terms -->
            <p
              v-t="'contact.terms'"
              class="my-3 contact__title contact__disclaimer font-medium text-left w-2/3"
            />
            <button class="mt-7 mb-6 contact__send" type="submit" :disabled="loading">
              <span v-if="!loading" v-t="'contact.send'" />
              <v-progress-circular v-else indeterminate color="white" />
            </button>
          </div>
        </template>
      </template>
      <!-- Check message -->
      <section v-else>
        <div class="contact__sucess-email">
          <v-icon large color="white">
            mdi-check
          </v-icon>
          <p v-t="'contact.message_sent'" />
        </div>
        <button class="contact__another_email" type="button" @click="sendAnotherMessage()">
          <v-icon size="25" color="white">
            mdi-reload
          </v-icon>
          {{ $t('contact.send_another_message') }}
        </button>
      </section>
    </v-form>
  </section>
</template>

<script>
import CONFIG from '@/config';
import listCodeArea from '@/locales/code_area.json';
import trackMixPanel from '@/utils/mixpanel';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ContactModal',
  props: {
    isContactPlataformForm: {
      type: Boolean,
      required: true,
    },
    schoolId: {
      type: String,
      default: null,
    },
    schoolUuid: {
      type: String,
      default: null,
    },
    campusName: {
      type: String,
      default: null,
    },
    contacts: {
      type: Array,
      default: null,
    },
    inExplorer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      validFormContact: true,
      loading: false,
      successSendMail: false,
      selectedContact: 'empty',
      selectedPosition: 'empty',
      // TODO: This i18n call is hardcoded. It should live in a more general context in i18n
      codeArea: this.$t('user_register.contact.default_dial_code'),
      form: {
        username: '',
        mail_from: '',
        phone: '',
        school_email: null,
        school_relate: null,
        identify_with_school: null,
        school_id: null,
        school_uuid: null,
        school_name: null,
        school_contact: 'empty',
        contact_type: null,
        position: null,
        message_title: null,
        message: null,
        user_id: null,
        params: {},
        profile_id: null,
        country: CONFIG.tenant,
      },
      errorForm: {
        username: [(v) => !!v || this.$t('contact.required_field')],
        mail_from: [
          (v) => !!v || this.$t('contact.required_field'),
          (v) => /.+@.+/.test(v) || this.$t('contact.invalid_mail'),
        ],
        position: false,
        contact: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      campusDetail: 'institutions/campusDetails',
    }),
    listCodeArea() {
      return listCodeArea.countries;
    },
    showStepTwo() {
      return this.form.username !== '' && this.form.mail_from !== '';
    },
    showStepThree() {
      if (this.isContactPlataformForm) {
        return this.selectedPosition !== 'empty';
      }
      return this.selectedContact !== 'empty' && this.form.school_relate !== null;
    },
    getTitleMessage() {
      // messages with contact platform
      if (this.isContactPlataformForm) {
        const isModifyChangeDigitalProfile = this.selectedPosition === this.$t('contact.position-list[1]')
        || this.selectedPosition === this.$t('contact.position-list[2]');
        return isModifyChangeDigitalProfile
          ? [...this.$t('contact.list_title_message_plataform_two')]
          : [...this.$t('contact.list_title_message_plataform')];
      }

      // messages with contact institution
      const messages = [...this.$t('contact.list_title_message')];
      const isExtern = this.form.school_relate === this.$t('contact.school_relate_list[1]');
      if (isExtern) {
        switch (this.form.identify_with_school) {
          case this.$t('contact.identify_with_school_list_extern[0]'):
            messages.shift();
            messages.unshift(this.$t('contact.other_list_title_message[0]'));
            break;
          case this.$t('contact.identify_with_school_list_extern[1]'):
            messages.shift();
            messages.unshift(this.$t('contact.other_list_title_message[1]'));
            break;
          case this.$t('contact.identify_with_school_list_extern[2]'):
            messages.shift();
            messages.unshift(this.$t('contact.other_list_title_message[2]'));
            break;
          default:
            break;
        }
      }
      return messages;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    listContacts() {
      const list = [];

      if (this.isContactPlataformForm) {
        const name = this.$t('contact.plataform_administrators');
        const email = this.mailPlatform;

        list.push({ name, email });
      } else {
        // eslint-disable-next-line
        for (const { email, contact_label } of this.contacts) {
          // eslint-disable-next-line
          const name = contact_label.contact_name;
          // eslint-disable-next-line camelcase
          const { id } = contact_label;
          const hasEmailContact = id < 5 && email;

          if (hasEmailContact) {
            list.push({ name, email });
          }
        }
      }

      return list;
    },
    mailPlatform() {
      return `${CONFIG.tenant}@explorador.com`;
    },
    mailTo() {
      if (CONFIG.environment !== 'production') {
        return this.form.mail_from;
      }
      if (this.isContactPlataformForm) {
        return this.mailPlatform;
      }
      return this.selectedContact;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.school_relate': function () {
      this.form.identify_with_school = null;
    },
  },
  mounted() {
    this.$emit('setResetModal');
  },
  methods: {
    ...mapActions({
      sendMail: 'messaging/sendMail',
      errorSnackbar: 'utils/error',
      successSnackbar: 'utils/success',
    }),
    sendContact() {
      trackMixPanel('app_send_message', {
        school_id: this.campusDetail.uuid,
        campus_code: this.campusDetail.campus_code,
        institution_code: this.campusDetail.institution_code,
        name: this.campusDetail.campus_name,
      });
      // if (!this.validForm()) {
      //   return;
      // }

      this.loading = true;
      this.form.school_contact = this.selectedContact.name;
      this.form.school_uuid = String(this.schoolUuid);
      this.form.school_id = String(this.schoolId);
      this.form.school_name = this.campusName;
      this.form.contact_type = this.isContactPlataformForm ? 'staff' : 'parent';
      this.form.phone = this.form.phone ? `${this.codeArea}${this.form.phone}` : '';
      this.form.school_email = this.mailTo;
      // this.form.school_email = this.form.mail_from;
      this.form.identify_with_school = this.isContactPlataformForm
        ? this.selectedPosition
        : this.form.identify_with_school;
      const { form } = this;
      this.sendMail({
        form,
        callback: () => {
          this.loading = false;
          this.successSendMail = true;
        },
        callbackError: () => {
          this.loading = false;
          this.errorSnackbar(this.$t('contact.snackbar.error'));
        },
      });
    },
    sendAnotherMessage() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.successSendMail = false;
      this.selectedContact = 'empty';
      this.selectedPosition = 'empty';
      this.$emit('resetForm');
    },
  },
};
</script>
