<template>
  <div class="register-main register-main--spaced">
    <div class="fill-width">
      <ModalHeader
        :content-sections="['actions', 'logo']"
        inline-logo
        small-logo
        @back="$emit('backStep')"
      />
      <RowStepper v-if="!isInMap" :step="2" />
      <ModalContent
        :content-sections="['smallTitle', 'subtitle', , 'instructions', 'infoBox', 'link']"
        :small-title-text="localeTexts.title"
        small-title-left
        :subtitle-arg="Object.keys(currentStudent).length > 0 ? currentStudent.first_name : ''"
        :subtitle-text="localeTexts.subtitle"
        :info-box-icon="'location-pin.svg'"
        :info-box-text="getAddressDisplayText()"
        :link-text="localeTexts.text_link"
        :instruction-text="localeTexts.instructions"
        info-box-fill-width
        @toLink="$emit('toLocationFlow')"
      />
    </div>
    <CallToActions
      :types="['main']"
      active-value
      :main-button-text="'user_register.continue'"
      small
      :spaced="false"
      @continue="nextStep"
    />
  </div>
</template>

<script>
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import RowStepper from '@/components/molecules/steppers/RowStepper.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ConfirmLocation',
  components: {
    ModalHeader,
    RowStepper,
    ModalContent,
    CallToActions,
  },
  props: {
    isInMap: {
      type: Boolean,
      default: false,
    },
    inStudentFlow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      legalGuardian: 'authentication/legalGuardian',
      municipalityLabels: 'options/municipalityLabels',
      students: 'authentication/students',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      currentStudent: 'authentication/currentStudent',
      roadTypes: 'options/roadTypes',
      fullAddressDetails: 'userRegister/fullAddressDetails',
    }),
    addressToConfirm() {
      if (this.inStudentFlow && this.currentStudent.address?.length > 0) {
        // Student has previously registered an address, so we show it
        return this.currentStudent.address[0];
      }
      // Either its a student with no address yet, so we suggest the legal guardian's address
      // Or we're validating the legal guardian's address, so we show it as well
      return this.legalGuardianAddress[0];
    },
    localeTexts() {
      const accessor = this.inStudentFlow ? 'student' : 'legal_guardian';
      const name = this.inStudentFlow
        ? this.currentStudent.first_name
        : this.legalGuardian.firstName;
      return {
        title: this.$t(`user_register.confirm_location.title.${accessor}`),
        subtitle: this.$t('user_register.confirm_location.subtitle', { name }),
        instructions: this.$t('user_register.confirm_location.instructions'),
        text_link: this.$t('user_register.confirm_location.text_link'),
      };
    },
  },
  methods: {
    ...mapActions({
      setStudentLocation: 'newStudentRegister/setStudentLocation',
    }),
    nextStep() {
      /* eslint-disable */
      // If nextStep is called, then the suggested address was confirmed
      // We actually only need to save the address if it's a student using the legal guardian's address
      // Otherwise, we would be overriding the same address in a request - let's avoid that :)
      if (this.inStudentFlow && !this.currentStudent.address) {
        const {

          address_details,
          address_lat,
          address_lon,
          address_name,
          location: locationName,
        } = this.addressToConfirm;
        let location = {
          // student flow -> Student ID
          uuid: this.currentStudent.uuid,
          // Duplicating the legal guardian's address
          same_address: true,
          // TODO: Determine
          address_name: address_name,
          // Data that comes from the backend regarding the address
          address_details,
          address_lat,
          address_lon,
          address_street: address_details,
          address_number: address_details,
          address_complement: address_details,
          location: locationName,
        };

        this.setStudentLocation({ location, updateStudent: true });
      }

      this.$emit('nextStep', this.addressToConfirm);
      return null;
    },
    getAddressDisplayText() {
      if (this.addressToConfirm.address_details === 'Selecciona tu ubicación') {
        return this.addressToConfirm.location;
      }
      if (this.addressToConfirm.address_details) {
        return this.addressToConfirm.address_details;
      }
      if (this.addressToConfirm.address_name?.address_name) {
        return this.addressToConfirm.address_name.address_name;
      }
      return this.addressToConfirm.location ?? '';
    },
  },
};
</script>
