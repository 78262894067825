<template>
  <div
    class="register-main"
  >
    <ModalHeader
      :content-sections="['logo']"
      :welcome-text-logo="!validDigitalEnrollment"
      :small-logo="validDigitalEnrollment"
    />
    <ModalContent
      v-if="validDigitalEnrollment"
      :content-sections="imageUrl ? ['smallTitle', 'subtitle', 'imageAtom'] : ['smallTitle', 'subtitle']"
      image-rounded
      :small-title-text="
        $t('user_register.welcome.tenant_register_title', {
          tenant: digitalEnrollmentTenant.campus_name,
        })
      "
      small-title-primary-color
      :small-title-no-margin="true"
      :image-source="imageUrl"
      :image-alt-text="$t('user_register.welcome.image_alt', {
        campus: digitalEnrollmentTenant.campus_name,
      })"
      :subtitle-text="$t('user_register.welcome.tenant_register_subtitle')"
      subtitle-center
      subtitle-neutral
      image-medium
    />
    <CallToActions
      :option-text1="'user_register.welcome.login'"
      :option-text2="'user_register.welcome.register'"
      :option-text-active-tag="'click_reg_explore-as-user_log-in-button'"
      :option-text-tag="'click_reg_explore-as-user_sing-in-button'"
      :link-text="'views.login.back-guest'"
      :link-text-tag="'click_reg_explore-as-user_continue-as-guest-textlink'"
      :types="possibleActions"
      @nextStep="nextStep"
      @toLink="$router.push('/')"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalHeader from '@/components/molecules/modals/Header.vue';
import ModalContent from '@/components/molecules/modals/Content.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';

export default {
  name: 'LandingModal',
  components: {
    ModalHeader,
    ModalContent,
    CallToActions,
  },
  computed: {
    ...mapGetters({
      inDigitalEnrollment: 'digitalEnrollment/inDigitalEnrollment',
      digitalEnrollmentTenant: 'digitalEnrollment/tenant',
    }),
    possibleActions() {
      // In digital enrollment, the user can only register or login, no guests
      return this.inDigitalEnrollment ? ['option'] : ['option', 'link'];
    },
    validDigitalEnrollment() {
      return this.inDigitalEnrollment && this.digitalEnrollmentTenant !== null;
    },
    imageUrl() {
      return (this.validDigitalEnrollment && this.digitalEnrollmentTenant.image_thumb)
        ? this.digitalEnrollmentTenant.image_thumb[0].image_link
        : '';
    },
  },
  methods: {
    nextStep(actionIndex) {
      if (actionIndex === 1) {
        return this.$emit('toLogin');
      }
      return this.$emit('nextStep');
    },
  },
};
</script>
