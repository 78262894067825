import { messagingApi } from '@/api';
import CONFIG from '@/config';

const tableName = CONFIG.environment === ('development' || 'staging') ? 'messages_staging' : 'messages';
export default {
  sendMail(data) {
    return messagingApi.post('mailing/sendmail/', data);
  },
  getMessagesCount() {
    return messagingApi.get(`/count/table/${tableName}/`);
  },
  getMessages({ schoolUuid }) {
    const indexName = 'school_uuid';
    return messagingApi.get(`/mailing/${tableName}/search/${indexName}/${schoolUuid}/`);
  },
  getGroupedMessages({ index, uuid, groupBy }) {
    return messagingApi.get(`/stats/${tableName}/${index}/${uuid}/${groupBy}/`);
  },
};
