var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-title",class:{
    'main-title--secondary': _vm.secondary,
    'main-title--neutral': _vm.neutral,
    'main-title--small': _vm.small,
    'main-title--tiny': _vm.tiny,
    'main-title--left fill-width': _vm.leftAlign,
    'ma-0': _vm.noMargin,
  }},[_c('h1',{directives:[{name:"t",rawName:"v-t",value:(_vm.setText()),expression:"setText()"}]})])
}
var staticRenderFns = []

export { render, staticRenderFns }