<template>
  <div
    class="flex-column"
    :class="{
      'register-main--small-btn-div': small && !absolute,
      'register-main--btn-div': !small && !absolute && !medium,
      'search-location--btn-div': absolute,
      'register-main--btn-div--tiny': tiny,
    }"
  >
    <button
      type="button"
      :class="{
        'register-main--btn-div--btn-main': !small && !absolute,
        'register-main--small-btn-div--btn-main': small && !absolute,
        'register-main--btn-div--btn-main--active':
          ((activeValue || isActive) && !activeLight) && !small && !absolute,
        'register-main--small-btn-div--btn-main--active':
          ((activeValue || isActive) && !activeLight) && small && !absolute,
        'search-location--btn-div--btn-main': absolute,
        'register-main--small-btn-div--btn-main--white': light,
        'register-main--small-btn-div--btn-main--purple': purple,
        'register-main--medium-btn-div': medium,
        'register-main--btn-div--btn-main--borders-transparency': withBorder,
      }"
      @click="mainButtonClick()"
    >
      <div v-if="loader" style="height: 48px" class="d-flex align-center">
        <v-progress-circular
          v-if="loader"
          class="d-flex align-center justify-center"
          :style="getLoaderSize()"
          indeterminate
          color="white"
        />
      </div>
      <div
        v-if="!loader"
        v-t="text"
        class="d-flex align-center justify-center"
        :style="getSize"
      />
    </button>
  </div>
</template>

<script>
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'MainButton',
  components: {},
  props: {
    text: {
      type: [String, Object],
      default: '',
    },
    mixpanelTag: {
      type: String,
      default: null,
    },
    activeValue: {
      type: [String, Boolean],
      default: false,
    },
    loader: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    tiny: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    activeLight: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    purple: {
      type: Boolean,
      default: false,
    },
    withBorder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getSize() {
      if (this.tiny) {
        return 'height: 48px; padding: 8px 12px';
      }
      if ((this.small || this.absolute)) {
        return 'height: 48px; padding: 12px 28px';
      }
      return 'height: 48px';
    },
  },
  methods: {
    mainButtonClick() {
      if (this.mixpanelTag) {
        trackMixPanel(this.mixpanelTag);
      }
      this.$emit('continue');
    },
    getLoaderSize() {
      if (this.small) {
        return 'width: 143px; height: 30px';
      }
      return 'height: 40px';
    },
  },
};
</script>
