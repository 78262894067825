<template>
  <div
    class="main-title"
    :class="{
      'main-title--secondary': secondary,
      'main-title--neutral': neutral,
      'main-title--small': small,
      'main-title--tiny': tiny,
      'main-title--left fill-width': leftAlign,
      'ma-0': noMargin,
    }"
  >
    <h1
      v-t="setText()"
    />
  </div>
</template>

<script>
export default {
  name: 'MainTitle',
  props: {
    secondary: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    args: {
      type: String,
      default: null,
    },
    leftAlign: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    tiny: {
      type: Boolean,
      default: false,
    },
    neutral: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    setText() {
      if (this.args) {
        const text = {
          path: this.text,
          args: { name: this.args },
        };
        return text;
      }
      return this.text;
    },
  },
};
</script>
