<template>
  <section
    class="application-to-date"
    :class="showApplicationsToDate ? 'application-to-date-active' : ''"
  >
    <button
      type="button"
      class="w-full d-flex"
      :class="showApplicationsToDate ? 'justify-left' : 'justify-space-between'"
      @click="showApplications()"
    >
      <TextAtom
        :value="$t('feedback.applications_to_date.title')"
        tag="p"
        font="poppins"
        color="secondary-medium-light"
        weight="500"
        size="normal"
      />
      <SvgIcon
        v-if="!showApplicationsToDate"
        :icon="'horizontal-expand.svg'"
        size="24"
        style="margin: 0 8px 0 8px "
      />
    </button>
    <div v-if="showApplicationsToDate" class="mt-2">
      <figure v-for="({ image, text, size }, index) in getApplicationsToDate" :key="index" class="mt-3">
        <div class="application-image">
          <SvgIcon
            :icon="image"
            alt="application icon"
            :size="size || '23'"
          />
        </div>
        <figcaption class="ml-2">
          <TextAtom
            :value="text"
            color="neutral-800"
            font="inter"
            size="default"
            class="text-left feedback-text-wrap-balance"
          />
        </figcaption>
      </figure>
    </div>
  </section>
</template>

<script>
import TextAtom from '@/components/atoms/labels/TextAtom.vue';
import SvgIcon from '@/components/atoms/icons/SvgIcon.vue';
import trackMixPanel from '@/utils/mixpanel';

export default {
  name: 'ApplicationsToDate',
  components: {
    TextAtom, SvgIcon,
  },
  props: {
    stats: {
      type: Object,
      required: true,
    },
    openApplications: {
      type: Boolean,
      default: false,
      required: false,
    },
    campusCode: {
      type: String,
      default: '',
    },
    application: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showApplicationsToDate: false,
    };
  },
  computed: {
    getApplicationsToDate() {
      return [
        {
          image: 'seat_available.svg',
          text: this.$tc('feedback.applications_to_date.available', this.stats?.seats),
        },
        {
          image: 'seat_higher_priority.svg',
          text: this.$tc('feedback.applications_to_date.higher_priority', this.stats?.n_assigned_above),
        },
        {
          image: 'seat_assigned_same_priority.svg',
          text: this.$tc('feedback.applications_to_date.assigned_same_priority', this.stats?.n_assigned),
        },
        {
          image: 'seat_same_priority.svg',
          text: this.$tc('feedback.applications_to_date.same_priority', this.stats?.n_considered),
        },
        {
          size: '25',
          image: 'seat_not_assigned.svg',
          text: this.$tc('feedback.applications_to_date.not_assigned', (this.stats.n_considered_tot - this.stats.n_assigned_tot)),
        },
      ];
    },
  },
  mounted() {
    this.showApplicationsToDate = this.openApplications;
  },
  methods: {
    showApplications() {
      trackMixPanel(
        'click_feedbackchile_postind_verpostulaciones2',
        {
          campusCode: this.campusCode,
          applicationsToDate: { ...this.getApplicationsToDate },
          campus: this.application,
        },
      );
      this.showApplicationsToDate = !this.showApplicationsToDate;
    },
  },
};
</script>
<style lang="scss" scoped>

figure {
  display: flex;
  align-items: center;
}
.application-to-date {
  max-width: 100% !important;
  background: white !important;
  border: 1px solid var(--secondary-medium-light);
  padding: 19px 23px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 28px;
  margin: auto;
  height: 45px;
}

.application-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px !important;
}

figcaption {
  width: calc(100% - 30px) !important;
}

.application-to-date-active {
  max-width: 95% !important;
  border-radius: 8px;
  height: 100% !important;
}
</style>
