var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"campus-detail__travel-time campus-detail__module mt-2"},[_c('h3',{directives:[{name:"t",rawName:"v-t",value:('campus-details.travel_time.title'),expression:"'campus-details.travel_time.title'"}],staticClass:"campus-detail__module__title ml-2"}),_c('div',{staticClass:"campus-detail__travel-time__boxes",style:({ '--n-elements-per-row': _vm.mobile ? '1' : '2', '--gap': _vm.mobile ? '0' : '10px' })},[_c('div',{staticClass:"campus-detail__travel-time__institution mt-4"},[_c('div',{staticClass:"campus-detail__travel-time__boxes--institution font-weight-medium text-white"},[_c('v-avatar',{attrs:{"size":"25"}},[_c('SVGIcon',{attrs:{"icon":"place.svg"}})],1),_c('p',{staticClass:"campus-detail__travel-time__boxes--address-inst"},[_vm._v(" "+_vm._s(_vm.institution.address === '' ? _vm.institution.name : _vm.institution.address)+" ")])],1)]),_c('div',{staticClass:"campus-detail__travel-time__from mt-4"},[_c('div',{staticClass:"campus-detail__travel-time__boxes--flex"},[_c('div',{staticClass:"flex campus-detail__module__subtitle campus-detail__travel-time__boxes--address-inst ml-0 pl-0"},[_c('em',[_c('h3',{directives:[{name:"t",rawName:"v-t",value:('campus-details.travel_time.from_location'),expression:"'campus-details.travel_time.from_location'"}],staticClass:"mr-1"})]),_c('em',[_vm._v(_vm._s(_vm.getInfoLocationUser.name)+": ")])])]),_c('p',{staticClass:"campus-detail__module__title campus-detail__travel-time__boxes--user"},[_vm._v(" "+_vm._s(_vm.getInfoLocationUser.address)+" ")])])]),_c('div',{staticClass:"travel-time__radio--button mt-2"},[_vm._l((_vm.listTravelTime),function({
        name, time, icon, alt,
      }){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedTravelTime),expression:"selectedTravelTime"}],attrs:{"id":name,"type":"radio"},domProps:{"value":name,"checked":_vm._q(_vm.selectedTravelTime,name)},on:{"change":function($event){_vm.selectedTravelTime=name}}}),_c('label',{staticClass:"mt-2",attrs:{"for":name}},[_c('SVGIcon',{attrs:{"icon":icon,"size":"25","alt-text":alt,"squared":""}}),_c('span',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(time)+" ")])],1)]})],2),(_vm.isErrorRequestTravelTime())?_c('p',{directives:[{name:"t",rawName:"v-t",value:('campus-details.travel_time.error_request'),expression:"'campus-details.travel_time.error_request'"}],staticClass:"campus-detail__module__title font-weight-light mt-2"}):_vm._e(),_c('GmapMap',{ref:"mapRef",staticClass:"campus-detail__travel-time__map mt-4",attrs:{"zoom":_vm.zoom,"center":_vm.institution.location,"options":{
      disableDefaultUI: true,
      clickableIcons: false,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      styles: _vm.mapStyle,
      gestureHandling: 'greedy',
    }}},[_c('GmapCustomMarker',{attrs:{"marker":_vm.getInfoLocationUser.location,"alignment":"top"}},[_c('SVGIcon',{attrs:{"icon":"home-icon.svg","size":"30","alt-text":"home"}})],1),_c('GmapCustomMarker',{attrs:{"marker":_vm.institution.location,"alignment":"top"}},[_c('SVGIcon',{attrs:{"icon":"institution-icon.svg","size":"30","alt-text":"institution"}})],1),(_vm.waypoints.walk.end)?_c('GmapCustomMarker',{attrs:{"marker":_vm.waypoints.walk.end,"alignment":"top"}},[(_vm.selectedTravelTime === 'TRANSIT')?_c('SVGIcon',{attrs:{"icon":"bus_marker.svg","size":"25","alt-text":"bus"}}):_vm._e()],1):_vm._e(),(_vm.selectedTravelTime === 'TRANSIT')?[_c('DirectionsRenderer',{attrs:{"map":_vm.$refs,"travel-mode":"WALKING","origin":_vm.waypoints.walk.start,"destination":_vm.waypoints.walk.end,"waypoints":{},"render":{}}}),_c('DirectionsRenderer',{attrs:{"map":_vm.$refs,"travel-mode":"DRIVING","origin":_vm.waypoints.walk.end,"destination":_vm.institution.location,"waypoints":_vm.getWaypoints,"render":{}}})]:_vm._e(),(_vm.selectedTravelTime !== 'TRANSIT' && _vm.selectedTravelTime !== null)?_c('DirectionsRenderer',{key:_vm.keyDirectionsRenderer,attrs:{"map":_vm.$refs,"travel-mode":_vm.selectedTravelTime,"origin":_vm.getInfoLocationUser.location,"destination":_vm.institution.location,"render":_vm.listTravelTime[`${_vm.selectedTravelTime.toLowerCase()}`].render},on:{"response-directions":_vm.getResponseDirections}}):_vm._e()],2),_c('picture',{staticClass:"d-flex flex-row align-middle mt-5"},[_c('img',{attrs:{"src":require("@/assets/icons/exclamation-purple.svg"),"width":"20px","alt":"exclamation"}}),_c('p',{directives:[{name:"t",rawName:"v-t",value:('campus-details.travel_time.disclaimer'),expression:"'campus-details.travel_time.disclaimer'"}],staticClass:"campus-detail__travel-time__disclaimer ml-1"})]),(_vm.partnerships.length > 0)?_c('MainActivities',{staticClass:"mt-4",attrs:{"partnerships":_vm.partnerships}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }