import { backendAPI } from '@/api';

const appUrl = 'registration';

export default {
  patchStep1(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_1/`, data);
  },
  patchStep2(data) {
    return backendAPI.patch(`${appUrl}/applicant/lg_relationship/`, data);
  },
  patchStep3(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_3/`, data);
  },
  patchStep4(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_4/`, data);
  },
  patchStep5(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_5/`, data);
  },
  patchStep5Pre(data) {
    const params = { identification_number: data.identification_number };
    return backendAPI.get(`${appUrl}/applicant/step_5_pre/`, { params });
  },
  patchStep6(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_6/`, data);
  },
  patchStep7(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_7/`, data);
  },
  patchStep8(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_8/`, data);
  },
  patchStep9_1(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_9_1/`, data);
  },
  patchStep9_2(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_9_2/`, data);
  },
  patchStep10(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_10/`, data);
  },
  patchStep11(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_11/`, data);
  },
  patchStep12(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_12/`, data);
  },
  patchArmedConflict(data) {
    return backendAPI.patch(`${appUrl}/applicant/armed_conflict/`, data);
  },
  patchDisabilities(data) {
    return backendAPI.patch(`${appUrl}/applicant/disabilites/`, data);
  },
  patchIcbfBoolean(icbfInfo) {
    return backendAPI.patch(`${appUrl}/applicant/c_icbf/`, icbfInfo);
  },
  patchStep15(data) {
    return backendAPI.patch(`${appUrl}/applicant/step_15/`, data);
  },
  setSteps(data) {
    return backendAPI.patch(`${appUrl}/applicant/set_steps/`, data);
  },
  patchStep12Pre(nDocument) {
    const params = { identification_number: nDocument };
    return backendAPI.get(`${appUrl}/applicant/step_12_pre/`, { params });
  },
  setStudents(gradePayload) {
    return backendAPI.patch(`${appUrl}/applicant/f_step_1/`, gradePayload);
  },
  setStudentLocation({ location }) {
    return backendAPI.patch(`${appUrl}/applicant/c_step_3/`, location);
  },
  setStudentGrades({ grades }) {
    return backendAPI.patch(`${appUrl}/applicant/c_step_2/`, grades);
  },
  setStudentId({ studentId }) {
    return backendAPI.patch(`${appUrl}/applicant/c_step_4/`, studentId);
  },
  setStudentSisbenfGroup({ icbfInfo }) {
    return backendAPI.patch(`${appUrl}/applicant/c_sisben/`, icbfInfo);
  },
  getApplicantCheckIcbf(uuid) {
    const params = { identification_number: uuid };
    return backendAPI.get(`${appUrl}/applicant/check_icbf/`, { params });
  },
  setBloodSiblingInfo(brother) {
    return backendAPI.patch(`${appUrl}/applicant/blood_sibling/`, brother);
  },
  setNativeLanguage(languageArray) {
    return backendAPI.patch(`${appUrl}/applicant/native_language/`, languageArray);
  },
};
