import { TENANT_CONFIGURATION } from '@/constants/tenant';
import { DEFAULT_CIRCLE_RADIUS } from '@/constants/explorer/map';
import services from '@/services';

const getDefaultState = () => ({
  map: {
    center: TENANT_CONFIGURATION.DEFAULTS.LOCATION.coordinates,
    zoom: null,
    cornerBounds: null,
    processId: null,
  },
  symbols: {
    distanceCircle: {
      radius: 2000,
      show: true,
    },
  },
  flags: {
    showMarker: false,
    showCard: false,
    hideVacancies: false,
    hideCategories: false,
    interactiveEmbed: false,
    shrinkUnfiltered: false,
  },
  labels: {},
  calledLabels: [],
});

const state = getDefaultState();

const getters = {
  labels: ({ labels }) => labels,
  labelsByKey: ({ labels }) => (key) => labels[key] ?? [],
  mapNavigation({ map }) {
    const { center, zoom } = map;
    return { center, zoom };
  },
  mapCenter({ map }) {
    return map.center;
  },
  mapZoom({ map }) {
    return map.zoom;
  },
  mapCornerBounds({ map }) {
    return map.cornerBounds;
  },
  processId({ map }) {
    return map.processId;
  },
  distanceCircleConfiguration({ symbols }) {
    return symbols.distanceCircle;
  },
  showCard({ flags }) {
    return flags.showCard;
  },
  hideVacancies({ flags }) {
    return flags.hideVacancies;
  },
  flags({ flags }) {
    return flags;
  },
};

const mutations = {
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  setMapCenter(state, { lat, lng }) {
    state.map.center = { lat: parseFloat(lat), lng: parseFloat(lng) };
  },
  setMapZoom(state, { zoom }) {
    state.map.zoom = zoom;
  },
  setDistanceCircleRadius(state, radius) {
    state.symbols.distanceCircle.radius = radius;
  },
  setShowDistanceCircle(state, show) {
    state.symbols.distanceCircle.show = show;
  },
  setProcessId(state, processId) {
    state.map.processId = processId;
  },
  setCornerBounds(state, cornerBounds) {
    state.map.cornerBounds = cornerBounds;
  },
  setShowCard(state, { show }) {
    state.flags.showCard = show;
  },
  setInteractiveEmbed(state, { interactive }) {
    state.flags.interactiveEmbed = interactive;
  },
  setLabels(state, { key, value }) {
    state.labels[key] = value;
  },
  setCalledLabels(state, key) {
    state.calledLabels.push(key);
  },
  setHideVacancies(state, { hide }) {
    state.flags.hideVacancies = hide;
  },
  setHideCategories(state, { hide }) {
    state.flags.hideCategories = hide;
  },
  setShrinkUnfiltered(state, { shrink }) {
    state.flags.shrinkUnfiltered = shrink;
  },
};

const actions = {
  navigateTo({ commit }, { lat, lng, zoom }) {
    if (lat && lng) commit('setMapCenter', { lat, lng });
    if (zoom) commit('setMapZoom', { zoom });
  },
  setMapZoom({ commit }, { zoom }) {
    commit('setMapZoom', { zoom });
  },
  configureDistanceCircle({ commit }, { radius = DEFAULT_CIRCLE_RADIUS, show = true }) {
    commit('setDistanceCircleRadius', radius);
    commit('setShowDistanceCircle', show);
  },
  setProcessId({ commit }, { processId }) {
    if (!Array.isArray(processId)) {
      commit('setProcessId', [processId]);
    } else {
      commit('setProcessId', processId);
    }
  },
  setCornerBounds({ commit }, { cornerBounds }) {
    commit('setCornerBounds', cornerBounds);
  },
  setShowCard({ commit }, { show }) {
    commit('setShowCard', { show });
  },
  setHideVacancies({ commit }, hideVacancies) {
    commit('setHideVacancies', hideVacancies);
  },
  setFlags({ commit }, {
    showMarker, showCard, hideVacancies, hideCategories, interactiveEmbed, shrinkUnfiltered,
  }) {
    if (showMarker !== undefined) commit('setShowMarker', showMarker);
    if (showCard !== undefined) commit('setShowCard', showCard);
    if (hideVacancies !== undefined) commit('setHideVacancies', { hide: hideVacancies });
    if (hideCategories !== undefined) commit('setHideCategories', { hide: hideCategories });
    if (interactiveEmbed !== undefined) commit('setInteractiveEmbed', { interactive: interactiveEmbed });
    if (shrinkUnfiltered !== undefined) commit('setShrinkUnfiltered', { shrink: shrinkUnfiltered });
  },
  /**
   * Checks if the given labels are already cached or called, and if not, retrieves them from the server.
   * @param {Object} context - The Vuex action context object.
   * @param {Array} payload.keysLabels - An array of label keys to check and retrieve.
   */
  async checkLabels({ commit }, { keysLabels = [] }) {
    let promisesOfLabels = [];
    const filterKeyLabels = [];

    keysLabels.forEach((keyLabel) => {
      const isCalled = state.calledLabels.includes(keyLabel);
      const isCached = Object.defineProperty.hasOwnProperty.call(state.labels, keyLabel);

      if (!isCached) commit('setCalledLabels', keyLabel);
      if (!isCached && !isCalled) filterKeyLabels.push(keyLabel);
    });

    if (filterKeyLabels.length === 0) return;
    promisesOfLabels = filterKeyLabels.map((keyLabel) => services.explorerLabelService.getLabels({ key: keyLabel }));

    try {
      let dataPromisesOfLabels = await Promise.allSettled(promisesOfLabels);
      dataPromisesOfLabels = dataPromisesOfLabels.filter((result) => result.status === 'fulfilled');

      dataPromisesOfLabels.forEach((result, index) => {
        commit('setLabels', { key: filterKeyLabels[index], value: result?.value?.data });
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error, 'error load labels');
    }
  },
  /**
   * Checks if a label is already cached or called, and if not, retrieves it from the server.
   * @param {Object} context - The Vuex action context object.
   * @param {string} keyLabel - The key of the label to check.
   * @returns {Promise<Object>} - A Promise that resolves to the label data.
   */
  async checkLabelInmediatly({ commit }, { keyLabel }) {
    const isCalled = state.calledLabels.includes(keyLabel);
    const isCached = Object.defineProperty.hasOwnProperty.call(state.labels, keyLabel);

    if (!isCached) commit('setCalledLabels', keyLabel);
    if (!isCached && !isCalled) {
      try {
        const { data } = await services.explorerLabelService.getLabels({ key: keyLabel });
        commit('setLabels', { key: keyLabel, value: data });
        return data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error, 'error load label ', keyLabel);
      }
    }

    return state.labels[keyLabel];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
