<template>
  <section class="bg-primary-dark w-full d-flex flex-column">
    <GmapMap
      id="map"
      :center="location"
      :zoom="13.3"
      :options="{
        disableDefaultUI: true,
        clickableIcons: false,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        scrollwheel: false,
        draggable: false,
        styles: stylesGoogleMap,
        gestureHandling: 'greedy',
      }"
      class="map"
    >
      <GmapCustomMarker id="student-location" :marker="location">
        <img
          :src="require(`@/assets/feedback/marker-student.svg`)"
          width="18"
          alt="marker student"
        />
      </GmapCustomMarker>
      <template v-if="recommendations.length > 0">
        <!-- Marker recommendations -->
        <GmapCustomMarker
          v-for="(marker, index) in getLocationRecommendations"
          :key="`recommendation-${index}`"
          :marker="marker"
        >
          <img
            :src="require(`@/assets/feedback/marker-white-full.svg`)"
            width="18"
            alt="marker student"
          />
        </GmapCustomMarker>
        <!-- Marker recommendations payment and performance -->
        <GmapCustomMarker
          v-for="(marker, index) in getLocationFilteredSchools"
          :key="`filtered-${index}`"
          :marker="marker"
        >
          <img
            :src="require(`@/assets/feedback/marker-green.svg`)"
            width="18"
            alt="marker student"
          />
        </GmapCustomMarker>
      </template>
      <!-- Marker applications -->
      <GmapCustomMarker
        v-for="(marker, index) in getLocationApplications"
        :key="`applications-${index}`"
        :marker="marker"
      >
        <img
          :src="require(`@/assets/feedback/marker-favorite.svg`)"
          width="18"
          alt="marker student"
        />
      </GmapCustomMarker>
      <GmapCircle
        id="student-location-radius"
        :center="location"
        :radius="3000"
        :visible="true"
        :options="mapCircle"
      />
    </GmapMap>

    <div class="w-full d-flex align-center justify-center py-7">
      <div class="space-y-4">
        <div class="d-flex flex-row justify-start">
          <img
            src="@/assets/feedback/marker-green.svg"
            width="18"
            alt="marker student"
          />
          <TextAtom
            value="feedback.map.filtered"
            color="secondary-light"
            font="inter"
            class="ml-2"
          />
        </div>
        <div class="d-flex flex-row justify-start">
          <img
            src="@/assets/feedback/marker-white-full.svg"
            width="18"
            alt="recommended"
          />
          <TextAtom
            value="feedback.map.around"
            color="secondary-light"
            font="inter"
            class="ml-2"
          />
        </div>
        <div class="d-flex flex-row justify-start">
          <img
            src="@/assets/feedback/marker-favorite.svg"
            width="18"
            alt="marker student"
          />
          <TextAtom
            value="feedback.map.applied"
            color="secondary-light"
            font="inter"
            class="ml-2"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import stylesGoogleMap from '@/assets/mapStyle.json';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'FeedbackMap',
  components: {
    GmapCustomMarker, TextAtom,
  },
  props: {
    location: {
      type: Object,
      required: true,
      validate: (location) => {
        if (!location || (location.lat && location.lng)) return true;
        return false;
      },
    },
    recommendations: {
      type: Array,
      required: true,
    },
    applications: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stylesGoogleMap,
      mapCircle: {
        strokeColor: 'rgba(255, 255, 255, 0.9)',
        strokeWeight: 2,
        fillColor: 'rgba(255, 255, 255, 0.6)',
        radius: 3000,
      },
    };
  },
  computed: {
    getLocationFilteredSchools() {
      const hasMultimedia = ({ multimedia_options: multimedia }) => {
        const testimonialKeys = Object.keys(multimedia).filter(
          (key) => key.startsWith('has_testimonial'),
        );

        const hasTestimonial = testimonialKeys.some((key) => multimedia[key]);
        return hasTestimonial && multimedia.has_drone_flight && multimedia.has_tour;
      };
      return this.recommendations.filter(hasMultimedia).map(({ location: { lat, lon } }) => ({ lat, lng: lon }));
      // return this.recommendations.filter(
      //  (recommendation) => recommendation.map_category === 'highlighted').map(({ lat, lon }) => ({ lat, lng: lon }));
    },
    filteredUUIDs() {
      return new Set(this.getLocationFilteredSchools.map(({ uuid }) => uuid));
    },
    getLocationRecommendations() {
      return this.recommendations
        .filter(({ uuid }) => !this.filteredUUIDs.has(uuid))
        .map(({ location: { lat, lon } }) => ({ lat, lng: lon }));
    },
    getLocationApplications() {
      return this.applications.map((application) => ({
        lat: application.location.lat,
        lng: application.location.lon,
      }));
    },
  },
};
</script>
