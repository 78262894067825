/* eslint-disable no-empty-pattern */
import CONFIG from '@/config';
import services from '@/services';

const getDefaultState = () => ({
  programGenders: [],
  userGenders: [],
  grades: [],
  stages: [],
  agreementsLabels: [],
  specialties: [],
  pridePointLabels: [],
  regions: [],
  performanceCategories: [],
  monthlyPayments: [],
  paymentBandLabels: [],
  paymentTypeLabels: [],
  actualVideo: null,
  documentTypes: [],
  relationshipTypes: [],
  disabilities: [],
  armedConflictTypes: [],
  nationalities: [],
  nationalIdentifications: [],
  municipalities: [],
  roadTypes: [],
  roadTypeLabels: [],
  sectorLabels: [],
  languageLabels: [],
  languageLevelsLabels: [],
  sportsLabels: [],
  infrastructureLabels: [],
  safetyLabels: [],
  membersLabels: [],
  extracurricularLabels: [],
  supportLabels: [],
  locationTypeLabels: [],
  sisbenGroups: [],
  audiovisualLabel: [],
  partnershipLabels: [],
  nativeLanguageLabels: [],
  contactLabels: [],
  shiftsLabels: [],
  modalitiesLabels: [],
  testimonialsLabel: [],
});

const state = {
  ...getDefaultState(),
};

const getters = {
  programGenders: ({ programGenders }) => programGenders,
  userGenders: ({ userGenders }) => userGenders,
  genderLabels: ({ userGenders }) => userGenders.map((userGender) => userGender.gender_label),
  pridePointLabels: ({ pridePointLabels }) => pridePointLabels,
  grades: ({ grades }) => grades, // !! FALTA
  stages: ({ stages }) => stages, // !! FALTA
  specialties: ({ specialties }) => specialties,
  specialityLabels: ({ specialties }) => specialties.map((speciality) => speciality.specialty_name),
  nationalities: ({ nationalities }) => nationalities,
  nationalityLabels: ({ nationalities }) => nationalities.map((nationality) => nationality.nationality_name),
  nationalIdentifications: ({ nationalIdentifications }) => nationalIdentifications,
  nationalIdentificationLabels({ nationalIdentifications }) {
    return nationalIdentifications.map((nationalIdentification) => nationalIdentification.identification_type_name);
  },
  regions: ({ regions }) => regions,
  regionLabels: ({ regions }) => regions.map(({ name }) => name),
  municipalities: ({ municipalities }) => municipalities,
  municipalityLabels: ({ municipalities }) => municipalities.map(({ name }) => name).sort(),
  roadTypes: ({ roadTypes }) => roadTypes,
  roadTypeLabels: ({ roadTypes }) => roadTypes.map((road) => road.tipo_via),
  performanceCategories: ({ performanceCategories }) => performanceCategories,
  monthlyPayments: ({ monthlyPayments }) => monthlyPayments,
  paymentBandLabels: ({ paymentBandLabels }) => paymentBandLabels,
  paymentTypeLabels: ({ paymentTypeLabels }) => paymentTypeLabels,
  actualVideo: ({ actualVideo }) => actualVideo,
  documentTypes: ({ documentTypes }) => documentTypes,
  relationshipOptions: ({ relationshipTypes }) => relationshipTypes,
  disabilities: ({ disabilities }) => disabilities,
  armedConflictTypes: ({ armedConflictTypes }) => armedConflictTypes,
  agreementsLabels: ({ agreementsLabels }) => agreementsLabels,
  sectorLabels: ({ sectorLabels }) => sectorLabels,
  languageLabels: ({ languageLabels }) => languageLabels,
  sportsLabels: ({ sportsLabels }) => sportsLabels,
  languageLevelsLabels: ({ languageLevelsLabels }) => languageLevelsLabels,
  infrastructureLabels: ({ infrastructureLabels }) => infrastructureLabels,
  safetyLabels: ({ safetyLabels }) => safetyLabels,
  membersLabels: ({ membersLabels }) => membersLabels,
  extracurricularLabels: ({ extracurricularLabels }) => extracurricularLabels,
  supportLabels: ({ supportLabels }) => supportLabels,
  locationTypesLabels: ({ locationTypeLabels }) => locationTypeLabels,
  sisbenGroups: ({ sisbenGroups }) => sisbenGroups,
  audiovisualLabel: ({ audiovisualLabel }) => audiovisualLabel,
  partnershipLabels: ({ partnershipLabels }) => partnershipLabels,
  nativeLanguageLabels: ({ nativeLanguageLabels }) => nativeLanguageLabels,
  contactLabels: ({ contactLabels }) => contactLabels,
  shiftsLabels: ({ shiftsLabels }) => shiftsLabels,
  modalitiesLabels: ({ modalitiesLabels }) => modalitiesLabels,
  testimonialsLabel: ({ testimonialsLabel }) => testimonialsLabel,
};

const mutations = {
  resetStore(state) {
    Object.assign(state, getDefaultState());
  },
  setSectorLabels(state, newSector) {
    state.sectorLabels = newSector;
  },
  setProgramGenders(state, newGenders) {
    state.programGenders = newGenders;
  },
  setUserGenders(state, newGenders) {
    state.userGenders = newGenders;
  },
  setGrades(state, newGrades) {
    state.grades = newGrades;
  },
  setStages(state, newGrades) {
    state.stages = newGrades;
  },
  setPridePointLabels(state, newPridePoints) {
    state.pridePointLabels = newPridePoints;
  },
  setSpecialties(state, specialties) {
    state.specialties = specialties;
  },
  setRegions(state, newRegions) {
    state.regions = newRegions;
  },
  setMunicipalities(state, municipalities) {
    state.municipalities = municipalities;
  },
  setRoadType(state, roadTypes) {
    state.roadTypes = roadTypes;
  },
  setPerformanceCategories(state, newCategories) {
    state.performanceCategories = newCategories;
  },
  setMonthlyPayments(state, newMonthlyPayments) {
    state.monthlyPayments = newMonthlyPayments;
  },
  setPaymentBandLabels(state, newPaymentBandLabels) {
    state.paymentBandLabels = newPaymentBandLabels;
  },
  setPaymentTypeLabels(state, newPaymentTypeLabels) {
    state.paymentTypeLabels = newPaymentTypeLabels;
  },
  setActualVideo(state, newActualVideo) {
    state.actualVideo = newActualVideo;
  },
  setDocumentTypes(state, types) {
    state.documentTypes = types;
  },
  setRelationshipOptions(state, options) {
    state.relationshipTypes = options;
  },
  setDisabilityOptions(state, options) {
    state.disabilities = options;
  },
  setArmedConflictTypes(state, options) {
    state.armedConflictTypes = options;
  },
  setSisbenGroups(state, options) {
    state.sisbenGroups = options;
  },
  setPartnerships(state, options) {
    state.partnershipLabels = options;
  },
  setNativeLanguage(state, options) {
    state.nativeLanguageLabels = options;
  },
  setNationalities(state, nationalities) {
    state.nationalities = nationalities;
  },
  setNationalIdentification(state, nationalIdentifications) {
    state.nationalIdentifications = nationalIdentifications;
  },
  setAgreementLabels(state, agreementLabels) {
    state.agreementsLabels = agreementLabels;
  },
  setLanguageLabels(state, languageLabels) {
    state.languageLabels = languageLabels;
  },
  setSportsLabels(state, sportsLabels) {
    state.sportsLabels = sportsLabels;
  },
  setInfrastructureLabels(state, infrastructureLabels) {
    state.infrastructureLabels = infrastructureLabels;
  },
  setSafetyLabels(state, safetyLabels) {
    state.safetyLabels = safetyLabels;
  },
  setMembersLabels(state, membersLabels) {
    state.membersLabels = membersLabels;
  },
  setLanguageLevelsLabels(state, languageLevelsLabels) {
    state.languageLevelsLabels = languageLevelsLabels;
  },
  setExtracurricularLabels(state, extracurricularLabels) {
    state.extracurricularLabels = extracurricularLabels;
  },
  setSupportLabels(state, supportLabels) {
    state.supportLabels = supportLabels;
  },
  setLocationType(state, locationType) {
    state.locationTypeLabels = locationType;
  },
  setAudiovisualLabels(state, audiovisualLabel) {
    state.audiovisualLabel = audiovisualLabel;
  },
  setTestimonialsLabels(state, testimonialsLabel) {
    state.testimonialsLabel = testimonialsLabel;
  },
  setContactLabels(state, contactLabels) {
    state.contactLabels = contactLabels;
  },
  setShiftsLabels(state, shiftsLabels) {
    state.shiftsLabels = shiftsLabels;
  },
  setModalitiesLabels(state, modalitiesLabels) {
    state.modalitiesLabels = modalitiesLabels;
  },
};

const actions = {
  resetStores({ commit }) {
    commit('resetStore');
  },
  setSectorLabels({ commit }, sectors) {
    commit('setSectorLabels', sectors);
  },
  setDisabilityOptions({ commit }, types) {
    commit('setDisabilityOptions', types);
  },
  getPridePoints({ commit }) {
    services.optionsService.retrievePridePoints().then(({ data }) => {
      commit('setPridePointLabels', data);
    });
  },
  getRelationshipTypes({ commit }) {
    services.optionsService.retrieveRelationshipTypes().then(({ data }) => {
      commit('setRelationshipOptions', data);
    });
  },
  getRegions({ commit }) {
    services.optionsService.retrieveRegions().then(({ data }) => {
      commit('setRegions', data);
    });
  },
  async getMunicipalities({ commit }) {
    await services.optionsService.retrieveMunicipalities().then(({ data }) => {
      commit('setMunicipalities', data);
    });
  },
  getProgramGenders({ commit }) {
    services.optionsService.retrieveProgramGenders().then(({ data }) => {
      commit('setProgramGenders', data);
    });
  },
  getUserGenders({ commit }) {
    services.optionsService.retrieveUserGenders().then(({ data }) => {
      commit('setUserGenders', data);
    });
  },
  getEducationalLevels({ commit }) {
    services.optionsService.retrieveEducationalLevels().then(({ data }) => {
      const { grades, stages } = data;
      commit('setGrades', grades);
      commit('setStages', stages);
    });
  },
  getPerformanceCategories({ commit }) {
    services.optionsService.retrievePerformanceCategories().then(({ data }) => {
      commit('setPerformanceCategories', data);
    });
  },
  getMonthlyPayments({ commit }) {
    services.optionsService.retrieveMonthlyPayments().then(({ data }) => {
      const dataSorted = data.sort((a, b) => a.id - b.id);
      commit('setMonthlyPayments', dataSorted);
    });
  },
  getPaymentBandLabels({ commit }) {
    services.optionsService.retrievePaymentBandLabels().then(({ data }) => {
      const dataSorted = data.sort((a, b) => a.id - b.id);
      commit('setPaymentBandLabels', dataSorted);
    });
  },
  getPaymentTypeLabels({ commit }) {
    services.optionsService.retrievePaymentTypeLabels().then(({ data }) => {
      const dataSorted = data.sort((a, b) => a.id - b.id);
      commit('setPaymentTypeLabels', dataSorted);
    });
  },
  getAgreementLabels({ commit }) {
    services.optionsService.retrieveAgreements().then(({ data }) => {
      commit('setAgreementLabels', data);
    });
  },
  getSectorLabels({ commit }) {
    services.optionsService.retrieveSectorLabels().then(({ data }) => {
      commit('setSectorLabels', data);
    });
  },
  getLanguageLabels({ commit }) {
    services.optionsService.retrieveLanguage().then(({ data }) => {
      commit('setLanguageLabels', data);
    });
  },
  getSportsLabels({ commit }) {
    services.optionsService.retrieveSports().then(({ data }) => {
      commit('setSportsLabels', data);
    });
  },
  getInfrastructureLabels({ commit }) {
    services.optionsService.retrieveInfrastructure().then(({ data }) => {
      commit('setInfrastructureLabels', data);
    });
  },
  getSafetyLabels({ commit }) {
    services.optionsService.retrieveSafety().then(({ data }) => {
      commit('setSafetyLabels', data);
    });
  },
  getMembersLabels({ commit }) {
    services.optionsService.retrieveMembers().then(({ data }) => {
      commit('setMembersLabels', data);
    });
  },
  getLanguageLevelsLabels({ commit }) {
    services.optionsService.retrieveLanguageLevels().then(({ data }) => {
      commit('setLanguageLevelsLabels', data);
    });
  },
  getExtracurricularLabels({ commit }) {
    services.optionsService.retrieveExtracurriculares().then(({ data }) => {
      commit('setExtracurricularLabels', data);
    });
  },
  getSupportLabels({ commit }) {
    services.optionsService.retrieveSupport().then(({ data }) => {
      commit('setSupportLabels', data);
    });
  },
  getSpecialties({ commit }) {
    services.optionsService.retrieveSpecialities().then(({ data }) => {
      commit('setSpecialties', data);
    });
  },
  getAudiovisualLabels({ commit }) {
    services.optionsService.retrieveAudiovisual().then(({ data }) => {
      const filteredTestimonials = data?.filter(({ id }) => id > 5 && id !== 99) ?? [];
      const filteredAudiovisualLabel = (
        data?.filter(({ id }) => id === 1 || id === 2 || id === 4) ?? []
      ).sort((a, b) => a.id - b.id);
      commit('setAudiovisualLabels', filteredAudiovisualLabel);
      commit('setTestimonialsLabels', filteredTestimonials);
    });
  },
  getNationalities({ commit }) {
    services.optionsService.retrieveNatonialitys().then(({ data }) => {
      commit('setNationalities', data);
    });
  },
  getNationalIdentifications({ commit }) {
    services.optionsService.retrieveNationalidentification().then(({ data }) => {
      commit('setNationalIdentification', data);
    });
  },
  getDocumentTypes({ commit }) {
    services.optionsService.retriveDocumentTypes().then(({ data }) => {
      const documents = {};
      data.forEach((elem) => {
        if (Object.prototype.hasOwnProperty.call(documents, elem.nationality.id)) {
          documents[elem.nationality.id].push(elem);
        } else {
          documents[elem.nationality.id] = [elem];
        }
      });
      commit('setDocumentTypes', documents);
    });
  },
  getDisabilities({ commit }) {
    services.optionsService.retrieveDisabilities().then(({ data }) => {
      commit('setDisabilityOptions', data);
    });
  },
  getLocationTypesLabels({ commit }) {
    services.optionsService.retrieveLocationType().then(({ data }) => {
      commit('setLocationType', data);
    });
  },
  getRoadTypes({ commit }) {
    services.optionsService.retrieveRoadType().then(({ data }) => {
      commit('setRoadType', data);
    });
  },
  getArmedConflictTypes({ commit }) {
    services.optionsService.retrieveArmedConflictTypes().then(({ data }) => {
      commit('setArmedConflictTypes', data);
    });
  },
  getSisbenGroups({ commit }) {
    services.optionsService.retrieveSisbenGroup().then(({ data }) => {
      commit('setSisbenGroups', data);
    });
  },
  getPartnerships({ commit }) {
    services.optionsService.retrievePartnership().then(({ data }) => {
      commit('setPartnerships', data);
    });
  },
  getNativeLanguageLabels({ commit }) {
    services.optionsService.retrieveNativeLanguages().then(({ data }) => {
      commit('setNativeLanguage', data);
    });
  },
  getContactLabels({ commit }) {
    services.optionsService.retrieveContacts().then(({ data }) => {
      commit('setContactLabels', data);
    });
  },
  getShiftsLabels({ commit }) {
    services.optionsService.retrieveShifts().then(({ data }) => {
      commit('setShiftsLabels', data);
    });
  },

  getModalitiesLabels({ commit }) {
    services.optionsService.retrieveModalities().then(({ data }) => {
      commit('setModalitiesLabels', data);
    });
  },
  retrieveOptions({ dispatch, rootGetters }, { reset = false }) {
    const hasPridePoints = rootGetters['options/pridePointLabels'].length > 0;
    const hasRelationshipTypes = rootGetters['options/relationshipOptions'].length > 0;
    const hasRegions = rootGetters['options/regions'].length > 0;
    const hasMunicipalities = rootGetters['options/municipalities'].length > 0;
    const hasProgramGenders = rootGetters['options/programGenders'].length > 0;
    const hasUserGenders = rootGetters['options/userGenders'].length > 0;
    const hasEducationalLevels = !!(rootGetters['options/stages'].length && rootGetters['options/grades'].length);
    const hasPerformanceCategories = rootGetters['options/performanceCategories'].length > 0;
    const hasMonthlyPayments = rootGetters['options/monthlyPayments'].length > 0;
    const hasPaymentBandLabels = rootGetters['options/paymentBandLabels'].length > 0;
    const hasPaymentTypeLabels = rootGetters['options/paymentTypeLabels'].length > 0;
    const hasAgreementsLables = rootGetters['options/agreementsLabels'].length > 0;
    const hasSectorLabels = rootGetters['options/sectorLabels'].length > 0;
    const hasLanguageLabels = rootGetters['options/languageLabels'].length > 0;
    const hasSportsLabels = rootGetters['options/sportsLabels'].length > 0;
    const hasInfrastructureLabels = rootGetters['options/infrastructureLabels'].length > 0;
    const hasSafetyLabels = rootGetters['options/safetyLabels'].length > 0;
    const hasMembersLabels = rootGetters['options/membersLabels'].length > 0;
    const hasLanguageLevelsLabels = rootGetters['options/languageLevelsLabels'].length > 0;
    const hasExtracurricularLabels = rootGetters['options/extracurricularLabels'].length > 0;
    const hasSupportLabels = rootGetters['options/supportLabels'].length > 0;
    const hasSpecialties = rootGetters['options/specialties'].length > 0;
    const hasAudiovisualLabels = rootGetters['options/audiovisualLabel'].length > 0;
    const hasNationalities = rootGetters['options/nationalities'].length > 0;
    const hasNationalIdentifications = rootGetters['options/nationalIdentifications'].length > 0;
    const hasDocumentTypes = Object.keys(rootGetters['options/documentTypes']).length > 0;
    const hasDisabilities = rootGetters['options/disabilities'].length > 0;
    const hasLocationTypesLabels = rootGetters['options/locationTypesLabels'].length > 0;
    const hasPartnershipsLabels = rootGetters['options/partnershipLabels'].length > 0;
    const hasNativeLanguageLabels = rootGetters['options/nativeLanguageLabels'].length > 0;
    const hasContactLabels = rootGetters['options/contactLabels'].length > 0;
    const hasShiftsLabels = rootGetters['options/shiftsLabels'].length > 0;
    const hasModalitiesLabels = rootGetters['options/modalitiesLabels'].length > 0;
    const locationFromUrl = rootGetters['institutions/locationFromUrl'];
    if (reset) {
      dispatch('getPridePoints');
      dispatch('getRelationshipTypes');
      dispatch('getRegions');
      dispatch('getMunicipalities');
      dispatch('getProgramGenders');
      dispatch('getUserGenders');
      dispatch('getGradeTracks');
      dispatch('getPerformanceCategories');
      dispatch('getMonthlyPayments');
      dispatch('getPaymentBandLabels');
      dispatch('getPaymentTypeLabels');
      dispatch('getAgreementLabels');
      dispatch('getSectorLabels');
      dispatch('getLanguageLabels');
      dispatch('getSportsLabels');
      dispatch('getInfrastructureLabels');
      dispatch('getSafetyLabels');
      dispatch('getMembersLabels');
      dispatch('getEducationalLevels');
      dispatch('getLanguageLevelsLabels');
      dispatch('getExtracurricularLabels');
      dispatch('getSupportLabels');
      dispatch('getSpecialties');
      dispatch('getAudiovisualLabels');
      dispatch('getNationalities');
      dispatch('getNationalIdentifications');
      dispatch('getDocumentTypes');
      dispatch('getDisabilities');
      dispatch('getLocationTypesLabels');
      dispatch('getPartnerships');
      dispatch('getNativeLanguageLabels');
      dispatch('getContactLabels');
      dispatch('getShiftsLabels');
      dispatch('getModalitiesLabels');
    } else {
      if (!hasPridePoints) {
        dispatch('getPridePoints');
      }
      if (!hasRelationshipTypes) {
        dispatch('getRelationshipTypes');
      }
      if (!hasRegions) {
        dispatch('getRegions');
      }
      if (!hasMunicipalities && !locationFromUrl) {
        dispatch('getMunicipalities');
      }
      if (!hasProgramGenders) {
        dispatch('getProgramGenders');
      }
      if (!hasUserGenders) {
        dispatch('getUserGenders');
      }
      if (!hasEducationalLevels) {
        dispatch('getEducationalLevels');
      }
      if (!hasPerformanceCategories) {
        dispatch('getPerformanceCategories');
      }
      if (!hasMonthlyPayments) {
        dispatch('getMonthlyPayments');
      }
      if (!hasPaymentBandLabels) {
        dispatch('getPaymentBandLabels');
      }
      if (!hasPaymentTypeLabels) {
        dispatch('getPaymentTypeLabels');
      }
      if (!hasAgreementsLables) {
        dispatch('getAgreementLabels');
      }
      if (!hasSectorLabels) {
        dispatch('getSectorLabels');
      }
      if (!hasLanguageLabels) {
        dispatch('getLanguageLabels');
      }
      if (!hasSportsLabels) {
        dispatch('getSportsLabels');
      }
      if (!hasInfrastructureLabels) {
        dispatch('getInfrastructureLabels');
      }
      if (!hasSafetyLabels) {
        dispatch('getSafetyLabels');
      }
      if (!hasMembersLabels) {
        dispatch('getMembersLabels');
      }
      if (!hasLanguageLevelsLabels) {
        dispatch('getLanguageLevelsLabels');
      }
      if (!hasExtracurricularLabels) {
        dispatch('getExtracurricularLabels');
      }
      if (!hasSupportLabels) {
        dispatch('getSupportLabels');
      }
      if (!hasSpecialties) {
        dispatch('getSpecialties');
      }
      if (!hasAudiovisualLabels) {
        dispatch('getAudiovisualLabels');
      }
      if (!hasNationalities) {
        dispatch('getNationalities');
      }
      if (!hasNationalIdentifications) {
        dispatch('getNationalIdentifications');
      }
      if (!hasDocumentTypes) {
        dispatch('getDocumentTypes');
      }
      if (!hasDisabilities) {
        dispatch('getDisabilities');
      }
      if (!hasLocationTypesLabels) {
        dispatch('getLocationTypesLabels');
      }
      if (!hasPartnershipsLabels) {
        dispatch('getPartnerships');
      }
      if (!hasContactLabels) {
        dispatch('getContactLabels');
      }
      if (!hasShiftsLabels) {
        dispatch('getShiftsLabels');
      }
      if (!hasModalitiesLabels) {
        dispatch('getModalitiesLabels');
      }
      if (['colombia', 'palmira'].includes(CONFIG.tenant)) {
        const hasRoadTypes = rootGetters['options/roadTypes'].length > 0;
        const hasArmedConflictTypes = rootGetters['options/armedConflictTypes'].length > 0;
        const hasSisbenGroups = rootGetters['options/sisbenGroups'].length > 0;

        if (!hasRoadTypes) {
          dispatch('getRoadTypes');
        }

        if (!hasArmedConflictTypes) {
          dispatch('getArmedConflictTypes');
        }

        if (!hasSisbenGroups) {
          dispatch('getSisbenGroups');
        }
      }
      if (CONFIG.tenant === 'newhaven' && !hasNativeLanguageLabels) {
        dispatch('getNativeLanguageLabels');
      }
    }
  },
  setActualVideo({ commit }, actualVideo) {
    commit('setActualVideo', actualVideo);
  },
  stopActualVideo({ state }) {
    state.actualVideo?.stopVideo();
  },
  async getModalitiesByGrade({ }, { gradeId, stageId }) {
    const modalitiesList = await services.optionsService
      .retrieveModalitiesByGrade({ gradeId, stageId })
      .then((response) => response.data)
      .catch(() => []);
    return modalitiesList;
  },
  async getSpecialtiesByGrade({ }, { gradeId, stageId, modalityId }) {
    const specialtiesList = await services.optionsService
      .retrieveSpecialtiesByGrade({ gradeId, stageId, modalityId })
      .then((response) => response.data)
      .catch(() => []);
    return specialtiesList;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
