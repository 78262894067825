var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main"},[_c('p',{directives:[{name:"t",rawName:"v-t",value:(_vm.setText),expression:"setText"}],staticClass:"register-main--small-title",class:{
      'register-main--small-title--left': _vm.centerLeft,
      'register-main--small-title--primary': _vm.primary,
      'register-main--small-title--neutral': _vm.neutral,
      'register-main--small-title--tiny': _vm.tiny,
      'ma-0': _vm.noMargin,
    },style:('margin-top: 16px')})])
}
var staticRenderFns = []

export { render, staticRenderFns }