var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register-main register-main--spaced"},[_c('div',{staticClass:"fill-width"},[(!_vm.inSimulation || _vm.schoolOnly)?_c('ModalHeader',{attrs:{"content-sections":['actions', 'logo'],"inline-logo":"","small-logo":""},on:{"back":function($event){return _vm.$emit('backStep')}}}):_vm._e(),(!_vm.inSimulation || _vm.schoolOnly)?_c('RowStepper',{attrs:{"step":3}}):_vm._e(),_c('ModalContent',{attrs:{"content-sections":(_vm.inSimulation || _vm.schoolOnly)
        ? ['subtitle', 'selectionButton'] : ['smallTitle', 'subtitle', 'selectionButton'],"small-title-text":'user_register.additional_info.title',"small-title-left":"","subtitle-text":_vm.isGuest && _vm.$route.name === 'Simulate'
        ? 'user_register.brother_in_campus.subtitle_guest'
        : 'user_register.brother_in_campus.subtitle',"subtitle-arg":Object.keys(_vm.currentStudent).length > 0 ? _vm.currentStudent.first_name : '',"selection-button-info":_vm.selectionButtonInfo},on:{"setSelectionButtonValue":_vm.setButtonsValue}}),(_vm.currentSchoolToSet)?_c('section',{staticStyle:{"margin-top":"30px"}},_vm._l((_vm.numberOfBrothers),function(index){return _c('div',{key:index},[((!_vm.inSimulation && !_vm.schoolOnly))?_c('ModalContent',{attrs:{"content-sections":['subtitle', 'autoComplete'],"subtitle-text":'user_register.brother_in_campus.nationality',"auto-complete-placeholder":'user_register.basic_info.nationality_placeholder',"auto-complete-items":_vm.setNationalityArray(),"autocomplete-no-info-error":_vm.noInfoError && _vm.nationality.length === 0,"autocomplete-index":index,"autocomplete-initial-value":_vm.defaultStudent.length >= index ? _vm.defaultStudent[index - 1].nationalityStudent : ''},on:{"setAutoComplete":_vm.setNationality}}):_vm._e(),_c('ModalContent',{attrs:{"content-sections":(_vm.inSimulation || _vm.schoolOnly) ? ['subtitle'] : ['subtitle', 'autoComplete'],"subtitle-text":'user_register.campus_selection.which_one',"auto-complete-placeholder":'user_register.register_location.department_placeholder',"auto-complete-items":_vm.regionLabels,"autocomplete-no-info-error":_vm.noInfoError && _vm.departments.length === 0,"autocomplete-index":index,"autocomplete-initial-value":_vm.defaultStudent.length >= index ? _vm.defaultStudent[index - 1].department : ''},on:{"setAutoComplete":_vm.setDepartment}}),_vm._l((2),function(i){return _c('div',{key:i},[_c('ModalContent',{staticStyle:{"margin":"-4px 0"},attrs:{"content-sections":['autoComplete'],"auto-complete-placeholder":i === 1
                ? 'user_register.register_location.commune_placeholder'
                : 'user_register.campus_selection.placeholder',"auto-complete-items":i === 1 ? _vm.municipalityLabels : _vm.communeInstitutionListSorted,"disabled-autocomplete-value":i === 1 ? false : (_vm.communeInstitutionList.length === 0 && !_vm.preFill),"autocomplete-no-info-error":_vm.noInfoError && _vm.communes.length === 0,"autocomplete-index":index,"autocomplete-reset-value":_vm.setResetValue(index),"autocomplete-initial-value":_vm.getInstitutionSibling(index, i)},on:{"setAutoComplete":function($event){i === 1 ? _vm.setMuncipality($event, index, i) : _vm.setSchools($event, index, i)}}})],1)}),(false)?_c('section',{class:_vm.inSimulation ? 'd-flex' : ''},[_c('ModalContent',{attrs:{"content-sections":_vm.getModalContent(),"subtitle-text":(_vm.inSimulation || _vm.schoolOnly)
              ? 'user_register.basic_info.brother_question' : 'user_register.campus_selection.idTitle',"text-select-items":_vm.setTypeOfDocuments(),"text-select-placeholders":'user_register.basic_info.document_type_placeholder',"text-select-index":index - 1,"input-section":_vm.defaultStudent.length > 0
                ? _vm.defaultStudent[index - 1].inputContent.firstName
                : _vm.setInputContent(1),"text-select-initial-value":_vm.defaultStudent.length >= index ? _vm.defaultStudent[index - 1].docTypeStudent : ''},on:{"setTextSelectValue":_vm.setTextValue,"setValue":function($event){return _vm.setInputValue($event, index)}}}),((_vm.inSimulation || _vm.schoolOnly))?_c('ModalContent',{style:(!_vm.schoolOnly && !_vm.mobile ? 'margin-top: 70px; margin-left: 22px;' : ''),attrs:{"content-sections":['registerInput'],"input-section":_vm.defaultStudent.length > 0
                ? _vm.defaultStudent[index - 1].inputContent.lastName
                : _vm.setInputContent(2),"text-select-initial-value":_vm.defaultStudent.length >= index ? _vm.defaultStudent[index - 1].docTypeStudent : ''},on:{"setTextSelectValue":_vm.setTextValue,"setValue":function($event){return _vm.setInputValue($event, index, true)}}}):_vm._e()],1):_vm._e(),_c('ModalContent',{attrs:{"content-sections":['subtitle', 'selectionButton'],"subtitle-text":_vm.isGuest && _vm.$route.name === 'Simulate'
            ? 'user_register.brother_in_campus.another_brother_guest'
            : 'user_register.brother_in_campus.another_brother',"subtitle-arg":Object.keys(_vm.currentStudent).length > 0 ? _vm.currentStudent.first_name : '',"selection-button-info":_vm.setSelectionButtonInfo(index)},on:{"setSelectionButtonValue":_vm.setButtonsValue2}})],2)}),0):_vm._e()],1),_c('section',{staticClass:"d-flex align-center justify-end fill-width"},[(_vm.inSimulation)?_c('CallToActions',{staticStyle:{"margin-top":"40px","margin-right":"8px"},attrs:{"types":['main'],"active-value":(!_vm.inSimulation || !_vm.schoolOnly) ? _vm.isActive : false,"main-button-text":(_vm.inSimulation || _vm.schoolOnly)
        ? 'user_register.brother_in_campus.cancel' : 'user_register.continue',"small":"","main-button-tiny":_vm.mobile,"spaced":false,"main-active-light":(_vm.inSimulation || _vm.schoolOnly)},on:{"continue":function($event){(_vm.inSimulation || _vm.schoolOnly) ? _vm.$emit('backStep') : _vm.nextStep()}}}):_vm._e(),((_vm.inSimulation || _vm.schoolOnly))?_c('CallToActions',{staticStyle:{"margin-top":"40px","margin-left":"8px"},attrs:{"types":['main'],"active-value":_vm.isActive,"main-button-text":'user_register.brother_in_campus.confirm',"small":"","main-button-tiny":_vm.mobile,"width-stretch":"","spaced":false},on:{"continue":function($event){return _vm.nextStep()}}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }