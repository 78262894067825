<template>
  <div
    v-if="!loading"
    class="user_register_container"
    :class="{
      'user_register_container--no-borders': inStep(11),
      'user_register_container--center': inStep(1, 13),
    }"
  >
    <PasswordRecovery v-if="inStep(-1)" @toRegister="toStep(2)" @backStep="backStep" />
    <LoginModal
      v-if="inStep(0)"
      @backStep="backStep"
      @nextStep="loginFollowUp"
      @toRecovery="toStep(-1)"
      @toRegister="toStep(2)"
      @toVerification="toStep(3)"
      @skipVerification="toStep(5)"
      @toAccountLinking="toStep(25)"
    />
    <LandingModal v-if="inStep(1)" @nextStep="nextStep" @toLogin="toStep(0)" />
    <RegisterData
      v-if="inStep(2)"
      @nextStep="nextStep"
      @backStep="backStep"
      @closeModal="closeModals"
      @setLocationModal="toStep(3)"
      @toResetPass="toStep(43)"
      @skipVerification="toStep(5)"
      @toAccountLinking="toStep(25)"
    />
    <RegisterCode v-if="inStep(3)" @nextStep="registerCodeFollowUp" @backStep="backStep" />
    <RegisterType
      v-if="inStep(4)"
      @nextStep="nextStep"
      @toLogin="restartProcess(0)"
      @toRegister="restartProcess(2)"
      @setRegisterType="setRegisterType"
    />
    <StepsModal
      v-if="inStep(5, 16)"
      :in-student-flow="inStudentFlow"
      :register-type="registerType"
      @nextStep="nextStep"
      @backStep="inDigitalEnrollment ? restartProcess(2) : backStep()"
      @toMap="toMap"
    />
    <Identification
      v-if="inStep(6, 17)"
      :pre-fill="inDataValidationFlow"
      :pre-fill-data="currentUserToEdit"
      :in-student-flow="inStudentFlow"
      :is-new-student="inStudentFlow && initializingStudent"
      :in-detailed-register="inDetailedRegister"
      @nextStep="identificationFollowUp"
      @backStep="identificationBackStep"
    />
    <DateOfBirth
      v-if="inStep(7)"
      :pre-fill="inDataValidationFlow"
      :pre-fill-data="currentUserToEdit"
      :in-student-flow="inStudentFlow"
      :register-type="registerType"
      @nextStep="dateOfBirthFollowUp"
      @backStep="backStep"
    />
    <Nationality
      v-if="inStep(8)"
      :pre-fill="inDataValidationFlow"
      :pre-fill-data="currentUserToEdit"
      :in-student-flow="inStudentFlow"
      @nextStep="toStep(inStudentFlow ? 45 : 9)"
      @backStep="backStep"
    />
    <Contact
      v-if="inStep(9)"
      :pre-fill="inDataValidationFlow"
      :pre-fill-data="contactPreferences"
      :register-type="registerType"
      @nextStep="contactFollowUp"
      @backStep="backStep"
    />
    <Location
      v-if="inStep(10)"
      :location-for="inStudentFlow ? 'student' : ''"
      :in-student-flow="inStudentFlow"
      :register-type="registerType"
      :selected-option="selectedOp"
      in-register
      @backStep="backStep"
      @toFinale="toStep(13)"
      @setUserLocation="setUserLocation"
      @setCommune="setCommune"
    />
    <SearchLocation
      v-if="inStep(11)"
      :in-student-flow="inStudentFlow"
      :commune="selectedCommune"
      :user-location="userLocation"
      :location-for="inStudentFlow ? 'student' : ''"
      style="position: relative"
      @nextStep="nextStep"
      @backStep="backStep"
      @setUserLocation="setUserLocation"
    />
    <LocationType
      v-if="inStep(12)"
      :pre-fill="inDataValidationFlow"
      :pre-fill-data="currentUserToEdit"
      :in-student-flow="inStudentFlow"
      :register-type="registerType"
      @nextStep="locationTypeFollowUp"
      @backStep="backStep"
    />
    <RegisterFinalModal
      v-if="inStep(13)"
      :register-type="registerType"
      @nextStep="nextStep"
      @closeModal="closeModals"
    />
    <RolesModal
      v-if="inStep(14)"
      :register-type="registerType"
      @nextStep="setRolesModalFlow()"
      @backStep="backStep"
      @setInterview="toStep(26)"
      @askForStudent="setStudentFlow(true)"
      @endRegistration="toStep(23)"
    />
    <!--
      FIXME: Gender component only handles student gender
             According to design, LegalGuardians should also have gender
    -->
    <RegisterGender
      v-if="inStep(18)"
      :pre-fill="inDataValidationFlow"
      :pre-fill-data="currentUserToEdit"
      @nextStep="toStep(7)"
      @backStep="backStep"
    />
    <LocationStudent
      v-if="inStep(19)"
      @nextStep="toStep(12)"
      @backStep="backStep"
      @toLocationFlow="toStep(10)"
    />
    <AdditionalInfoInstitution
      v-if="inStep(20)"
      @nextStep="nextStep"
      @backStep="backStep"
      @setFinalModal="toStep(24)"
    />
    <AdditionalInfoYear
      v-if="inStep(21)"
      @nextStep="inDigitalEnrollment ? yearFollowUp() : toStep(30)"
      @backStep="backStep"
    />
    <SelectGrades
      v-if="inStep(22)"
      :in-student-flow="inStudentFlow"
      user-register
      @nextStep="toStep(inStudentFlow ? inDigitalEnrollment ? 46 : 24 : 23)"
      @backStep="backStep"
    />
    <RolesFinal
      v-if="inStep(23)"
      :register-type="registerType"
      @backStep="backStep"
    />
    <LinkAccount
      v-if="inStep(25)"
      @backStep="backStep"
      @closeModal="closeModals"
      @resetPassword="resetPassword"
    />
    <HeadmasterInfo
      v-if="inStep(26)"
      :register-type="registerType"
      @nextStep="nextStep"
      @backStep="backStep"
    />
    <RegisterCalendar
      v-if="inStep(27)"
      :register-type="registerType"
      :calendar-url="headmasterCalendarUrl"
      @backStep="backStep"
    />
    <RelationshipModal
      v-if="inStep(28)"
      :pre-fill="inDataValidationFlow"
      :pre-fill-data="currentUserToEdit"
      :register-type="registerType"
      @backStep="backStep"
      @nextStep="toStep(inDetailedRegister ? inDigitalEnrollment ? nextStepRelationship() : 18 : 7)"
    />
    <GradeSuggestion
      v-if="inStep(30)"
      @nextStep="gradeSuggestionFollowup"
      @backStep="backStep"
      @toGradeSelection="({ skip = false }) => toStep(22, { skipCurrent: skip })"
    />
    <PriorityQuestion
      v-if="inStep(47)"
      @nextStep="nextStep"
      @toFinale="toStep(24)"
      @backStep="backStep"
    />
    <LanguagePriorities
      v-if="inStep(48)"
      :pre-fill="stepCompleted.includes(48)"
      @nextStep="toStep(32);"
      @backStep="backStep"
    />
    <CampusSelection
      v-if="inStep(32)"
      @toFinale="toStep(24)"
      @backStep="backStep"
      @nextStep="nextStep"
    />
    <BrotherInCampus
      v-if="inStep(33)"
      :pre-fill="stepCompleted.includes(33)"
      school-only
      @backStep="backStep"
      @nextStep="toStep(24)"
    />
    <StudentsFinal
      v-if="inStep(24)"
      :register-type="registerType"
      @nextStep="studentsFinalFollowUp"
      @backStep="inDigitalEnrollment ? restartProcess(2) : backStep()"
      @toMap="toMap"
    />
    <!--
    <PlaceOfBirth @backStep="backStep" v-if="inStep(29)" @nextStep="toStep(19)" />
    <PriorityQuestion
      v-if="inStep(31)"
      @backStep="backStep"
      @nextStep="toStep(33)"
      @toFinale="toStep(24)"
    />
    <SisbenPriority
      v-if="inStep(34)"
      @toIcbfInfo="toStep(36)"
      @backStep="backStep"
      @nextStep="nextStep"
    />
    <PreSchoolPriority v-if="inStep(35)" @backStep="backStep" @nextStep="toStep(37)" />
    <IcbfInfoModal v-if="inStep(36)" @backStep="backStep" @nextStep="nextStep" />
    <ArmedConflict v-if="inStep(37)" @backStep="backStep" @nextStep="nextStep" />
    <Disability v-if="inStep(38)" @nextStep="toStep(24)" @backStep="backStep" />
    <EducationalLevel v-if="inStep(39)" @backStep="backStep" />
    <MaritalStatus v-if="inStep(40)" @backStep="backStep" />
    <Ethnicity v-if="inStep(41)" @nextStep="toStep(8)" @backStep="backStep" />
    -->
    <RegisterRedirect v-if="inStep(42)" />
    <RegisterPasswordRecovery v-if="inStep(43)" @backStep="backStep" />
    <StudentSelection
      v-if="inStep(44)"
      @nextStep="studentSelectionFollowUp"
      @toNewStudentRegister="toNewStudentRegister"
      @backStep="backStep"
    />
    <ConfirmLocation
      v-if="inStep(45)"
      :in-student-flow="inStudentFlow"
      @nextStep="inDetailedRegister ? toStep(20) : toStep(24)"
      @backStep="backStep"
      @toLocationFlow="toStep(10)"
    />
    <ShiftPriority
      v-if="inStep(46)"
      :pre-fill="inDataValidationFlow"
      :pre-fill-data="currentUserToEdit"
      use-defaults
      @nextStep="toStep(24)"
      @backStep="backStep"
    />
    <SvgLogo
      v-if="(currentStep > 5 && currentStep < 31) && inDigitalEnrollment"
      class="clickable"
      :logo="'help-icon.svg'"
      left
      no-margin
      alt="help icon"
      @logoClick="helpModal = true"
    />
    <v-dialog v-model="helpModal" max-width="350">
      <HelpInformation :class="mobile ? '' : 'help-information--dge' " @close="helpModal = false" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AdditionalInfoInstitution from '@/components/organisms/register/AdditionalInfoInstitution.vue';
import AdditionalInfoYear from '@/components/organisms/register/AdditionalInfoYear.vue';
import BrotherInCampus from '@/components/organisms/register/BrotherInCampus.vue';
import RegisterCalendar from '@/components/organisms/register/CalendarModal.vue';
import CampusSelection from '@/components/organisms/register/CampusSelection.vue';
import ConfirmLocation from '@/components/organisms/register/ConfirmLocation.vue';
import Contact from '@/components/organisms/register/Contact.vue';
import DateOfBirth from '@/components/organisms/register/DateOfBirth.vue';
import GradeSuggestion from '@/components/organisms/register/GradeSuggestion.vue';
import HeadmasterInfo from '@/components/organisms/register/HeadmasterInfo.vue';
import Identification from '@/components/organisms/register/Identification.vue';
import LandingModal from '@/components/organisms/register/LandingModal.vue';
import LanguagePriorities from '@/components/organisms/register/LanguagePriorities.vue';
import LinkAccount from '@/components/organisms/register/LinkAccount.vue';
import Location from '@/components/organisms/register/Location.vue';
import LocationStudent from '@/components/organisms/register/LocationStudent.vue';
import LocationType from '@/components/organisms/register/LocationType.vue';
import LoginModal from '@/components/organisms/register/LoginModal.vue';
import Nationality from '@/components/organisms/register/Nationality.vue';
import PasswordRecovery from '@/components/organisms/register/PasswordRecovery.vue';
import PriorityQuestion from '@/components/organisms/register/PriorityQuestion.vue';
import RegisterCode from '@/components/organisms/register/RegisterCode.vue';
import RegisterData from '@/components/organisms/register/RegisterData.vue';
import RegisterFinalModal from '@/components/organisms/register/RegisterFinalModal.vue';
import RegisterGender from '@/components/organisms/register/RegisterGender.vue';
import RegisterRedirect from '@/components/organisms/register/RegisterRedirect.vue';
import RegisterType from '@/components/organisms/register/RegisterType.vue';
import RelationshipModal from '@/components/organisms/register/RelationshipModal.vue';
import RolesFinal from '@/components/organisms/register/RolesFinal.vue';
import RolesModal from '@/components/organisms/register/RolesModal.vue';
import ShiftPriority from '@/components/organisms/register/ShiftPriority.vue';
import StepsModal from '@/components/organisms/register/StepsModal.vue';
import StudentSelection from '@/components/organisms/register/StudentSelection.vue';
import StudentsFinal from '@/components/organisms/register/StudentsFinal.vue';
import SearchLocation from '@/components/shared/SearchLocation.vue';
import SelectGrades from '@/components/shared/SelectGrades.vue';
// import PriorityQuestion from '@/components/organisms/register/PriorityQuestion.vue';
import SvgLogo from '@/components/atoms/logos/SvgLogo.vue';
import HelpInformation from '@/components/organisms/digital_enrollment/HelpInformation.vue';
import { LABELS } from '@/constants/explorer/labels';

// For documentation purposes, these are the normal registration workflows (main cases)
// Fast Registration (LG):      [1,2,3,4,5,6,9,10,11,13,14,22,23]
// Detailed Registration (LG):  [1,2,3,4,5,6,7,8,9,10,11,12,13,14,16,17,28,18,7,45,20,21,30,45,47,48,32,33,24]
// Fast Registration (DIR):     [1,2,3,4,5,6,9,10,11,13,14,26,27]
// Detailed Registration (DIR): [1,2,3,4,5,6,7,8,9,10,11,12,13,14,26,27]
// Digital Enrollment:          [1,2,3,9,2,28,7,30,46,24]
/**
 * DGE Expected flow
 * 1. Welcome
 * 2. Login
 * [ LEGAL GUARDIAN FLOW ]
 * 3. Identification
 * 4. Contact
 * [ STUDENT FLOW ]
 * 5. Information
 * 6. Relationship *
 * 7. DateOfBirth
 * 8. Grade suggestion
 * 9. shift priorities
 * 10. redirect modal
 * [ REDIRECT ]
 */
export default {
  name: 'UserRegister',
  components: {
    RegisterData,
    RegisterCode,
    SelectGrades,
    RegisterFinalModal,
    LinkAccount,
    LandingModal,
    LoginModal,
    PasswordRecovery,
    RegisterType,
    StepsModal,
    Identification,
    Contact,
    Location,
    SearchLocation,
    RolesModal,
    RolesFinal,
    DateOfBirth,
    RegisterGender,
    LocationType,
    LocationStudent,
    Nationality,
    AdditionalInfoInstitution,
    AdditionalInfoYear,
    StudentsFinal,
    HeadmasterInfo,
    RegisterCalendar,
    RelationshipModal,
    RegisterRedirect,
    GradeSuggestion,
    PriorityQuestion,
    ShiftPriority,
    LanguagePriorities,
    CampusSelection,
    BrotherInCampus,
    /*
    PlaceOfBirth,
    SisbenPriority,
    IcbfInfoModal,
    PreSchoolPriority,
    ArmedConflict,
    Disability,
    EducationalLevel,
    MaritalStatus,
    Ethnicity,
    RegisterPasswordRecovery,
    */
    StudentSelection,
    ConfirmLocation,
    SvgLogo,
    HelpInformation,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      // Navigation related variables
      stepStack: [1],
      inStudentFlow: false,
      inStudentFlowToggleStep: null,
      initializingStudent: true,
      registerType: 1,
      legalGuardianModal: false,

      // Modal related variables
      selectedCommune: null,
      userLocation: null,
      headmasterCalendarUrl: '//calendly.com/digital-enrollment/headmaster-interview?month=2022-07?hide_landing_page_details=1&hide_gdpr_banner=1&text_color=1a0c4c&primary_color=1a0c4c" style="min-width:360px;height:630px;',
      // Profile completion related variables
      inDataValidationFlow: false, // Activates preFill and other data validation related features
      selectedOp: 1,
      stepCompleted: [],
      helpModal: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'loading/loading',
      forcedStep: 'userRegister/forcedStep',
      inDigitalEnrollment: 'digitalEnrollment/inDigitalEnrollment',
      externalLoginInfo: 'authentication/externalLoginInfo',
      digitalEnrollmentUserType: 'digitalEnrollment/userType',
      contactPreferences: 'userRegister/contactPreferences',
      currentStudent: 'authentication/currentStudent',
      legalGuardian: 'authentication/legalGuardian',
      legalGuardianAddress: 'authentication/legalGuardianAddress',
      isHeadMaster: 'roles/isHeadMaster',
      isAmbassador: 'roles/isAmbassador',
      isGuest: 'authentication/isGuest',
      digitalEnrollmentTenant: 'digitalEnrollment/tenant',
      yearToApply: 'digitalEnrollment/yearToApply',
    }),
    currentUserToEdit() {
      return this.inStudentFlow ? this.currentStudent : this.legalGuardian;
    },
    currentStep() {
      return this.stepStack[this.stepStack.length - 1];
    },
    inDetailedRegister() {
      return this.registerType === 2;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  watch: {
    step: {
      handler(newVal) {
        this.restartProcess(newVal);
      },
    },
  },
  mounted() {
    this.checkLabels({ keysLabels: [LABELS.GENDERS, LABELS.NATIONALITIES] });
    if (
      (this.isAmbassador || this.isHeadMaster)
      && this.externalLoginInfo.origin === 'internalDgeLogin'
      && !this.isGuest) {
      this.inDataValidationFlow = true;
      this.setRegisterType(2);
      this.restartProcess(6);
    } else {
      this.restartProcess();
    }
  },
  methods: {
    ...mapActions({
      setInRegisterFlow: 'userRegister/setInRegisterFlow',
      retrieveDigitalEnrollmentTenantOptions: 'digitalEnrollment/retrieveDigitalEnrollmentTenantOptions',
      checkLabels: 'explorer/checkLabels',
    }),

    // Basic Navigation Methods
    nextStepRelationship() {
      if (this.yearToApply) {
        this.retrieveDigitalEnrollmentTenantOptions({ tenantId: this.digitalEnrollmentTenant.campus_code });
        return 22;
      }
      return 21;
    },
    nextStep() {
      if (this.inStep(0)) {
        this.$emit('inLoginFlow');
      }
      let nextStep = this.currentStep + 1;
      if (this.legalGuardianModal) {
        // Maybe this logic should be moved to it's own callback
        this.setLegalGuardianModal(false);
        nextStep = 15;
      }
      this.stepStack.push(nextStep);
    },
    toStep(step, { skipCurrent = false } = {}) {
      if (!this.stepCompleted.includes(this.currentStep)) {
        this.stepCompleted.push(this.currentStep);
      }
      // Jump to a specific step. Do not use to reset the process, use restartProcess instead.
      // If skipCurrent is true, the current step will be skipped in the historial
      if (skipCurrent) {
        const inToggleStep = this.inStudentFlowToggleStep === this.currentStep;
        this.inStudentFlowToggleStep = inToggleStep ? step : this.inStudentFlowToggleStep;
        this.stepStack = [...this.stepStack.slice(0, -1), step];
      } else {
        this.stepStack.push(step);
      }
    },
    backStep() {
      if (this.stepStack.length === 1) {
        if (this.inDigitalEnrollment) {
          this.toStep(42);
        }
        this.$emit('closeModal');
      }
      if (this.inStudentFlow && this.inStudentFlowToggleStep === this.currentStep) {
        this.inStudentFlow = false;
        this.inStudentFlowToggleStep = null;
      }
      this.stepStack.pop();
    },
    inStep(...steps) {
      return steps.includes(this.currentStep);
    },
    restartProcess(customStep) {
      if (this.forcedStep || this.forcedStep === 0) {
        this.stepStack = [1, this.forcedStep];
      } else {
        const newStep = customStep !== undefined ? customStep : this.step;
        this.stepStack = [newStep];
      }
    },
    toMap() {
      this.$router.push('/map');
    },

    // FollowUps - advanced nextStep callbacks with extra logic
    loginFollowUp() {
      if (this.inDigitalEnrollment) {
        let nextStep;
        if (this.digitalEnrollmentUserType === 'headmaster') {
          nextStep = 42; // redirect
        } else if (this.digitalEnrollmentUserType === 'legalguardian') {
          // Initialize detailed registration for LG with prefill
          this.setRegisterType(2);
          this.setDataValidationFlow(true);
          nextStep = 6; // Identification
          // nextStep = 44; // to student selection
        } else {
          nextStep = 42; // redirect
        }
        this.toStep(nextStep);
      } else {
        this.toMap();
      }
    },

    yearFollowUp() {
      this.retrieveDigitalEnrollmentTenantOptions({ tenantId: this.digitalEnrollmentTenant.campus_code });
      this.toStep(22);
    },

    registerCodeFollowUp() {
      if (this.inDigitalEnrollment) {
        // If we are in digital enrollment, we skip the step 4 as we force detailed registration
        this.setRegisterType(2);
        this.toStep(5);
      } else {
        this.nextStep();
      }
    },
    identificationFollowUp() {
      this.setStudentInitialization(!this.inStudentFlow);
      let nextStep = 9; // Contact
      if (this.inStudentFlow) {
        nextStep = 28; // Relationship -> Gender -> DOB -> Contact
      } else if (this.inDetailedRegister) {
        nextStep = 9; // Contacto
      }
      this.toStep(nextStep);
    },
    identificationBackStep() {
      if (
        (this.isAmbassador || this.isHeadMaster)
        && this.externalLoginInfo.origin === 'internalDgeLogin'
        && !this.isGuest) {
        this.$router.push('/map');
      }
      return this.backStep();
    },
    dateOfBirthFollowUp() {
      if (this.inDetailedRegister) {
        if (this.inDigitalEnrollment) {
          return this.toStep(30); // GradeSuggestion
        }
        return this.toStep(45); // Confirm Location
      }
      return this.toStep(24); // Finish Student
    },
    contactFollowUp() {
      if (!this.inDigitalEnrollment) {
        // Move into location selection or location setting
        const studentHasPotentialAddress = this.inStudentFlow
          && (this.currentStudent.address?.length || this.legalGuardianAddress?.length);
        const legalGuardianHasPotentialAddress = !this.inStudentFlow && this.legalGuardianAddress?.length;
        if (studentHasPotentialAddress || legalGuardianHasPotentialAddress) {
          this.toStep(45);
        } else {
          this.nextStep();
        }
      } else {
        this.setStudentFlow(true);
        this.toStep(44);
      }
    },
    // Flag toggling methods
    setLegalGuardianModal(value) {
      this.inStudentFlow = value;
      this.legalGuardianModal = value;
    },
    // Information setting methods
    locationTypeFollowUp() {
      let nextStep;
      if (this.inStudentFlow) {
        if (this.inDetailedRegister) {
          // If we are in detailed register, we ask grade selection
          nextStep = 20;
        } else {
          // Otherwise just finish the student registration
          nextStep = 24;
        }
      } else if (this.inDigitalEnrollment) {
        // If DGE, we skip to the corresponding step
        // 26 (get appointment) for headmasters, 44 (add student) for legal guardians
        nextStep = this.digitalEnrollmentUserType === 'headmaster' ? 26 : 44;
      } else {
        nextStep = 13;
      }
      this.toStep(nextStep);
    },

    studentsFinalFollowUp() {
      if (this.inDigitalEnrollment) {
        // Redirect to DGE
        this.toStep(42);
      } else {
        // New student flow
        this.toNewStudentRegister();
      }
    },

    studentSelectionFollowUp() {
      // FIXME: studentSelectionFollowUp logic
      // Message to the user that the student data needs to be checked
      // Enable the following redirect to the DGE if student is complete
      // this.toStep(42);
      this.setStudentInitialization(false);
      this.setStudentFlow(true);
      this.toStep(17);
    },

    gradeSuggestionFollowup() {
      let nextStep = 24; // Finish student registration
      if (this.inDetailedRegister) {
        nextStep = 47;
      }
      if (this.inDigitalEnrollment) {
        nextStep = 46; // Shift Priority
      }
      this.toStep(nextStep);
    },

    // Other navigation methods used in callbacks different from nextStep
    toNewStudentRegister({ skipCurrent = false } = {}) {
      this.setStudentFlow(true);
      this.setStudentInitialization(true);
      if (this.inDigitalEnrollment) {
        // Make sure we are in detailed registration if we are in digital enrollment
        this.setRegisterType(2);
      }
      this.toStep(17, { skipCurrent });
    },
    setRolesModalFlow() {
      if (this.inStudentFlow && !this.inDetailedRegister) {
        return this.toStep(17);
      }
      if (this.inStudentFlow && this.inDetailedRegister) {
        return this.toStep(16);
      }
      return this.toStep(24);
    },
    setCommune(commune) {
      this.userLocation = null;
      this.selectedCommune = commune;
      this.nextStep();
    },
    setUserLocation(location, idInLocation) {
      this.selectedCommune = null;
      this.userLocation = location;
      if (idInLocation) {
        this.nextStep();
      }
    },
    // Flag toggling methods
    setDataValidationFlow(value) {
      this.inDataValidationFlow = value;
    },

    // Information setting methods
    setStudentFlow(value) {
      this.inStudentFlow = value;
      if (value) {
        this.inStudentFlowToggleStep = this.inStudentFlowToggleStep ?? this.currentStep;
      } else {
        this.inStudentFlowToggleStep = null;
      }
    },
    setStudentInitialization(value) {
      this.initializingStudent = value;
    },

    // Other methods
    resetPassword() {
      this.$emit('resetPassword');
      this.$emit('closeModal');
    },
    setRegisterType(type) {
      this.registerType = type;
    },
    closeModals() {
      this.$emit('closeModal');
      this.setInRegisterFlow(false);
    },
  },

};
</script>
