/**
 * Set Application Variables from environment. Set in .env.
 */
import { parseAPIUrl } from '@/utils/config';

const CONFIG = {};

// ******************************************************************************
// **                Define the context of the application                     **
// ******************************************************************************

const APP_TENANT = process.env.VUE_APP_TENANT.toLowerCase();
const APP_ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT.toLowerCase();
const APP_API_ENVIRONMENT = process.env.VUE_APP_API_ENVIRONMENT.toLowerCase();

const fallbackApiEnvironment = APP_API_ENVIRONMENT || APP_ENVIRONMENT || 'development';

CONFIG.tenant = APP_TENANT;
CONFIG.environment = APP_ENVIRONMENT;
CONFIG.apiEnvironment = APP_API_ENVIRONMENT;

CONFIG.language = ['en', 'es'];

// ******************************************************************************
// **                Define the URLs that will be consumed                     **
// ******************************************************************************

CONFIG.backendURL = parseAPIUrl({
  url: process.env.VUE_APP_BACKEND_HOST || 'tether.education/core/',
  env: process.env.VUE_APP_BACKEND_ENVIRONMENT || fallbackApiEnvironment,
  tenant: APP_TENANT,
});

CONFIG.userServiceURL = parseAPIUrl({
  url: process.env.VUE_APP_USER_SERVICE_HOST || 'tether.education/users/',
  env: process.env.VUE_APP_USER_SERVICE_ENVIRONMENT || fallbackApiEnvironment,
  // User API is tenant agnostic
});

CONFIG.widgetsURL = parseAPIUrl({
  url: 'tether.education/widgets',
  env: fallbackApiEnvironment,
});

CONFIG.webhooksURL = parseAPIUrl({
  url: process.env.VUE_APP_WEBHOOKS_HOST || 'tether.education/webhooks/',
  env: fallbackApiEnvironment,
});

CONFIG.geoToolsBaseURL = parseAPIUrl({
  url: process.env.VUE_APP_GEOTOOLS_HOST || 'tether.education/geotools/',
  env: process.env.VUE_APP_GEOTOOLS_ENVIRONMENT || fallbackApiEnvironment,
  tenant: APP_TENANT,
});

CONFIG.analyticsApiURL = parseAPIUrl({
  url: process.env.VUE_APP_ANALYTICS_HOST || 'tether.education/service-analytics/',
  env: process.env.VUE_APP_ANALYTICS_ENVIRONMENT || fallbackApiEnvironment,
  tenant: APP_TENANT,
});

CONFIG.websocketURL = parseAPIUrl({
  url: process.env.VUE_APP_WEBSOCKET_HOST || 'tether.education/',
  env: process.env.VUE_APP_WEBSOCKET_ENVIRONMENT || fallbackApiEnvironment,
  apiProtocol: 'wss',
});
/// -----COMMENTED BECAUSE THIS IS A TEMPORAL URL FOR WIDGETS SERVICE. IT WILL EVENTUALLY GO THROUGH GATEWAY
// CONFIG.widgetsApiURL = parseAPIUrl({
//   url: process.env.VUE_APP_WIDGETS_HOST || 'tether.education/widgets/',
//   env: process.env.VUE_APP_ANALYTICS_ENVIRONMENT || fallbackApiEnvironment,
//   tenant: APP_TENANT,
// });
CONFIG.widgetsApiURL = process.env.VUE_APP_WIDGETS_HOST;

CONFIG.staticWidgetsBaseURL = parseAPIUrl({
  url: process.env.VUE_APP_STATIC_WIDGETS_HOST || 'tether.education/widgets/api',
  env: process.env.VUE_APP_STATIC_WIDGETS_ENVIRONMENT || fallbackApiEnvironment,
});

CONFIG.messagingServiceBaseURL = parseAPIUrl({
  url: process.env.VUE_APP_MESSAGING_HOST || 'tether.education/notifications/',
  env: process.env.VUE_APP_MESSAGING_ENVIRONMENT || fallbackApiEnvironment,
  tenant: APP_TENANT,
});

CONFIG.searchEngineURL = parseAPIUrl({
  url: process.env.VUE_APP_ELASTIC_SEARCH_HOST || 'tether.education/search-engine/search/',
  env: process.env.VUE_APP_ELASTIC_SEARCH_ENVIRONMENT || fallbackApiEnvironment,
  // Search engine tenant is specified through the search index
});

CONFIG.digitalEnrollmentURL = parseAPIUrl({
  url: process.env.VUE_APP_DGE_HOST || 'tether.education/bff/',
  env: process.env.VUE_APP_DGE_ENVIRONMENT || fallbackApiEnvironment,
  tenant: APP_TENANT,
});

CONFIG.digitalEnrollmentAdmissionsURL = parseAPIUrl({
  url: process.env.VUE_APP_DGE_ADMISSIONS_HOST || 'tether.education/admission/',
  env: process.env.VUE_APP_DGE_ADMISSIONS_ENVIRONMENT || fallbackApiEnvironment,
});

CONFIG.paymentsURL = parseAPIUrl({
  url: process.env.VUE_APP_PAYMENTS_HOST || 'tether.education/payments/api/',
  env: process.env.VUE_APP_PAYMENTS_ENVIRONMENT || fallbackApiEnvironment,
});

CONFIG.publicRecordsURL = parseAPIUrl({
  url: process.env.VUE_APP_PUBLIC_RECORDS_HOST || 'tether.education/public-record/',
  env: process.env.VUE_APP_PUBLIC_RECORDS_ENVIRONMENT || fallbackApiEnvironment,
});

CONFIG.instagramAPI = process.env.VUE_APP_INSTAGRAM_API;
CONFIG.metaAPI = process.env.VUE_APP_META_API;
CONFIG.metaIntegrationPermissions = 'pages_show_list,business_management,instagram_basic';

// ******************************************************************************
// **                    API Keys, DSN & Tokens loading                        **
// ******************************************************************************

CONFIG.apigatewayKey = process.env.VUE_APP_APIGATEWAY_ON === 'True' ? process.env.VUE_APP_APIGATEWAY_KEY : undefined;
CONFIG.awsKey = process.env.VUE_APP_AWS_KEY || 0;
CONFIG.captchaKey = process.env.VUE_APP_CAPTCHA_KEY;
CONFIG.googleAnalyticsKey = process.env.VUE_APP_GOOGLE_ANALYTICS_KEY;
CONFIG.googleMapsKey = process.env.VUE_APP_GOOGLE_MAPS_KEY;
CONFIG.hotjarKey = process.env.VUE_APP_HOTJAR_KEY;
CONFIG.mixpanelToken = process.env.VUE_APP_MIXPANEL_TOKEN;
CONFIG.sentryDSN = process.env.VUE_APP_SENTRY_DSN;
CONFIG.userSearchExternalKey = process.env.VUE_APP_USER_SEARCH_EXTERNAL_KEY;
CONFIG.paymentProviderKey = process.env.VUE_APP_PAYMENT_PROVIDER_KEY;
CONFIG.googleClientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
CONFIG.intercomAppId = process.env.VUE_APP_INTERCOM_APP_ID;

// ******************************************************************************
// **                             Application flags                            **
// ******************************************************************************

CONFIG.guestOption = process.env.VUE_APP_GUEST_OPTION === 'True';
CONFIG.hotjarOn = process.env.VUE_APP_HOTJAR_ON === 'True';
CONFIG.maintenance = process.env.VUE_APP_MAINTENANCE_ON === 'True';
CONFIG.mixpanelDebug = process.env.VUE_APP_MIXPANEL_DEBUG === 'True';
// TODO: This is not being used for current simulations. Remove?
CONFIG.simulationOn = process.env.VUE_APP_SIMULATION_ON === 'True';

CONFIG.landingPluginEnabled = process.env.VUE_APP_LANDING_PLUGIN_ENABLED === 'True';

// ******************************************************************************
// **                      Application specific variables                      **
// ******************************************************************************

CONFIG.applicationRound = 1;
CONFIG.feedbackType = process.env.VUE_APP_FEEDBACK_TYPE;

CONFIG.ambassadorCalendly = process.env.VUE_APP_AMBASSADOR_CALENDLY;
CONFIG.researchUrl = process.env.VUE_APP_RESEARCH_URL;

export default CONFIG;
