<template>
  <!-- FIXME: We should have a separate CSS class for this atom, and not depend on register-main -->
  <div
    class="register-main--svg-logo"
    :class="{
      'ma-0': noMargin,
      'register-main--svg-logo--left': left,
      'register-main--svg-logo--right': right,
      'register-main--svg-logo--pointer': pointer,
      'w-auto': widthAuto,
    }"
  >
    <img
      class="register-main--svg-logo--main"
      :src="logo.startsWith('http') ? logo : require('@/assets/logos/' + logo)"
      :width="svgWidth"
      :alt="alt"
      @click="$emit('logoClick', index)"
      @keydown="$emit('logoClick', index)"
    />
  </div>
</template>

<script>
export default {
  name: 'SVGLogoRender',
  components: {},
  props: {
    logo: {
      type: String,
      default: 'face.svg',
    },
    index: {
      type: Number,
      default: null,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    svgWidth: {
      type: String,
      default: 'auto',
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    pointer: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
      default: 'SVG Logo',
    },
    widthAuto: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
